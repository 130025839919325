import { Injectable } from '@angular/core';
import {shareReplay} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {CmsApiService} from './api/cms-api.service';

@Injectable({
  providedIn: 'root'
})
export class CacheControlService {

  /**
   * Cache observable
   */
  private _cache$: Observable<any> = this._cmsApi.getCacheCf().pipe(
    shareReplay(1),
  );

  constructor(
    private _cmsApi: CmsApiService
  ) { }

  get cache$() {
    return this._cache$;
  }
}
