import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { Modal } from '../../modal';
import { showHide } from '../../modal-animations';

@Component({
  selector: 'app-modal-template-clean',
  templateUrl: './modal-template-clean.component.html',
  styleUrls: ['./modal-template-clean.component.scss'],
  animations: [showHide]
})
export class ModalTemplateCleanComponent extends Modal implements OnInit {

  @ViewChild('content', { static: true, read: ViewContainerRef }) private contentRef: ViewContainerRef;

  constructor() {
    super();
  }

  ngOnInit() {}

}
