import { Injectable } from '@angular/core';
import {ScriptLoaderService} from '../script-loader.service';
import { catchError, tap, filter, map, switchMap, delay, takeWhile, endWith } from 'rxjs/operators';
import {fromEvent, of, ReplaySubject} from 'rxjs';
import {UserService} from '../user/user.service';
import {LanguageService} from '../language/language.service';
import {PlatformService} from '../platform.service';
import {CookieService} from 'ngx-cookie-service';
import {CommonDataService} from '../common-data.service';
import {EnvironmentService} from '../environment.service';
import {GroupsService} from '../groups.service';
import {UserTransactionsService} from '../user/user-transactions.service';
import {StaticContentService} from '../static-content.service';
import {
  DEFAULT_DEPARTMENTS_LIST,
  IZendeskUserData, OFFLINE_DEPARTMENT, ONLINE_CHAT_COUNTRIES,
  VIP_CHAT_EXPIRE_AT_COOKIE, VIP_DEPARTMENT,
  ZENDESK_SCRIPT_URL,
} from './zendesk-chat-data';

declare var zE;

@Injectable({
  providedIn: 'root'
})

export class ZendeskChatService {

  /**
   * Returns true if zendesk chat available
   */
  private _available: boolean;

  /**
   * Emits true if zendesk chat available
   */
  private _available$: ReplaySubject<boolean> = new ReplaySubject<boolean>();

  /**
   * Is vip chat set up now
   */
  public isVipChatNow: boolean;

  /**
   * Returns true if chat open
   * @private
   */
  private _isOpen: boolean;

  /**
   * Timeout to restore default chat (remove vip)
   */
  private _timeoutToRemoveVipChat: any;

  /**
   * Time to restore default chat after closing vip chat
   */
  private readonly _timeToRestoreDefaultChat = 20 * 60 * 1000;

  constructor(
    private _scriptLoader: ScriptLoaderService,
    private _user: UserService,
    private _lang: LanguageService,
    private _platform: PlatformService,
    private _cookie: CookieService,
    private _common: CommonDataService,
    private _env: EnvironmentService,
    private _groups: GroupsService,
    private _transactions: UserTransactionsService,
    private _static: StaticContentService,
  ) {
  }

  /**
   * Success from outside
   */
  get available() { return this._available; }
  get available$() { return this._available$; }
  get isOpen() { return this._isOpen; }

  /**
   * Get widget api key
   * @private
   */
  private _getWidgetApiKey$() {
    return this._static.item({slug: 'zendesk-api-key'}).pipe(
      filter(data => !!data && data[0]),
      map(data => data[0].Content && data[0].Content.trim()),
    );
  }
  public initOfflineChat(isOffline: boolean) {
    this.available$.pipe(
      takeWhile(value => value !== false),
      endWith(true),
      delay(150),
      filter((data) => data === true),
      tap(() => this._setZendeskOffline(isOffline))
    ).subscribe();
  }

  /**
   * Init zendesk chat
   */
  public initChat(forVip: boolean = false) {
    if (this._platform.isBrowser) {
      this._user.auth$.pipe(
        switchMap(() => this._getWidgetApiKey$()),
        filter(key => !!key),
        tap(() => {
          const zeScript = document.querySelector('#ze-snippet');
          if (zeScript) {
            zeScript.remove();
          }
          this._available = false;
          this._available$.next(false);
        }),
        delay(0),
        switchMap((key) => this._scriptLoader.load(`${ZENDESK_SCRIPT_URL}?key=${key}&timestamp=${Date.now()}`, undefined, {id: 'ze-snippet', async: true})),
        filter(() => Boolean(zE)),
        tap(() => {
          this._available = true;
          this._available$.next(true);
          this._setInitFunctionalData(forVip);
        }),
        catchError(error => {
          this._available = false;
          this._available$.next(false);
          return of(error);
        })
      ).subscribe();
    }
  }

  /**
   * Set init data for functional chat
   * @private
   */
  private _setInitFunctionalData(isVip: boolean = false, isOffline: boolean = false) {
    this.hide();

    this._onClose();
    this._onChangeLanguage();
    this._closeByDocumentClick();
    this._onMessage();
    this._updateWidgetSettings({
      chat: {
        suppress: isOffline || this._isUseOfflineForm(),
        departments: this._getDepartmentSettings(isVip),
        tags: [
          this._lang.current ? this._lang.current : '',
          this._cookie.get('id_id') ? this._cookie.get('id_id') : ''
        ]
      }
    });
  }

  /**
   * Toggle chat
   * @open
   */
  public toggle() {
    this.isOpen ? this.hide() : this.open();
  }

  /**
   * Hide chat
   */
  public hide() {
    if (this.available) {
      zE(() => zE.hide());
      this._isOpen = false;
    }
  }

  /**
   * Open chat
   */
  public open() {
    setTimeout(() => {
      if (this.available && zE && typeof zE.activate === 'function') {
        zE.activate();
        this._isOpen = true;
      }
    }, 300);
  }

  /**
   * Set user data (name, email)
   * @param data
   * @private
   */
  private _setUserData(data: IZendeskUserData) {
    if (this.available) {
      zE(() => {
        zE.identify({...data});
      });
    }
  }

  /**
   * Handler on close widget
   * @private
   */
  private _onClose() {
    zE('webWidget:on', 'close', () => {
      this._isOpen = false;

      if (this.isVipChatNow) {
        if (!this._timeoutToRemoveVipChat) {
          this._restoreDefaultChatIn(this._timeToRestoreDefaultChat);
          this._cookie.set(VIP_CHAT_EXPIRE_AT_COOKIE, (Date.now() + this._timeToRestoreDefaultChat).toString(), new Date(Date.now() + this._timeToRestoreDefaultChat), '/', (<any>window.location.hostname));
        }
      }
    });
  }

  /**
   * Set locale for chat
   * @private
   * @locale
   */
  private _setLocale(locale) {
    if (this.available) {
      zE(() => {
        zE.setLocale(locale);
      });
    }
  }

  /**
   * Add tags
   * @private
   * @tags
   */
  private _addTags(tags: string[]) {
    zE('webWidget', 'chat:addTags', tags);
  }

  /**
   * Change locale for chat
   * @private
   */
  private _onChangeLanguage() {
    this._lang.langChange$.pipe(
      tap(locale => this._setLocale(locale))
    ).subscribe();
  }

  /**
   * Close by document click
   * @private
   */
  private _closeByDocumentClick() {
    if (this._platform.isBrowser) {
      fromEvent(document, 'click').pipe(
        filter(() => this._isOpen),
        tap(() => this.hide())
      ).subscribe();
    }
  }

  /**
   * Update widget settings
   * @param settings
   * @private
   */
  private _updateWidgetSettings(settings = {}) {
    zE( 'webWidget', 'updateSettings', {
      webWidget: {...settings}
    } );
  }
  private _setZendeskOffline(isOffline) {
    if (isOffline) {
      zE('webWidget', 'updateSettings', {
        webWidget: {
          chat: {
            suppress: true,
            departments: this._getDepartmentSettings(false),
          }
        }
      });
      this.hide();
    }
  }

  /**
   * Open chat if message coming
   * @private
   */
  private _onMessage() {
    if (this.available && zE) {
      zE('webWidget:on', 'chat:unreadMessages', countMessage =>  {
        if (!this._isOpen) this.open();
      });
    }
  }

  /**
   * Get department settings (set department for user)
   * @param isVip
   * @private
   */
  private _getDepartmentSettings(isVip: boolean = false) {

    if (!isVip && this._user.auth && this._cookie.check(VIP_CHAT_EXPIRE_AT_COOKIE) && +this._cookie.get(VIP_CHAT_EXPIRE_AT_COOKIE) > Date.now()) { // restore vip chat anyway if user has cookie
      isVip = true;
      this._restoreDefaultChatIn(+this._cookie.get(VIP_CHAT_EXPIRE_AT_COOKIE) - Date.now());
    } else if (!isVip && this._cookie.check(VIP_CHAT_EXPIRE_AT_COOKIE)) {
      this._cookie.delete(VIP_CHAT_EXPIRE_AT_COOKIE);
    }

    this.isVipChatNow = isVip;

    const isOnlineChat = ONLINE_CHAT_COUNTRIES.includes(this._env.env.country.short);

    if (isVip) {
      return {
        select: VIP_DEPARTMENT
      };
    }

    if (!isOnlineChat) {
      return {
        enabled: [OFFLINE_DEPARTMENT],
        select: OFFLINE_DEPARTMENT
      };
    }

    return {
      enabled: DEFAULT_DEPARTMENTS_LIST,
    };

  }

  /**
   * Restore default chat in passed ms
   */
  private _restoreDefaultChatIn(ms: number) {
    this._timeoutToRemoveVipChat = setTimeout(() => {
      this.initChat(false);
    }, ms);
  }


  /**
   * Use for suppress in webwidget config
   * @private
   */
  private _isUseOfflineForm(offlineStags: string[] = []) {
    return this._cookie.get('id_id') && offlineStags.includes(this._cookie.get('id_id'))
    ||
    !ONLINE_CHAT_COUNTRIES.includes(this._env.env.country.short);
  }
}
