import { Injectable } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { TranslationService } from '../shared/translation/translation.service';

/**
 * Error messages by keys
 */
export const ERROR_MESSAGES = {
  required: 't.cant-blank',
  required_true: 't.must-accepted',
  pattern: 't.invalid-format',
  password_pattern: 't.paswd-requirments',
  must_match: 't.passwds-not-match',
  eighteen_years: 't.18-years-err',
  is_not_valid: 't.not-valid',
  contain_spaces: 't.error-spaces',
  use_visa: 'error.use-visa',
  use_mastercard: 'error.use-mastercard',
  ca_postal_code_error: 't.postal-code-error-for-ca'
};

@Injectable({
  providedIn: 'root'
})
export class FormsErrorHandlerService {

  constructor(private _translate: TranslationService) { }

  /**
   * Apply form errors with messages
   * And return object with errors for each key
   *
   * @param form
   * @param ssResponse
   * @param touch
   */
  public applyFormErrors(form: UntypedFormGroup, ssResponse: any = null, touch = false): any {

    const formErrors = {};

    ssResponse = ssResponse || {errors: {}};

    Object.keys(form.controls).forEach(controlKey => {
      const control = form.get(controlKey);

      let errors = {
        ...control.errors || {},
        ...(ssResponse.errors && ssResponse.errors[controlKey] || {})
      };

      const errorKeys = Object.keys(errors);

      if (errorKeys.length) {
        errorKeys.forEach(errorKey => {
          errors[errorKey] = this.getErrorMessageByKey(errorKey) || errors[errorKey];
        });
      } else {
        errors = null;
      }

      control.setErrors(errors);

      formErrors[controlKey] = this.errors(control);

      if (touch) {
        control.markAsTouched();
      }
    });

    /**
     * Uses for get errors which key not match control key
     */
    Object.keys(ssResponse).forEach(errorKey => {
      Object.keys(ssResponse[errorKey]).forEach(key => {
        if (!formErrors[key]) {
          formErrors[key] = Object.keys(ssResponse[errorKey][key]).map(error => ssResponse[errorKey][key][error]);
        }
      });
    });

    return formErrors;
  }

  /**
   * Returns array of error messages if exists for provided control
   *
   * @param control
   */
  public errors(control: AbstractControl): Array<any> {
    return Object.keys(control.errors || {}).map(key => {
      if (key === 'min' || key === 'max') {
        return {error: `${key} ${control.errors[key][key]}`, attributes: null};
      } else if(key === 'Mask error') {
        return {error: null, attributes: null};
      } else if (key === 'minlength' || key === 'maxlength') {
        return {error: 't.required-length', attributes: {length: control.errors[key].requiredLength}};
      } else {
        return {error: this.getErrorMessageByKey(key) || control.errors[key], attributes: null};
      }
    });
  }

  /**
   * Returns error message for provided key
   *
   * @param key
   */
  public getErrorMessageByKey(key: string): string {
    return ERROR_MESSAGES[key] || '';
  }

  /**
   * Convert SS error response to array of errors
   *
   * @param error
   */
  public ssBackendErrorsToArray(error: any): Array<string> {
    const errorList = [];

    if (error) {
      Object.keys(error).forEach(key => {
        const type = typeof error[key];

        if (type === 'object') {
          errorList.push(...this.ssBackendErrorsToArray(error[key]));
        } else if (type === 'string') {
          errorList.push(error[key]);
        }
      });
    }

    return errorList;
  }
}
