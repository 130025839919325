import { Component, OnInit } from '@angular/core';
import { EnvironmentService } from '../../../../services/environment.service';

@Component({
  selector: 'app-header-not-authorized',
  templateUrl: './header-not-authorized.component.html',
  styleUrls: ['./header-not-authorized.component.scss']
})
export class HeaderNotAuthorizedComponent implements OnInit {

  constructor(
    public env: EnvironmentService,
  ) { }

  ngOnInit() {
  }
}
