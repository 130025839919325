import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { WindowService } from './window.service';
import { fromEvent, ReplaySubject } from 'rxjs';
import { PlatformService } from './platform.service';
import { Inject, Injectable } from '@angular/core';
import { NgZone } from '@angular/core';
import {first, map, tap} from 'rxjs/operators';
import {switchMap} from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';

export interface WindowSize {
  width: number | any;
  height: number | any;
}

@Injectable({
  providedIn: 'root'
})
export class GlobalEventsService {

  public resize$: ReplaySubject<any> = new ReplaySubject(1);
  public scroll$: ReplaySubject<any> = new ReplaySubject(1);
  public beforeinstallprompt$: ReplaySubject<any> = new ReplaySubject(1);
  public routerNavigationStart$: ReplaySubject<any> = new ReplaySubject(1);
  public routerNavigationEnd$: ReplaySubject<any> = new ReplaySubject(1);
  public globalRouterEvent$: ReplaySubject<any> = new ReplaySubject(1);
  public isLandscape$: ReplaySubject<any> = new ReplaySubject(1);
  public isLeaveBrowserTab$: ReplaySubject<any> = new ReplaySubject(1);
  public isVisibleTab$: ReplaySubject<boolean> = new ReplaySubject(1);
  private _isLeaveBrowserTab = null;

  /**
   * Trigger if breakpoints updated
   * @private
   */
  private _updatedBreakpoints$: ReplaySubject<any> = new ReplaySubject<any>();

  constructor(
    private _platform: PlatformService,
    private _zone: NgZone,
    private _window: WindowService,
    private _router: Router,
    @Inject(DOCUMENT) private _document: Document
  ) { }

  public initGlobalEvent() {
    this._runRouterNavigationListener();
    if (this._platform.isBrowser) {
      this._zone.run(() => {
        this._runScrollListener();
        this._runResizeListener();
        this._runBeforeInstallPromptListener();
        this._runLandscapeChecker();
        this._handlerLeaveBrowserTab();
        this._handlerVisibilityChange();
      });
    }
  }

  get updatedBreakpoints$(): ReplaySubject<any> {
    return this._updatedBreakpoints$;
  }

  /**
   * Run global scroll listener
   */
  private _runScrollListener() {
    fromEvent(this._window.nativeWindow, 'scroll', {passive: true, capture: false})
    .subscribe((e) => this.scroll$.next(e));
  }

  /**
   * Run global resize listener
   */
  private _runResizeListener() {
    this._document.documentElement.style.setProperty('--vh', `${this._window.nativeWindow.innerHeight * 0.01}px`);
    this._document.documentElement.style.setProperty('--app-height', `${this._window.nativeWindow.innerHeight}px`);
    fromEvent(this._window.nativeWindow, 'resize', {passive: true}).pipe(
      map(event => <WindowSize>{
        width: (<any>window).innerWidth,
        height: (<any>window).innerHeight
      }),
      tap(e => {
        this._document.documentElement.style.setProperty('--app-height', `${e.height}px`);
        this._document.documentElement.style.setProperty('--vh', `${this._window.nativeWindow.innerHeight * 0.01}px`);
      })
    ).subscribe((e) => this.resize$.next(e));
  }

  /**
   * Run global before install prompt listener
   */
  private _runBeforeInstallPromptListener() {
    fromEvent(this._window.nativeWindow, 'beforeinstallprompt')
    .subscribe((e) => this.beforeinstallprompt$.next(e));
  }

  /**
   * Run global router event listener
   */
  private _runRouterNavigationListener() {
    this._router.events.subscribe((e) => {
      if(e instanceof NavigationStart){
        this.routerNavigationStart$.next(e);
      }
      if (e instanceof NavigationEnd) {
        this.routerNavigationEnd$.next(e);
      }
      this.globalRouterEvent$.next(e);
    });
  }

  /**
   * Run global landscape checker
   */
  private _runLandscapeChecker() {
    fromEvent(this._window.nativeWindow, 'orientationchange').pipe(
      first(),
      switchMap(() => this._updatedBreakpoints$),
    ).subscribe((e) => this.isLandscape$.next(('orientation' in window) && !(window.orientation === 0 || window.orientation === 180)));

    this.isLandscape$.next(('orientation' in window) && !(window.orientation === 0 || window.orientation === 180));
  }

  /**
   * It's include open\close browser console, click on iframes, change active tab
   */
  private _handlerLeaveBrowserTab() {
    if (this._platform.isBrowser) {
      fromEvent(this._window.nativeWindow, 'blur').subscribe((e: any) => {
        if (e.eventPhase && !this._isLeaveBrowserTab) {
          this._isLeaveBrowserTab = true;
          this.isLeaveBrowserTab$.next(true);
        }
      });
      fromEvent(this._window.nativeWindow, 'focus').subscribe((e: any) => {
        if (e.eventPhase && this._isLeaveBrowserTab) {
          this._isLeaveBrowserTab = false;
          this.isLeaveBrowserTab$.next(false);
        }
      });
    }
  }
  /**
   * This function listens for changes in the visibility state of the document and updates a
   * BehaviorSubject accordingly.
   */
  private _handlerVisibilityChange() {
    fromEvent(this._document, 'visibilitychange').subscribe(() => {
      if (this._document.visibilityState === 'visible') {
        this.isVisibleTab$.next(true);
      } else {
        this.isVisibleTab$.next(false);
      }
    });
  }
}
