import { BrowserModule } from '@angular/platform-browser';
import { APP_ID, Inject, Injector, NgModule, PLATFORM_ID, ErrorHandler } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ContentUpdaterService } from './core/services/content-updater/content-updater.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GlobalErrorHandler } from './core/helpers/global-error-handler';
import { PostMessageService } from './core/services/post-message.service';
import { PlatformService } from './core/services/platform.service';
import { SsPaymentsModule } from './core/vendor/ss-payments/ss-payments.module';
import { environment } from '../environments/environment';
import { HostRequestsInterceptor } from './core/interceptors/host-requests.interceptor';
import { SsWebSocketsModule } from './core/vendor/ss-web-sockets/ss-web-sockets.module';
import { CacheControlInterceptor } from './core/interceptors/cache-control.interceptor';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';
import { CookieService } from 'ngx-cookie-service';
import { DecoratorService } from './core/helpers/decorator-service.service';
import {ModalModule} from './core/modal-v2/modal.module';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule.withServerTransition({appId: 'wd'}),
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    SsPaymentsModule.forRoot({
      host: environment.ss_host
    }),
    SsWebSocketsModule,
    NgxMaskDirective,
    ModalModule
  ],
  providers: [
    ContentUpdaterService,
    CookieService,
    provideNgxMask(),
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CacheControlInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HostRequestsInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(
    @Inject(PLATFORM_ID) private platformId: object,
    @Inject(APP_ID) private appId: string,
    private _injector: Injector,
    private _postMessage: PostMessageService,
    private _platform: PlatformService
  ) {
    DecoratorService.injector = _injector;
    console.log(`App ${this.appId}`);
    this._sendPostMessage();
  }

  /**
   * Send post message with current url
   *
   * @private
   */
  private _sendPostMessage() {
    if (this._platform.isBrowser) {
      this._postMessage.send({
        url: window.location.href
      });
    }
  }
}
