import { BaseError, ErrorChanel } from './base-error';

export class MissingTranslateError extends BaseError {
  public key: string;
  public chanel: ErrorChanel = ErrorChanel.QA;
  public type = 'translate';
  public name = 'Translations';

  constructor(key: string) {
    super(`Missing translate: ` + key);
    this.key = key;
  }
}
