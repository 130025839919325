<app-header></app-header>

<app-side-nav></app-side-nav>

<div class="top-container">
  <ng-container *ngIf="!user.auth || !isWelcomeBonusesClaimed">
    <ng-container
      *ngIf="welcomeBonuses$ | async as welcomeBonuses; else welcomeBonusLoader"
    >
      <div
        class="container"
        *ngIf="currentWelcomeIndex === 0 || currentWelcomeIndex"
      >
        <div class="welcome" *ngIf="welcomeBonuses.length">
          <div class="welcome__list">
            <div
              *ngFor="let bonus of welcomeBonuses; let i = index"
              class="welcome__item"
              [ngClass]="{
                active: i === currentWelcomeIndex,
                taken: i < currentWelcomeIndex
              }"
            >
              <div class="welcome__item_border"></div>
              <div
                class="welcome__item_sequence"
                [innerHTML]="bonus?.topLabel | safe: 'html'"
              ></div>
              <div
                class="welcome__item_title"
                [innerHTML]="bonus?.title | safe: 'html'"
              ></div>
              <div class="welcome__item_toggle">
                <div
                  class="welcome__item_description"
                  [innerHTML]="bonus?.description | safe: 'html'"
                ></div>
                <a
                  class="btn btn--filled-primary big"
                  [routerLink]="user.auth ? '/deposit' : '/register'"
                >
                  {{ "t.get-bonus" | translate }}
                </a>
                <div
                  class="welcome__item_second-description"
                  *ngIf="bonus?.secondDescription"
                >
                  <div class="count-fs" *ngIf="bonus?.countFS">
                    <span
                      >{{ bonus?.countFS || "20"
                      }}{{ "t.fs" | translate }}</span
                    >
                    <span>{{ "t.day" | translate }}</span>
                  </div>
                  {{ bonus?.secondDescription }}
                </div>
              </div>
            </div>
          </div>
          <div class="welcome__image">
            <img
              [src]="welcomeBonuses[currentWelcomeIndex]?.image"
              alt="Deposit bonus image"
            />
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="dailyRewardBonus$ | async as dailyBonus">
    <div class="container daily">
      <div
        class="bonus-list bonus-list--reload"
      >
        <ng-container *ngIf="dailyBonus.length">
          <div class="bonus-list__title">
            {{ "t.daily-reward-bonus" | translate }}
          </div>
          <div class="bonus-list__wrapper bonus-list__wrapper--reload">
            <ng-container *ngFor="let bonus of dailyBonus; let i = index">
              <app-offer-preview [offer]="bonus" [buttonTitle]="'t.lets-go'"></app-offer-preview>
            </ng-container>

            <div class="bonus-steps">
              <div class="bonus-steps_title">{{ 't.how-you-can-get' | translate }}</div>
              <div class="bonus-steps_list">
                <div><span>1</span> <span>{{ 't.daily-first-step' | translate }}</span></div>
                <div><span>2</span> <span>{{ 't.daily-second-step' | translate }}</span></div>
                <div><span>3</span> <span>{{ 't.daily-third-step' | translate }}</span></div>
              </div>

              <a class="btn btn--filled-primary big" (click)="onBonusClick()" [routerLink]="user.auth ? '/deposit' : '/register'">
                {{ 't.get-bonus' | translate }}
              </a>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="user.auth">
    <ng-container
      *ngIf="specialBonuses$ | async as bonuses; else circlePreloading"
    >
      <div class="special" *ngIf="bonuses.length">
        <div class="container">
          <div class="bonus-list__title">
            {{ "t.special-bonuses" | translate }}
          </div>
          <div class="special__list">
            <ng-container *ngFor="let bonus of bonuses">
              <app-ss-bonus-preview
                [bonus]="bonus"
                (onCancel)="cancel($event)"
                (onActivate)="activate($event)"
              ></app-ss-bonus-preview>
            </ng-container>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>

<div class="bottom-container">
  <div class="container">
    <div
      class="bonus-list bonus-list--reload"
      *ngIf="reloadBonuses$ | async as reloadBonuses"
    >
      <ng-container *ngIf="reloadBonuses.length">
        <div class="bonus-list__title">
          {{ "t.bonuses-reload" | translate }}
        </div>
        <div class="bonus-list__wrapper bonus-list__wrapper--reload">
          <ng-container *ngFor="let bonus of reloadBonuses; let i = index">
            <app-offer-preview [offer]="bonus"></app-offer-preview>
          </ng-container>
        </div>
      </ng-container>
    </div>

    <div class="no-deposit-bonus" *ngIf="user.auth">
      <app-no-deposit-bonus></app-no-deposit-bonus>
    </div>

    <div
      class="bonus-list bonus-list--loyalty"
      *ngIf="loyaltyBonuses$ | async as loyaltyBonuses"
    >
      <ng-container *ngIf="loyaltyBonuses.length">
        <div class="bonus-list__title">
          {{ "t.bonuses-loyalty" | translate }}
        </div>
        <div class="bonus-list__wrapper">
          <img *ngIf="halloween.isSecondExist && halloween.eventEnable" (click)="halloween.addEventToCollection(halloween.eventType.Second)" src="/assets/img/promo/treasure-valley/hunt-icon.png" alt="" class="ghost">

          <ng-container *ngFor="let bonus of loyaltyBonuses; let i = index">
            <app-offer-preview
              [offer]="bonus"
              [loyaltyBonusIndex]="i"
              [activeLoyaltyIndex]="loyaltyActiveIndex"
            >
            </app-offer-preview>
            <ng-container *ngIf="i === 3 && promo$ | async as promo">
              <div class="social" *ngIf="promo && promo[0] && promo[0]?.Title">
                <app-social-offer [offer]="promo"></app-social-offer>
              </div>
            </ng-container>
          </ng-container>
        </div>
      </ng-container>
    </div>
  </div>

  <ng-container *ngIf="offersText$ | async as text">
    <div class="container" *ngIf="text[0]?.Terms">
      <div class="terms">
        <div class="terms">
          <div class="accordion">
            <div class="accordion__header" Accordion>
              <div>{{ "t.bonus-terms" | translate }}</div>
              <i class="accordion__icon icon-pointer-down"></i>
            </div>
            <div class="accordion__content">
              <div [innerHTML]="text[0]?.Terms | safe: 'html'"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

</div>

<app-bottom-navigation></app-bottom-navigation>

<app-footer>
  <app-bottom-text
    *ngIf="offersText$ | async as text"
    [text]="text[0]?.SeoContent"
  ></app-bottom-text>
</app-footer>

<ng-template #preloading>
  <div class="preloading"></div>
</ng-template>

<ng-template #circlePreloading>
  <app-preloader></app-preloader>
</ng-template>

<ng-template #welcomeBonusLoader>
  <div class="top-container">
    <div class="container welcome-preloader">
      <div class="row">
        <div class="left">
          <div class="welcome__item">
            <div
              class="welcome__item_border"
              [ngClass]="{ 'active-preloader': true }"
            ></div>
            <div class="item first_item">
              <div class="item__title">
                <app-skeleton
                  [count]="1"
                  [animation]="'progress'"
                  [type]="'block'"
                ></app-skeleton>
              </div>
              <div class="item__title-main">
                <app-skeleton
                  [count]="1"
                  [animation]="'progress'"
                  [type]="'block'"
                ></app-skeleton>
              </div>
              <div class="item__title-main">
                <app-skeleton
                  [count]="1"
                  [animation]="'progress'"
                  [type]="'block'"
                ></app-skeleton>
              </div>
              <div class="item__subtitle">
                <app-skeleton
                  [count]="1"
                  [animation]="'progress'"
                  [type]="'block'"
                ></app-skeleton>
              </div>
              <div class="item__button">
                <app-skeleton
                  [count]="1"
                  [animation]="'progress'"
                  [type]="'block'"
                ></app-skeleton>
              </div>
              <div class="item__row">
                <div class="row__days">
                  <app-skeleton
                    [count]="1"
                    [animation]="'progress'"
                    [type]="'circle'"
                  ></app-skeleton>
                </div>
                <div class="row__text">
                  <app-skeleton
                    [count]="1"
                    [animation]="'progress'"
                    [type]="'block'"
                  ></app-skeleton>
                  <app-skeleton
                    [count]="1"
                    [animation]="'progress'"
                    [type]="'block'"
                  ></app-skeleton>
                </div>
              </div>
            </div>
          </div>

          <div class="welcome__item" *ngFor="let i of [0, 1]">
            <div class="welcome__item_border"></div>
            <div class="item first_item">
              <div class="item__row">
                <div class="item__title">
                  <app-skeleton
                    [count]="1"
                    [animation]="'progress'"
                    [type]="'block'"
                  ></app-skeleton>
                </div>
                <div class="item__title">
                  <app-skeleton
                    [count]="1"
                    [animation]="'progress'"
                    [type]="'block'"
                  ></app-skeleton>
                </div>
              </div>
              <div class="item__title-main">
                <app-skeleton
                  [count]="1"
                  [animation]="'progress'"
                  [type]="'block'"
                ></app-skeleton>
              </div>
            </div>
          </div>
        </div>
        <div class="right">
          <div class="right__image">
            <app-skeleton
              [count]="1"
              [animation]="'progress'"
              [type]="'circle'"
            ></app-skeleton>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
