import { AfterContentInit, Component, ContentChild, ElementRef, forwardRef, HostListener, Input, OnInit, Output } from '@angular/core';
import { SelectService } from './select.service';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ReplaySubject } from 'rxjs';
import { SelectDropdownComponent } from './select-dropdown/select-dropdown.component';
import { SelectHeaderComponent } from './select-header/select-header.component';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  providers: [
    SelectService,
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => SelectService)
    }
  ]
})
export class SelectComponent implements OnInit, AfterContentInit {

  /**
   * Value changes emitter
   */
  @Output('change') change: ReplaySubject<any> = this.select.value$;

  /**
   * Link to SelectDropdownComponent
   */
  @ContentChild(SelectDropdownComponent) dropdown: SelectDropdownComponent;

  /**
   * Link to SelectHeaderComponent
   */
  @ContentChild(SelectHeaderComponent) header: SelectHeaderComponent;

  /**
   * Click handler
   *
   * @param target
   */
  @HostListener('document:click', ['$event.target']) onClick(target) {
    if (this._el.nativeElement.contains(target)) {
      this.select.dropdownOpen ? this.select.close() : this.select.open();
    } else {
      this.select.close();
    }
  }

  constructor(
    public select: SelectService,
    private _el: ElementRef
  ) { }

  ngOnInit() {
  }

  /**
   * Init Select service after inner components content init
   */
  ngAfterContentInit() {
    this.select.init(this.header, this.dropdown);
  }

  /**
   * Filter select options by provided query
   *
   * @param query
   */
  filterOptions(query: string) {
    this.select.filterOptions(query);
  }

}
