import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UserService} from '../../../services/user/user.service';
import {FormControl} from '@angular/forms';
import {PaymentAction} from '../../../vendor/ss-payments/ss-payments-types';
import {InstallAppService} from '../../../services/install-app.service';

@Component({
  selector: 'app-install-app-banner',
  templateUrl: './install-app-banner.component.html',
  styleUrls: ['./install-app-banner.component.scss']
})
export class InstallAppBannerComponent implements OnInit {


  constructor(
    public installApp: InstallAppService
  ) { }

  ngOnInit() {
  }

}
