import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { ApiProvider } from './helpers/api-provider';

@Injectable({
  providedIn: 'root',
})
export class RedirectorApiService {
  /**
   * For API communication
   */
  private _api: ApiProvider = new ApiProvider({
    defaultOptions: {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'DEVICE-TYPE': this._device.isDesktop()
          ? 'Desktop'
          : this._device.isTablet()
          ? 'Tablet'
          : 'Mobile',
      }),
      withCredentials: true,
    },
    host: environment.redirector_host,
  });

  constructor(private _device: DeviceDetectorService) {}

  public get customHeaders(): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      'DEVICE-TYPE': this._device.isDesktop() ? 'Desktop' : this._device.isTablet() ? 'Tablet' : 'Mobile'
    });
  }

  public get customHeadersList(): any[] {
    return this._api.customHeadersList;
  }

  /**
   * /site/mirror/list
   * @param params
   */
  public getMirrorOnStage(params: object = {}): Observable<any> {
    return this._api.get(`/site/mirror/list`, params);
  }

  /**
   * /static/img/provider/logo_${:id}.jpeg
   * @param params
   */
  public blockMirror(id, params: object = {}): Observable<any> {
    return this._api.get(`/static/img/provider/logo_${id}.jpeg`, params);
  }

  public checkNewMirror(mirror: string) {
    const _api = new ApiProvider({
      defaultOptions: {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'DEVICE-TYPE': this._device.isDesktop()
            ? 'Desktop'
            : this._device.isTablet()
            ? 'Tablet'
            : 'Mobile',
        }),
        withCredentials: true,
      },
      host: 'https://' + mirror
    });
    return _api.get(`/api/service/environment`, {crossdomain: true});
  }

}
