import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { UserService } from '../../services/user/user.service';
import { ToastMessageService } from '../../modules/toast-message/toast-message.service';
import { FormsErrorHandlerService } from '../../services/forms-error-handler.service';
import { FieldDescriptor, UserInfoService } from '../../services/user/user-info.service';
import { catchError, debounceTime, distinctUntilChanged, map, pairwise, startWith, switchMap, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { CommonDataService } from '../../services/common-data.service';
import { EventEmitter, OnDestroy, inject } from '@angular/core';
import { ValidationPatterns } from '../validation-patterns';
import { CustomValidators } from '../custom-validators';
import { GoogleTagManagerService } from '../../services/google-tag-manager.service';
import { countryToISO3 } from '../iso2-to-iso3';
import { PlacesApiService } from '../../services/places-api.service';
import { PlatformService } from '../../services/platform.service';
import { EnvironmentService } from '../../services/environment.service';
import { UserFieldDescriptor, userFields, UserFieldType } from './user-fields.data';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter } from 'rxjs/operators';
import {ModalRef} from '../../modal-v2/modal-ref';

@UntilDestroy()
export class UpdateProfileFormController implements OnDestroy {

  public fieldsData: {
    name: string,
    label: string,
    editable?: boolean,
    disabled?: boolean,
  }[] = [
      { name: 'nickname', label: 't.nickname', editable: true },
      { name: 'first_name', label: 't.first-name' },
      { name: 'last_name', label: 't.last-name' },
      { name: 'gender', label: 't.gender' },
      { name: 'date_of_birth', label: 't.date-of-birth' },
      { name: 'country', label: 't.country' },
      { name: 'city', label: 't.city' },
      { name: 'address', label: 't.address' },
      { name: 'postal_code', label: 't.postal-code' },
      { name: 'email', label: 't.email' },
      { name: 'state', label: 't.state' }
    ];

  /**
   * Access to global services
   */
  private _fb: UntypedFormBuilder = inject(UntypedFormBuilder);
  private _user: UserService = inject(UserService);
  private _userInfo: UserInfoService = inject(UserInfoService);
  private _toastMessage: ToastMessageService = inject(ToastMessageService);
  private _formErrors: FormsErrorHandlerService = inject(FormsErrorHandlerService);
  private _gtm: GoogleTagManagerService = inject(GoogleTagManagerService);
  public data: CommonDataService = inject(CommonDataService);
  private _places: PlacesApiService = inject(PlacesApiService);
  private _platform: PlatformService = inject(PlatformService);
  private _commonData: CommonDataService = inject(CommonDataService);
  private _env: EnvironmentService = inject(EnvironmentService);

  /**
   * Update profile form
   */
  public form: UntypedFormGroup = this._fb.group({
    nickname: ['', Validators.required],
    first_name: ['', [Validators.required, Validators.pattern(ValidationPatterns.letters)]],
    last_name: ['', [Validators.required, Validators.pattern(ValidationPatterns.letters)]],
    date_of_birth: ['', [Validators.required, Validators.pattern(ValidationPatterns.dateOfBirth), CustomValidators.eighteenYearsOld]],
    gender: [null, Validators.required],
    country: [null, Validators.required],
    city: ['', Validators.required],
    address: ['', Validators.required],
    postal_code: ['', Validators.required],
    email: ['', Validators.required],
    mobile_phone: ['', Validators.compose([Validators.required, Validators.pattern(ValidationPatterns.phoneNumber)])],
    state: ['', Validators.required]
  });

  public fields: Observable<UserFieldDescriptor[] & FieldDescriptor[]> = this._userInfo.missingAttributesFor(this.context).pipe(
    map(missedFields => missedFields.map((field: FieldDescriptor) => {
      const options = Boolean(field && field.inclusion && field.inclusion.in && field.inclusion.in.length && field.field !== 'gender') ? {
        options: of(field.inclusion.in).pipe(
          map((data) => data.map(op => [op, op] as [string, string]))
        )
      } : {};
      return userFields.has(field.field) ? {
        ...field,
        ...userFields.get(field.field),
        ...options,
        validators: this._userInfo.isCA && field.field === 'postal_code' ? [
          Validators.required, CustomValidators.changeKey('ca_postal_code_error', Validators.pattern(ValidationPatterns.canadianPostalCode))
        ] : [Validators.required]
      } : {
        ...field,
        type: field.type === 'boolean' ? UserFieldType.CHECKBOX : UserFieldType.TEXT,
        validators: [Validators.required],
        label: field.field,
        ...options
      };
    })),
    tap(missedFields => {
      this.formForModal = this.buildForm(missedFields);
    }),
    tap(() => {
      this._setCallingCode();
      this._initAutocomplete();
      this._setAutoDetectedValues();
    })
  );

  /**
   * Form for missed fields popup
   */
  public formForModal: UntypedFormGroup;

  /**
   * Is form loading
   */
  public loading: boolean;

  /**
   * Emits updating result
   */
  public updated$: EventEmitter<boolean> = new EventEmitter<boolean>();

  /**
   * Current editable field by user
   */
  private _currentEditableField: string;

  public toggleInputs = false;

  public infoIcon: boolean;

  /**
   * Link to modal window
   */
  public modal: ModalRef;

  /**
   * Suggestion lists
   */
  public citiesSuggestion: Array<any> = [];
  public addressesSuggestion: Array<any> = [];

  constructor(public context: string) {
  }

  ngOnDestroy() {
  }

  buildForm(fields: UserFieldDescriptor[] & FieldDescriptor[]): UntypedFormGroup {
    const form: UntypedFormGroup = new UntypedFormGroup({});

    fields.forEach((field: UserFieldDescriptor & FieldDescriptor) => form.addControl(field.field, this._fb.control(null, field.validators)));

    return form;
  }

  /**
   * Returns form control by name
   *
   * @param name
   */
  field(name: string): AbstractControl {
    return this.form.get(name);
  }

  /**
   * Submit form handler
   */
  submit(form: UntypedFormGroup) {
    this._gtm.updateProfileModalSubmitClick('register_2nd_step', 'create-account-btn');
    this._formErrors.applyFormErrors(form, null, true);

    if (form.invalid) {
      return;
    }

    if (this._userInfo.isFR) {
      delete form.value.country;
    }

    const formValue = {
      ...form.value,
      postal_code: this._userInfo.isCA ? form.value?.postal_code?.toUpperCase() : form.value?.postal_code
    };

    this.loading = true;
    this._userInfo.updatePlayerInfo(formValue, this.context).pipe(
      tap(() => {
        this._toastMessage.success('t.profile-updated');
      }),
      switchMap(() => this._user.fetchAllUserData()),
      tap(() => this.updated$.next(true)),
      catchError(error => {
        this._formErrors.applyFormErrors(form, error.error, true);
        this._toastMessage.error(this._formErrors.ssBackendErrorsToArray(error.error)[0] || 't.went-wrong');
        this.updated$.next(false);
        return of(error);
      })
    ).subscribe(() => {
      this.addStateToFields();
      this.loading = false;
    });
  }

  /**
   * Set calling code
   */
  private _setCallingCode() {
    this._env.env$.pipe(
      untilDestroyed(this),
      tap((env: any) => {
        if (env && env.data && env.data.country && this.form.get('mobile_phone')) {
          const { callingCode } = env.data.country;
          this.form.get('mobile_phone').setValue(callingCode || '');
        }
      })
    ).subscribe();
  }

  /**
   * Show input for edit field
   */
  showInput(fieldName: string) {
    if (this._currentEditableField) {
      this.hideInput(this._currentEditableField);
    }
    this._currentEditableField = fieldName;
    this.field(fieldName).enable();
  }

  /**
   * Hide input for edit field
   */
  hideInput(fieldName: string) {
    if (fieldName === this._currentEditableField) {
      this._currentEditableField = null;
    }

    let value: string;
    if (this._user.info[fieldName]) {  // if user have filled data set it
      if (fieldName === 'date_of_birth') {
        value = this._user.info[fieldName].split('-').reverse().join('/');
      } else {
        value = this._user.info[fieldName];
      }
      this.field(fieldName).reset(value);
    } else if (this.field(fieldName).value) { // if user has already filled this field before in current form
      value = this.field(fieldName).value;
      this.field(fieldName).reset(value);
      this.field(fieldName).markAsDirty();
    } else {
      value = null;
      this.field(fieldName).reset(value);
    }

    this.field(fieldName).disable();
  }

  /**
   * Hide all inputs for fields
   */
  hideAllInputs() {
    Object.keys(this.form.controls).forEach(control => this.hideInput(control));
  }

  toggleAllInputs() {

    this.toggleInputs = !this.toggleInputs;

    if (this.toggleInputs) {
      Object.keys(this.form.controls).forEach(control => {
        if (this._user.info[control]) {
          this.field(control).disable();
        } else {
          this.field(control).enable();
        }
      });
    } else {
      this.hideAllInputs();
    }
  }

  addStateToFields() {
    Object.keys(this.form.controls).forEach(control => {
      this.fieldsData.forEach(field => {
        if (this._user.info[control] && field.name === control) {
          field.disabled = true;
        }
      });
    });
    this._changeOrderFields();
  }

  private _changeOrderFields() {
    this.fieldsData.sort((x, y) => {
      return (y.disabled === x.disabled) ? 0 : y.disabled ? -1 : 1;
    });
  }

  /**
   * Init handle input autocomplete
   *
   * @private
   */
  private _initAutocomplete() {
    /**
     * Handle city field
     */
    if (this.formForModal.get('city')) {
      this.formForModal.get('city').valueChanges.pipe(
        startWith(''),
        pairwise(),
        filter(([prev, curr]) => Math.abs(curr.length - prev.length) === 1),
        map(([prev, curr]) => curr),
        debounceTime(300),
        distinctUntilChanged(),
        switchMap(value => this._places.getPredictions({
          query: value,
          country: countryToISO3(this.formForModal.get('country') && this.formForModal.get('country').value || this._user.info.country || this._env.getUserGeo()?.short?.toUpperCase()) || ''
        })),
        map(suggestions => this._places.filterCities(suggestions)),
        tap(suggestions => {
          this.citiesSuggestion = suggestions;
        })
      ).subscribe();
    }


    /**
     * Handle address field
     */
    if (this.formForModal.get('address')) {
      this.formForModal.get('address').valueChanges.pipe(
        startWith(''),
        pairwise(),
        filter(([prev, curr]) => Math.abs(curr.length - prev.length) === 1),
        map(([prev, curr]) => curr),
        debounceTime(300),
        distinctUntilChanged(),
        switchMap(value => this._places.getPredictions({
          query: this.formForModal.get('city').value + ' ' + value,
          country: countryToISO3(this.formForModal.get('country') && this.formForModal.get('country').value || this._user.info.country || this._env.getUserGeo()?.short?.toUpperCase()) || ''
        })),
        map(suggestions => this._places.filterAddress(suggestions)),
        tap(suggestions => {
          this.addressesSuggestion = suggestions;
        })
      ).subscribe();
    }
  }

  /**
   * Select suggested value
   *
   * @param suggestion
   * @param input
   */
  selectSuggestion(suggestion, input: string) {
    switch (input) {
      case 'city':
        if (this.formForModal.get('city')) {
          this.formForModal.get('city').setValue(suggestion.address.city, { emitEvent: true });
        }
        if (this.formForModal.get('postal_code')) {
          this.formForModal.get('postal_code').setValue(suggestion.address.postalCode, { emitEvent: false });
        }
        this.citiesSuggestion = [];
        break;
      case 'address':
        let address = suggestion.address.street;

        if (suggestion.address.houseNumber) {
          address = address + ', ' + suggestion.address.houseNumber;
        }

        if (this.formForModal.get('address')) {
          this.formForModal.get('address').setValue(address, { emitEvent: true });
        }
        if (this.formForModal.get('postal_code')) {
          this.formForModal.get('postal_code').setValue(suggestion.address.postalCode, { emitEvent: false });
        }
        this.addressesSuggestion = [];
        break;
    }
  }

  /**
   * Automatically set known values for register form
   *
   * @private
   */
  private _setAutoDetectedValues() {
    if (!this._platform.isBrowser) {
      return;
    }

    this._commonData.loaded$.pipe(
      debounceTime(100),
      tap(() => {
        if (this.formForModal.get('currency')) {
          if (this._commonData.currencyList.some(currency => currency.code === this._env.env.currency.short)) {
            this.formForModal.get('currency').setValue(this._env.env.currency.short);
          } else {
            this.formForModal.get('currency').setValue(this._commonData.currencyList[0].code);
          }
        }

        if (this.formForModal.get('country')) {
          if (this._userInfo.isFR) {
            this.formForModal.get('country').setValue(' ');
          } else if (this._commonData.countryList.some(country => country.short === this._env.env.country.short)) {
            this.formForModal.get('country').setValue(this._env.env.country.short.toUpperCase());
          }
        }
      })
    ).subscribe();
  }

  /**
   * Reset all suggestion
   */
  public resetSuggestionByType(field: string) {
    setTimeout(() => {
      switch (field) {
        case 'city':
          this.citiesSuggestion = [];
          break;
        case 'address':
          this.addressesSuggestion = [];
          break;
      }
    }, 200);
  }
}
