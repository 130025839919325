import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { PlatformService } from './platform.service';

/** Object that holds the scroll position of the viewport in each direction. */
export interface ViewportScrollPosition {
  top: number;
  left: number;
}

@Injectable({
  providedIn: 'root'
})
export class ScrollService {

  private _previousHTMLStyles = {top: '', left: ''};
  private _previousScrollPosition: { top: number; left: number };
  private _isEnabled = false;
  private _window: any;

  /**
   * Cached result of the check that indicates whether the browser supports scroll behaviors.
   */
  private _scrollBehaviorSupported: boolean | undefined;

  /** Cached viewport dimensions. */
  private _viewportSize: { width: number; height: number } | null;

  constructor(
    private _platform: PlatformService,
    @Inject(DOCUMENT) private _document: Document,
  ) {
    this._window = {};
    if (this._platform.isBrowser) {
      this._window = window;
    }
  }

  public get documentElement() {
    return this._document.documentElement;
  }

  public get bodyElement() {
    return this._document.body;
  }

  /**
   * Blocks page-level scroll
   */
  public blockScroll() {
    if (this._canBeEnabled()) {

      const root = this._document.documentElement!;

      this._previousScrollPosition = this.getViewportScrollPosition();

      // Cache the previous inline styles in case the user had set them.
      this._previousHTMLStyles.left = root.style.left || '';
      this._previousHTMLStyles.top = root.style.top || '';

      // Note: we're using the `html` node, instead of the `body`, because the `body` may
      // have the user agent margin, whereas the `html` is guaranteed not to have one.
      root.style.left = this._coerceCssPixelValue(-this._previousScrollPosition.left);
      root.style.top = this._coerceCssPixelValue(-this._previousScrollPosition.top);
      root.classList.add('scrollblock');
      this._isEnabled = true;
    }
  }

  /**
   * Unblocks page-level scroll
   */
  public UnblockScroll() {
    if (this._isEnabled) {
      const html = this._document.documentElement!;
      const body = this._document.body!;
      const htmlStyle = html.style;
      const bodyStyle = body.style;
      const previousHtmlScrollBehavior = htmlStyle.scrollBehavior || '';
      const previousBodyScrollBehavior = bodyStyle.scrollBehavior || '';

      this._isEnabled = false;

      htmlStyle.left = this._previousHTMLStyles.left;
      htmlStyle.top = this._previousHTMLStyles.top;
      html.classList.remove('scrollblock');

      // Disable user-defined smooth scrolling temporarily while we restore the scroll position.
      // See https://developer.mozilla.org/en-US/docs/Web/CSS/scroll-behavior
      // Note that we don't mutate the property if the browser doesn't support `scroll-behavior`,
      // because it can throw off feature detections in `supportsScrollBehavior` which
      // checks for `'scrollBehavior' in documentElement.style`.
      if (this._supportsScrollBehavior()) {
        htmlStyle.scrollBehavior = bodyStyle.scrollBehavior = 'auto';
      }

      window.scroll(this._previousScrollPosition.left, this._previousScrollPosition.top);

      if (this._supportsScrollBehavior()) {
        htmlStyle.scrollBehavior = previousHtmlScrollBehavior;
        bodyStyle.scrollBehavior = previousBodyScrollBehavior;
      }
    }
  }

  toggleScroll() {
    this._canBeEnabled() ? this.blockScroll() : this.UnblockScroll();
  }

  /**
   * Smooth scroll to DOM element
   *
   * @param el
   * @param duration
   * @param isCustomScroll
   * @param elementTopCustom
   */
  public scrollToElement(el: HTMLElement, duration: number = 1000, isCustomScroll?: boolean, elementTopCustom: number = 0) {
    const startingY: number = this._window.pageYOffset;
    let elementY;
    const self = this;
    if (isCustomScroll) {
      elementY = startingY + elementTopCustom;
    } else {
      elementY = startingY + el.getBoundingClientRect().top;
    }
    const diff: any = elementY - startingY;

    const easing = (t: any) => {
      return t < .5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;
    };

    let start: any;

    if (Math.abs(diff) <= 10) {
      return;
    }

    this._window.requestAnimationFrame(function step(timestamp: any) {
      if (!start) {
        start = timestamp;
      }

      const time = timestamp - start;

      let percent = Math.min(time / duration, 1);
      percent = easing(percent);

      self._window.scrollTo(0, startingY + diff * percent);

      if (time < duration) {
        self._window.requestAnimationFrame(step);
      }
    });
  }

  /**
   * Coerces a value to a CSS pixel value.
   */
  private _coerceCssPixelValue(value: any): string {
    if (value == null) {
      return '';
    }
    return typeof value === 'string' ? value : `${ value }px`;
  }

  private _canBeEnabled(): boolean {
    const html = this._document.documentElement!;
    if (html.classList.contains('scrollblock') || this._isEnabled) {
      return false;
    }
    const body = this._document.body;
    const viewport = this.getViewportSize();
    return body.scrollHeight > viewport.height || body.scrollWidth > viewport.width;
  }

  /**
   * Check whether the browser supports scroll behaviors.
   */
  private _supportsScrollBehavior(): boolean {
    if (this._scrollBehaviorSupported == null) {
      // If we're not in the browser, it can't be supported. Also check for `Element`, because
      // some projects stub out the global `document` during SSR which can throw us off.
      if (typeof this._document !== 'object' || !document || typeof Element !== 'function' || !Element) {
        this._scrollBehaviorSupported = false;
        return this._scrollBehaviorSupported;
      }
      // If the element can have a `scrollBehavior` style, we can be sure that it's supported.
      if ('scrollBehavior' in this._document.documentElement!.style) {
        this._scrollBehaviorSupported = true;
      } else {
        // At this point we have 3 possibilities: `scrollTo` isn't supported at all, it's
        // supported but it doesn't handle scroll behavior, or it has been polyfilled.
        const scrollToFunction: Function | undefined = Element.prototype.scrollTo;
        if (scrollToFunction) {
          // We can detect if the function has been polyfilled by calling `toString` on it. Native
          // functions are obfuscated using `[native code]`, whereas if it was overwritten we'd get
          // the actual function source. Via https://davidwalsh.name/detect-native-function. Consider
          // polyfilled functions as supporting scroll behavior.
          this._scrollBehaviorSupported = !/\{\s*\[native code\]\s*\}/.test(scrollToFunction.toString());
        } else {
          this._scrollBehaviorSupported = false;
        }
      }
    }
    return this._scrollBehaviorSupported;
  }

  /** Use defaultView of injected document if available or fallback to global window reference */
  private _getWindow(): Window {
    return this._document.defaultView || window;
  }

  /**
   * Gets the (top, left) scroll position of the viewport.
   */
  getViewportScrollPosition(): ViewportScrollPosition {
    // While we can get a reference to the fake document
    // during SSR, it doesn't have getBoundingClientRect.
    if (!this._platform.isBrowser) {
      return {top: 0, left: 0};
    }

    // The top-left-corner of the viewport is determined by the scroll position of the document
    // body, normally just (scrollLeft, scrollTop). However, Chrome and Firefox disagree about
    // whether `document.body` or `document.documentElement` is the scrolled element, so reading
    // `scrollTop` and `scrollLeft` is inconsistent. However, using the bounding rect of
    // `document.documentElement` works consistently, where the `top` and `left` values will
    // equal negative the scroll position.
    const document = this._document;
    const window = this._getWindow();
    const documentElement = document.documentElement!;
    const documentRect = documentElement.getBoundingClientRect();

    const top =
      -documentRect.top ||
      document.body.scrollTop ||
      window.scrollY ||
      documentElement.scrollTop ||
      0;

    const left =
      -documentRect.left ||
      document.body.scrollLeft ||
      window.scrollX ||
      documentElement.scrollLeft ||
      0;

    return {top, left};
  }

  /**
   * Returns the viewport's width and height.
   */
  getViewportSize(): Readonly<{ width: number; height: number }> {
    if (!this._viewportSize) {
      this._updateViewportSize();
    }
    const output = {width: this._viewportSize!.width, height: this._viewportSize!.height};
    // If we're not on a browser, don't cache the size since it'll be mocked out anyway.
    if (!this._platform.isBrowser) {
      this._viewportSize = null!;
    }
    return output;
  }

  /**
   * Updates the cached viewport size.
   */
  private _updateViewportSize() {
    const window = this._getWindow();
    this._viewportSize = this._platform.isBrowser
      ? {width: window.innerWidth, height: window.innerHeight}
      : {width: 0, height: 0};
  }

  public restoreScrollState(){
    this.bodyElement.style.top = 0 + 'px';
  }
}
