import { Component, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { tap } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { merge } from 'rxjs';
import {FormsErrorHandlerService} from '../../../services/forms-error-handler.service';
import {PlatformService} from '../../../services/platform.service';

@UntilDestroy()
@Component({
  selector: 'app-form-input',
  templateUrl: './form-input.component.html',
  styleUrls: ['./form-input.component.scss']
})
export class FormInputComponent implements OnInit, OnDestroy {

  /**
   * Text label over input
   */
  @Input() label;

  /**
   * List of errors for current control
   */
  @Input() errors: Array<any> = [];

  /**
   * List of validationMessages for current control
   */
  @Input() validationMessages: Array<string> = [];

  /**
   * Form control
   */
  @Input() control: AbstractControl;

  /**
   * Bottom placeholder
   */
  @Input() bottomPlaceholder: string;

  /**
   * List of messages for current control
   */
  public messages: Array<string> = [];

  constructor(
    private _formErrors: FormsErrorHandlerService,
    private _el: ElementRef,
    private _platform: PlatformService
  ) { }

  ngOnInit() {
    if (this.control) {
      this.errors = this._formErrors.errors(this.control);
      this._updateErrorsAndMessages(this.errors);

      merge(
        this.control.statusChanges,
        this.control.valueChanges
      ).pipe(
        untilDestroyed(this),
        tap(() => {
          const errors = this._formErrors.errors(this.control);
          this._updateErrorsAndMessages(errors);
        })
      ).subscribe();
    }
  }

  ngOnDestroy(): void {
  }

  /**
   * Update errors and messages array for view
   *
   * @param errors
   * @private
   */
  private _updateErrorsAndMessages(errors: string[]) {
    if (errors.some(err => this.validationMessages.includes(err))) {
      this.errors = [];
      this.messages = errors.filter(err => this.validationMessages.includes(err));
    } else {
      this.messages = [];
      this.errors = errors;
    }
  }

}
