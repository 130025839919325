import { Component, OnInit } from '@angular/core';
import {ModalContent, ModalRef} from '../../../modal-ref';
import {FlashPlayerService} from '../../../../services/flash-player.service';

@Component({
  selector: 'app-game-requires-flash',
  templateUrl: './game-requires-flash.component.html',
  styleUrls: ['./game-requires-flash.component.scss']
})
export class GameRequiresFlashComponent implements OnInit, ModalContent {

  /**
   * Access to parent modal
   */
  public modal: ModalRef;

  constructor(
    public flash: FlashPlayerService
  ) { }

  ngOnInit() {
  }

}
