import { Component, OnInit } from '@angular/core';
import { of, ReplaySubject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {ModalContent, ModalRef} from '../../../modal-ref';
import {StaticPageService} from '../../../../../page/static-page/static-page.service';
import {SsApiService} from '../../../../services/api/ss-api.service';
import {PlatformService} from '../../../../services/platform.service';

export enum Tab {
  TERMS = 'terms',
  POLICY = 'policy'
}

@Component({
  selector: 'app-terms-acceptance-form',
  templateUrl: './terms-acceptance-form.component.html',
  styleUrls: ['./terms-acceptance-form.component.scss']
})
export class TermsAcceptanceFormComponent implements OnInit, ModalContent {

  /**
   * Access to parent modal component
   */
  public modal: ModalRef;

  /**
   * Terms page observable
   */
  public terms$: ReplaySubject<any> = this._page.item({
    slug: 'terms-and-conditions'
  });

  /**
   * Terms page observable
   */
  public policy$: ReplaySubject<any> = this._page.item({
    slug: 'privacy-policy'
  });

  /**
   * Is loading
   */
  public loading: boolean;

  /**
   * Active tab
   */
  public currentTab = Tab.TERMS;

  constructor(
    private _page: StaticPageService,
    private _api: SsApiService,
    private _platform: PlatformService
  ) { }

  get Tab() { return Tab; }

  ngOnInit() {
  }

  decline() {
    this.loading = true;
    this._api.usersSignOut().pipe(
      catchError(error => of(error))
    ).subscribe(() => {
      this.loading = true;

      if (this._platform.isBrowser) {
        window.location.href = '/';
      }
    });
  }

  accept() {
    this.loading = true;
    this._api.authProvidersUpdateDetails({
      user: {
        terms_acceptance: true
      }
    }).pipe(
      catchError(error => of(error))
    ).subscribe(() => {
      this.loading = false;
      this.modal.options.data.user.fetchAllUserData();
      this.modal.close();
    });
  }

}
