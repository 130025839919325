import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router
} from '@angular/router';
import { Observable } from 'rxjs';
import { LocalHistoryService } from '../../services/local-history.service';
import {EnvironmentService} from "../../services/environment.service";
import { first, tap } from 'rxjs/operators';
import {UserInfoService} from '../../services/user/user-info.service';

@Injectable({
  providedIn: 'root'
})
export class LoginPageGuard implements CanActivate {
  constructor(
    private _localHistory: LocalHistoryService,
    private _router: Router,
    private _env: EnvironmentService,
    private _userInfo: UserInfoService
  ) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    /**
     * If restrict register - navigate to main page
     */
    this._env.isSignupRestricted$.pipe(
      first(),
      tap(() => {
        if (this._env.isRestrictSignUp && state.url.includes('register')) {
          this._router.navigateByUrl('login').then();
          return false;
        }
      })
    ).subscribe();

    /**
     * Do not rewrite before login page if already exists
     */
    if (this._localHistory.getUrl('before-login')) {
      return true;
    }

    /**
     * Save page from which user navigates to login
     */
    const urlBeforeLoginPage = this._localHistory.history[this._localHistory.history.length - 1] || '/';

    this._localHistory.addUrl(
      'before-login',
      urlBeforeLoginPage.includes('login') || urlBeforeLoginPage.includes('register')
        ? '/'
        : urlBeforeLoginPage
    );
    return true;
  }
}
