import { Injectable } from '@angular/core';
import { BodyService } from '../../../services/body.service';
import { UserService } from '../../../services/user/user.service';
import { BadgeService } from '../../../services/badge.service';
import {ScrollService} from '../../../services/scroll.service';

@Injectable({
  providedIn: 'root'
})
export class SideNavService {

  /**
   * Sidebar state
   */
  private _isOpen: boolean;

  constructor(
    private _scroll: ScrollService,
    public _user: UserService,
    private _badge: BadgeService
  ) { }

  /**
   * Access to side-nav state from outside
   */
  get isOpen(): boolean {
    return this._isOpen;
  }

  /**
   * Open side-nav
   */
  public open() {
    if (!this._isOpen) {
      this._isOpen = true;
      this._scroll.blockScroll();
    }
  }

  /**
   * Close side-nav
   */
  public close() {
    if (this._isOpen) {
      this._isOpen = false;
      this._scroll.UnblockScroll();
    }
  }

  /**
   * Toggle side-nav
   */
  public toggle() {
    if (this._isOpen) {
      this.close();
    } else {
      this.open();
    }
  }

}
