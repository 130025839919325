<div lazy-load-images>
  <router-outlet></router-outlet>
</div>

<div class="language-preloader" *ngIf="translations.loading">
  <div class="language-preloader__bar"></div>
</div>

<ng-container *appShellNoSSR>
  <app-toast-message></app-toast-message>
  <app-sticky-bonus></app-sticky-bonus>
  <ng-container *ngIf="installApp.showInstallAppAfterDepositBanner">
    <app-install-app-banner></app-install-app-banner>
  </ng-container>
</ng-container>

<img src="/assets/svg/top.svg" class="to-top-btn" [ngClass]="{'visible': toTopVisible}" (click)="onToTopClick()">
<textarea class="ios-show-keyboard"></textarea>
