import { InjectionToken } from '@angular/core';
import { SsPaymentsModuleConfig } from './ss-payments-types';

/**
 * API library source
 */
export const LIB_SRC = `/javascripts/payment_methods.js?timestamp=${Date.now()}`;

/**
 * Injection token for module config
 */
export const SsPaymentConfig = new InjectionToken<SsPaymentsModuleConfig>('SsPaymentConfig');

/**
 * Accept header required by SS
 */
export const ACCEPT_HEADER = 'application/vnd.softswiss.v1+json';
