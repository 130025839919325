import { Injectable } from '@angular/core';
import {delay, filter, map, pairwise, startWith, switchMap, tap} from 'rxjs/operators';
import {Observable, of, ReplaySubject, Subject, Subscription} from 'rxjs';
import { SsApiService } from './api/ss-api.service';
import { UserService } from './user/user.service';
import { PlatformService } from './platform.service';
import { SsWebSocketsService } from '../vendor/ss-web-sockets/ss-web-sockets.service';
import { SsWebSocketsPrivateChanel, SsWebSocketsPublicChanel } from '../vendor/ss-web-sockets/ss-web-sockets.types';
import {TranslationService} from '../shared/translation/translation.service';
import {CommonDataService} from './common-data.service';

@Injectable({
  providedIn: 'root'
})
export class WebSocketService {
  /**
   * Socket channels
   */
  private _winners: Subject<any> = new Subject<any>();
  private _bonuses: Subject<any> = new Subject<any>();
  private _freespins: Subject<any> = new Subject<any>();
  private _compPoints: Subject<any> = new Subject<any>();
  private _groups: Subject<any> = new Subject<any>();
  private _payments: Subject<any> = new Subject<any>();
  private _lootboxes: Subject<any> = new Subject<any>();
  private _tournaments: Subject<any> = new Subject<any>();
  private _tournamentsStarted: Subject<any> = new Subject<any>();
  private _limits: Subject<any> = new Subject<any>();
  private _jackpots: Subject<any> = new Subject<any>();
  private _jackpotsWin: Subject<any> = new Subject<any>();

  /**
   * Protected access to channels
   */
  public winners$: Observable<any> = this._winners.asObservable();
  public bonuses$: Observable<any> = this._bonuses.asObservable();
  public freespins$: Observable<any> = this._freespins.asObservable();
  public compPoints$: Observable<any> = this._compPoints.asObservable();
  public groups$: Observable<any> = this._groups.asObservable();
  public payments$: Observable<any> = this._payments.asObservable();
  public lootboxes$: Observable<any> = this._lootboxes.asObservable();
  public tournaments$: Observable<any> = this._tournaments.asObservable();
  public tournamentsStarted$: Observable<any> = this._tournamentsStarted.asObservable();
  public limits$: Observable<any> = this._limits.asObservable();
  public jackpots$: Observable<any> = this._jackpots.asObservable();
  public jackpotsWin$: Observable<any> = this._jackpotsWin.asObservable();

  /**
   * Channels array
   * @private
   */
  private _channels = [
    {channel: SsWebSocketsPrivateChanel.GROUPS_UPDATES, channel$: this._groups},
    {channel: SsWebSocketsPrivateChanel.PAYMENTS_CHANGED, channel$: this._payments},
    {channel: SsWebSocketsPrivateChanel.LOOTBOXES_CHANGED, channel$: this._lootboxes},
    {channel: SsWebSocketsPrivateChanel.TOURNAMENTS_STATUSES, channel$: this._tournaments},
    {channel: SsWebSocketsPrivateChanel.TOURNAMENTS_STARTED, channel$: this._tournamentsStarted},
    {channel: SsWebSocketsPrivateChanel.GAME_LIMITS, channel$: this._limits},
    {channel: SsWebSocketsPrivateChanel.BONUSES_CHANGED, channel$: this._bonuses},
    {channel: SsWebSocketsPrivateChanel.FREESPINS_CHANGED, channel$: this._freespins},
    {channel: SsWebSocketsPublicChanel.JACKPOTS, channel$: this._jackpots},
    {channel: SsWebSocketsPrivateChanel.JACKPOT_WIN_AWARD, channel$: this._jackpotsWin}
  ];

  /**
   * Socket subscriptions
   * @private
   */
  private _subscriptions: Subscription[] = [];

  constructor(
    private _api: SsApiService,
    private _user: UserService,
    private _platform: PlatformService,
    private _ssSockets: SsWebSocketsService,
    private _translate: TranslationService,
    private _commonData: CommonDataService
  ) {}

  public createConnection() {
    this._user.auth$.pipe(
      filter(() => this._platform.isBrowser),
      tap(() => {
        this._ssSockets.disconnect();
        this._unsubscribeFromAllSockets();
      }),
      switchMap(() => {
        return this._api.playerSettings().pipe(
          map((res: any) => res.cent),
          tap((cent: any) => {
            this._ssSockets.init({
              url: cent.url,
              token: cent.token,
              timestamp: cent.timestamp,
              user: of(cent.user) as ReplaySubject<any>
            });
          }),
          switchMap(() => {
            return of(this._channels).pipe(
              map((channel: { channel: SsWebSocketsPublicChanel | SsWebSocketsPrivateChanel, channel$: Subject<any> }[]) =>
                channel.map(item => {
                  const socketSubscription = this._ssSockets.connect(item.channel).pipe(
                    switchMap(notification => Array.isArray(notification) ? of(...notification) : of(notification)),
                    map(e => ({
                      ...e,
                      notify_channel: item.channel
                    }))).subscribe(item.channel$);
                  this._subscriptions.push(socketSubscription);
                  return socketSubscription;
                })),
            );
          })
        )
      })
    ).subscribe();
  }

  /**
   * Unsubscribe from all sockets
   * @private
   */
  private _unsubscribeFromAllSockets() {
    this._subscriptions.forEach(susb => susb?.unsubscribe());
    this._subscriptions = [];
  }
}
