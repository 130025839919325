<form class="form" [formGroup]="form" (ngSubmit)="submit()" [ngClass]="{'deposit-complete': isDepositComplete}">
  <div class="form__title">{{ (isDepositComplete ? 't.deposit-complete' : 't.update-profile') | translate }}</div>

  <div class="input-container">
    <app-form-input class="input-item" *ngIf="field('email')" [errors]="errors.email">
      <input class="input input--simple" type="email" name="email" [placeholder]="'t.enter-email-continue' | translate" formControlName="email">
    </app-form-input>

    <app-form-input class="input-item" *ngIf="field('profile_attributes.currency')" [errors]="errors.currency" formGroupName="profile_attributes">
      <app-select class="select" formControlName="currency">
        <app-select-header class="select__header">{{'t.currency' | translate}}</app-select-header>
        <app-select-dropdown class="select__dropdown">
          <app-select-option class="select__option" *ngFor="let currency of commonData.currencyList" [value]="currency.code">{{ currency.code }}</app-select-option>
        </app-select-dropdown>
      </app-select>
    </app-form-input>

    <app-form-input class="input-item" *ngIf="field('profile_attributes.terms_acceptance')" [errors]="errors.terms_acceptance" formGroupName="profile_attributes">
      <label class="checkbox">
        <input type="checkbox" formControlName="terms_acceptance">
        <span class="checkbox__view"></span>
        <span *ngIf="!isDepositComplete; else depositLabel" class="checkbox__label">{{ 't.years-old' | translate }}
          <a [routerLink]="['/', 'terms']" target="_blank" class="text-color-secondary">{{ 't.terms-and-cond' | translate }}</a> {{ 't.and' | translate }} <a [routerLink]="['/', 'policy']" target="_blank" class="text-color-secondary">{{ 't.privacy-policy' | translate }}&nbsp;*</a>
        </span>

        <ng-template #depositLabel>
          <span class="checkbox__label">{{ 't.years-old' | translate }}
          <span class="checkbox__popup-link" (click)="openInfoModal(terms)">{{ 't.terms-and-cond' | translate }}</span>
           {{ 't.and' | translate }} <span  class="checkbox__popup-link" (click)="openInfoModal(privacy)">{{ 't.privacy-policy' | translate }}&nbsp;*</span>
          </span>
        </ng-template>
      </label>
    </app-form-input>
  </div>

  <div class="submit-container">
    <button class="btn btn--filled-primary" *ngIf="!loading" type="submit">{{'t.save' | translate}}</button>
    <app-preloader *ngIf="loading"></app-preloader>
  </div>
</form>
