import { Injectable, OnDestroy } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { of, ReplaySubject } from 'rxjs';
import {catchError, map, switchMap, tap} from 'rxjs/operators';
import { CmsApiService } from './api/cms-api.service';
import { SsApiService } from './api/ss-api.service';
import { GoogleTagManagerService } from './google-tag-manager.service';
import { UserService } from './user/user.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { EnvironmentService } from './environment.service';

export enum AbTestId {
  DEVWFC1406_A = 'ID367',
  DEVWFC1406_B = 'ID368',
  DEVWFC1713_A = 'ID391',
  DEVWFC1713_B = 'ID392',
  DEVWFC1859_A = 'ID415',
  DEVWFC1859_B = 'ID416'
}

export enum AbTestType {
  DEVWFC1406 = 'DEVWFC1406',
  DEVWFC1713 = 'DEVWFC1713',
  DEVWFC1859 = 'DEVWFC1859'
}

export const abTestPairGroups = [
  [AbTestId.DEVWFC1406_A, AbTestId.DEVWFC1406_B],
  [AbTestId.DEVWFC1713_A, AbTestId.DEVWFC1713_B],
  [AbTestId.DEVWFC1859_A, AbTestId.DEVWFC1859_B],
];

@Injectable({
  providedIn: 'root'
})
export class AbTestService implements OnDestroy {

  public abTestId = AbTestId;
  private _abTest: string = null;
  private _abTestType: string = null;
  public abTest$: ReplaySubject<any> = new ReplaySubject(1);

  /**
   * These variables are used so that ab tests do not overwrite each other
   */
  private _abTestDEVWFC1406: string = null;
  private _abTestDEVWFC1713: string = null;
  private _abTestDEVWFC1859: string = null;

  /**
   * These variables are used so that ab tests do not overwrite each other
   */
  public abTestDEVWFC1406$: ReplaySubject<any> = new ReplaySubject(1);
  public abTestDEVWFC1713$: ReplaySubject<any> = new ReplaySubject(1);
  public abTestDEVWFC1859$: ReplaySubject<any> = new ReplaySubject(1);

  constructor(
    private _api: SsApiService,
    private _cmsApi: CmsApiService,
    private _cookie: CookieService,
    private _gtm: GoogleTagManagerService,
    private _user: UserService,
    private _device: DeviceDetectorService,
    private _env: EnvironmentService,
  ) {
  }

  ngOnDestroy() {
  }

  public get currentAbTest(): string { return this._abTest; }

  public get currentAbTestType(): string { return this._abTestType; }

  public get currentAbTestDEVWFC1406(): string {
    return this._abTestDEVWFC1406;
  }

  public get currentAbTestDEVWFC1713(): string {
    return this._abTestDEVWFC1713;
  }

  public get currentAbTestDEVWFC1859(): string {
    return this._abTestDEVWFC1713;
  }

  public getAbTestDEVWFC1406() {
    const abTestGroup = this._user.auth && this._user?.info?.statuses?.find(g => g?.id === this.abTestId.DEVWFC1406_A || g?.id === this.abTestId.DEVWFC1406_B);
    return abTestGroup ? abTestGroup?.id : this._cookie.get(AbTestId.DEVWFC1406_A) || this._cookie.get(AbTestId.DEVWFC1406_B);
  }

  public getAbTestDEVWFC1713() {
    const abTestGroup = this._user.auth && this._user?.info?.statuses?.find(g => g?.id === this.abTestId.DEVWFC1713_A || g?.id === this.abTestId.DEVWFC1713_B);
    return abTestGroup ? abTestGroup?.id : this._cookie.get(AbTestId.DEVWFC1713_A) || this._cookie.get(AbTestId.DEVWFC1713_B);
  }

  public getAbTestDEVWFC1859() {
    const abTestGroup = this._user.auth && this._user?.info?.statuses?.find(g => g?.id === this.abTestId.DEVWFC1859_A || g?.id === this.abTestId.DEVWFC1859_B);
    return abTestGroup ? abTestGroup?.id : this._cookie.get(AbTestId.DEVWFC1859_A) || this._cookie.get(AbTestId.DEVWFC1859_B);
  }

  public get isDEVWFC1406() {
    return this._abTestType === AbTestType.DEVWFC1406;
  }

  public get isDEVWFC1713() {
    return this._abTestType === AbTestType.DEVWFC1713;
  }

  public get isDEVWFC1859() {
    return this._abTestType === AbTestType.DEVWFC1859;
  }

  /**
   * Check if user has exist ab test before load new ab test
   */
  public checkExistABTest$(exists: () => {}, isPartner?: boolean, alias?: string, variableName?: string) {
    const abTest = exists.call(this);

    if (abTest) {
      this._setAbTest(abTest, variableName);
      return of(abTest);
    } else {
      return this.loadABTest$(isPartner, alias, variableName);
    }
  }

  /**
   * Load ABTest for any test
   */
  public loadABTest$(isPartner?: boolean, alias?: string, variableName?: string) {

    const headers = {
      'Content-Type': 'application/json',
      'u-group': alias,
      'DEVICE-TYPE': this._device.isDesktop() ? 'Desktop' : this._device.isTablet() ? 'Tablet' : 'Mobile'
    };

    return this._cmsApi.loadABTest(null, isPartner ? {} : { headers }).pipe(
      map((e: {data: any[]}) => {
        if (!e.data || !e.data.length || !e.data[0]) {
          return null;
        }
        return e.data[0].slug;
      }),
      map(adTest => {
        this._setAbTest(adTest, variableName);
        this._gtm.setAbTest('ab-test', this._abTestType, adTest);
        return adTest;
      }),
      catchError(e => of(null))
    );
  }

  /**
   * Set AB test cookie data
   */
  private _setAbTest(abTest, variableName?: string) {
    if (abTest) {
      this._removePreviousAbTestCookie(abTest);
      this._resolveAbTestType(abTest);
      this._cookie.set(abTest, abTest, 999, '/');
      this[variableName ? `${variableName}` : '_abTest'] = abTest;
    }
    if (variableName) {
      this[`${variableName.slice(1)}$`].next(abTest);
    }

    this.abTest$.next(abTest);
  }

  /**
   * Resolve AB test by type
   * @param abTest
   */
  private _resolveAbTestType(abTest) {
    switch (abTest) {
      case AbTestId.DEVWFC1406_A:
      case AbTestId.DEVWFC1406_B:
        this._abTestType = AbTestType.DEVWFC1406;
        break;
      case AbTestId.DEVWFC1713_A:
      case AbTestId.DEVWFC1713_B:
        this._abTestType = AbTestType.DEVWFC1713;
        break;
      case AbTestId.DEVWFC1859_A:
      case AbTestId.DEVWFC1859_B:
        this._abTestType = AbTestType.DEVWFC1859;
        break;
      default:
        break;
    }
  }

  /**
   * Add user to AB test group after registration
   */
  public addUserToABTestGroup(abTestGroup, variableName?: string) {
    if (abTestGroup) {
      this._setAbTest(abTestGroup, variableName);
      const pairGroups = abTestPairGroups.find(groups => groups.includes(abTestGroup));

      if (!this.checkExistUserGroups(this._user?.info?.statuses || [], pairGroups)) {
        return this._api.postPlayerGroups({groups: {add: [abTestGroup]}})
          .pipe(catchError(error => of(error))).subscribe();
      }
    }
  }

  /**
   * Check if user has one of A or B test group, by ab test list
   * @param userStatusList
   * @param abTestIdList
   */
  public checkExistUserGroups(userStatusList: any[], abTestIdList: any[]) {
    if (userStatusList && userStatusList.some(status => abTestIdList.includes(status.id))) {
      return userStatusList.find(userStatus => abTestIdList.includes(userStatus.id)).id ;
    } else {
      return null;
    }
  }

  /**
   * Remove previous ab test cookie
   * @private
   */
  private _removePreviousAbTestCookie(abTest: AbTestId) {
    const pairGroups = abTestPairGroups.find(groups => groups.includes(abTest));
    pairGroups?.forEach(g => this._cookie.delete(g));
  }
}
