import { Injectable } from '@angular/core';
import {Observable, of, timer} from 'rxjs';
import {map} from 'rxjs/operators';
import {PlatformService} from './platform.service';


/**
 * Available items statuses
 */
export enum TimeStatus {
  UPCOMING = 'upcoming',
  ACTIVE = 'active',
  CLOSED = 'closed'
}

@Injectable({
  providedIn: 'root'
})
export class TimeService {

  constructor(
    private _platform: PlatformService
  ) { }

  /**
   * Convert provided date string to local date object
   *
   * @param date
   */
  public toLocalDate(date?: string): Date {
    if (date) {
      const dateNumbers = date.split(/\D/);
      return new Date(Date.UTC(
        parseInt(dateNumbers[0], 10),
        parseInt(dateNumbers[1], 10) - 1,
        parseInt(dateNumbers[2], 10),
        parseInt(dateNumbers[3], 10),
        parseInt(dateNumbers[4], 10),
        parseInt(dateNumbers[5], 10),
        parseInt(dateNumbers[6], 10)
      ));
    } else {
      return new Date();
    }
  }

  /**
   * Returns diff object between current and provided time
   *
   * @param targetTime
   */
  public timeDiff(targetTime: Date) {
    const currentDate = new Date();
    const dateDiff = Math.abs(targetTime.getTime() - currentDate.getTime());
    const days = Math.floor(dateDiff / (1000 * 3600 * 24));
    const hours = Math.floor(dateDiff / (1000 * 3600) - days * 24);
    const minutes = Math.floor(((dateDiff % (1000 * 3600 * 24)) % (1000 * 3600)) / (1000 * 60));
    const seconds = Math.floor(((dateDiff % 60000) / 1000));
    return {
      days,
      hours,
      minutes,
      seconds,
      daysLabel: this.pluralizeDateLabel(days, 't.day'),
      hoursLabel: this.pluralizeDateLabel(hours, 't.hr'),
      minLabel: this.pluralizeDateLabel(minutes, 't.min'),
      secLabel: this.pluralizeDateLabel(minutes, 't.sec'),
    };
  }

  /**
   * Pluralise label string depend on provided number
   *
   * @param num
   * @param label
   */
  public pluralizeDateLabel(num: number, label: string) {
    return num > 1 ? label + 's' : label;
  }

  public currentDate$(): Observable<any> {
    if (this._platform.isBrowser) {
      const interval$ = timer(0, 1000);
      return interval$.pipe(
        map(() => {
          const now = new Date();
          const min = (now.getMinutes() < 10 ? '0' : '') + now.getMinutes(); // minutes
          const h = (now.getHours() < 10 ? '0' : '') + now.getHours(); // hours
          return {min, h};
        })
      );
    } else {
      return of({min: '', h: ''});
    }
  }


  /**
   * Returns tournament status from provided dates
   *
   * @param start
   * @param end
   * @private
   */
  public resolveTimeStatus(start: Date, end: Date): TimeStatus {

    const currentDate = new Date();

    if (currentDate < start) {
      return TimeStatus.UPCOMING;
    } else if (currentDate > end) {
      return TimeStatus.CLOSED;
    } else {
      return TimeStatus.ACTIVE;
    }
  }
  public detectEndOfDay(): string {
    const end = new Date();
    end.setHours(23, 59, 59, 999);
    return end.toISOString();
  }
  /**
   * Check if is it today date
   *
   * @param date
   */
  public isTodayDate(date: Date): boolean {
    const currentDate = new Date();
    const diffUTC = date.getTimezoneOffset();

    date.setHours(date.getHours() + diffUTC / 60);

    return currentDate < date;
  }

  /**
   * Convert date to timestamp
   * @param strDate
   * @private
   */
  public dateToTimestamp(strDate) {
    return Date.parse(strDate) / 1000;
  }
}
