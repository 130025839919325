/**
 * Bonus stage list
 */
export enum BonusStage {
  HANDLE_BETS = 'handle_bets',
  LOST = 'lost',
  CANCELED = 'canceled',
  PLAYED = 'played',
  ISSUED = 'issued',
  EXPIRED = 'expired',
  WAGER_DONE = 'wager_done',
  ACTIVATED = 'activated',
  FINISHED = 'finished',
  XMAS_FS = 'xmas_fs',
  XMAS_LOOTBOX = 'lootbox_fs',
}

export enum BonusCodeStatus {
  FAILED_TO_ACTIVATE = 'failed_to_activate',
  ALREADY_ACTIVATED = 'already_activated',
  SUCCESSFULLY_ACTIVATED = 'successfully_activated'
}

/**
 * Text labels for bonus stages
 */
export const BonusStageLabel = {
  [BonusStage.HANDLE_BETS]: 'Handle bets',
  [BonusStage.LOST]: 'Lost',
  [BonusStage.CANCELED]: 'Canceled',
  [BonusStage.PLAYED]: 'Played',
  [BonusStage.ISSUED]: 'Issued',
  [BonusStage.EXPIRED]: 'Expired',
  [BonusStage.WAGER_DONE]: 'Wager done',
  [BonusStage.ACTIVATED]: 'Activated',
  [BonusStage.FINISHED]: 'Finished',
  [BonusStage.XMAS_FS]: 'xmas_fs',
  [BonusStage.XMAS_LOOTBOX]: 'lootbox_fs',
};

/**
 * Deposit bonus config contract
 */
export interface DepositBonusConfig {
  img: string;
  color: string;
}

/**
 * Images for deposit bonuses
 */
export const DepositBonuses = new Map<string, DepositBonusConfig>([
  ['default', {img: '/assets/img/bonuses/deposit/1st_deposit.png', color: '#9ef6d6'}],
  ['first_deposit_bonus', {img: '/assets/img/bonuses/deposit/1st_deposit.png', color: '#9ef6d6'}]
]);
