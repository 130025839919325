<aside
       [ngClass]="{open: sidenav.isOpen}"
       ClickOutside
       (clickOutside)="sidenav.close()"
       [options]="{ignoreOutsideComponents: ['.header .menu']}"
       class="sidebar">
  <div class="sidebar-content" id="sidebar-content">
    <div class="sidebar-content__top">
      <div class="sidebar-content__header">
        <div class="close-btn" (click)="sidenav.close()"><i class="icon icon-close"></i></div>
        <a [href]="seo.originUrl + '/' + LANG">
          <img *ngIf="!christmas.isPromoLogo" [src]="'/assets/svg/logo.svg'" class="sidebar-logo" alt="Wild Fortune">
          <img *ngIf="christmas.isPromoLogo" [src]="'/assets/img/promo/xmas/logo.svg'" class="sidebar-logo"
               alt="Wild Fortune">

        </a>
      </div>
      <nav class="nav">
        <a class="nav__item nav__item--play" [routerLink]="['/', this.LANG, 'games']" (click)="closeMenu()">
          {{ 't.play' | translate }}
        </a>
        <ng-container *ngIf="abTest.currentAbTestDEVWFC1406 === abTest.abTestId.DEVWFC1406_A">
          <ng-container *ngIf="jackpot.cmsJackpot$ | async as cmsJackpot">
            <a class="nav__item nav__item--jackpot" routerLinkActive="active"
               [routerLink]="['/', this.LANG, 'jackpot', jackpot.jackpotSlug]" (click)="closeMenu()">
              <img class="nav__item-icon" src="/assets/img/jackpot/side-menu.png" alt="Jackpot link img">
              {{ cmsJackpot?.Title | ignoreHtmlTags }}</a>
            <hr class="divider">
          </ng-container>
        </ng-container>
        <ng-container *ngIf="halloween.linkMenuEnabled">
          <a class="nav__item nav__item--halloween" routerLinkActive="active"
             [routerLink]="['/', this.LANG, 'promo', 'halloween']" (click)="closeMenu()">
            <img class="nav__item-icon" src="/assets/img/promo/halloween/side-menu.gif" alt="Active promo link img">
            <div class="text-container">
              <span>{{ 't.halloween' | translate }}</span>
              <span class="small">{{ 't.treasure-valley' | translate }}</span>
            </div>
          </a>
          <hr class="divider">
        </ng-container>
        <ng-container *ngIf="christmas.linkMenuEnabled">
          <a class="nav__item nav__item--christmas" routerLinkActive="active"
             [routerLink]="['/', this.LANG, 'promo', 'christmas']" (click)="closeMenu()">
            <img class="nav__item-icon" src="/assets/img/promo/xmas/side-menu.svg" alt="Active promo link img">
            <div class="text-container">
              <span>{{ 't.xmas-gift-calendar' | translate }}</span>
            </div>
          </a>
          <hr class="divider">
        </ng-container>
        <a class="nav__item" routerLinkActive="active" [routerLink]="['/', this.LANG, 'account']" (click)="closeMenu()">
          <i class="nav__item-icon icon-account"></i> {{ 't.account' | translate }}</a>
        <hr class="divider">
        <ng-container *ngIf="!env.isSe">
          <a class="nav__item" routerLinkActive="active" [routerLink]="['/', this.LANG, 'bonuses']" (click)="closeMenu()">
            <i class="nav__item-icon icon-bonuses"></i> {{ 't.bonuses' | translate }}</a>
          <hr class="divider">
        </ng-container>
        <ng-container *ngIf="tournamentList$ | async as tournaments">
          <ng-container *ngIf="tournaments && tournaments.length && !env.isSe">
            <a class="nav__item" routerLinkActive="active" [routerLink]="['/', this.LANG, 'tournaments']" (click)="closeMenu()">
              <i class="nav__item-icon icon-tournaments"></i> {{ 't.tournaments' | translate }}</a>
            <hr class="divider">
          </ng-container>
        </ng-container>
        <a class="nav__item" routerLinkActive="active" [routerLink]="['/', this.LANG, 'mobile-app']"   (click)="closeMenu()">
          <i class="nav__item-icon icon-install-app"></i> {{ 't.install-app' | translate }}</a>
        <hr class="divider">
        <ng-container *ngIf="zendesk.available">
          <div class="nav__item" routerLinkActive="active" (click)="zendesk.toggle(); closeMenu(); $event.stopPropagation()">
            <i class="nav__item-icon icon-help"></i> {{ 't.help' | translate }}</div>
          <hr class="divider">
        </ng-container>

        <button *ngIf="auth" class="dep-btn btn btn--filled-primary big" (click)="onDepositButtonClick(); closeMenu()">{{ 't.deposit' | translate }}</button>
        <a *ngIf="!auth && !env.isRestrictSignUp" class="dep-btn btn btn--filled-primary big"
           [routerLink]="['/', this.LANG, 'register']" (click)="closeMenu()">{{ 't.sign-up' | translate }}</a>


       <div class="lang" (click)="handleClickForScroll()">
         <div #accordion=accordionDirective Accordion class="lang__header">
           <span class="lang-item">
             <img class="lang-item__icon" [src]="'/assets/svg/lang/' + LANG + '.svg'" alt="">
             <span class="lang-item__label">{{ LANG }}</span>
             <span class="sidebar-chevron sidebar-chevron--lang" [class.rotated]="accordion.isOpen"></span>
           </span>
         </div>

         <div class="lang__list">
           <ng-container *ngFor="let lang of env.env.languageList">
              <span *ngIf="LANG !== lang.short" class="lang-item" (click)="changeLanguage(lang.short)">
                <img class="lang-item__icon" [src]="'/assets/svg/lang/' + lang.short + '.svg'" alt="">
                <span class="lang-item__label">{{ lang.short }}</span>
           </span>
           </ng-container>
         </div>
       </div>

      </nav>
    </div>

    <div id="settings-block" class="sidebar-content__bottom">
      <nav #navBottom class="nav-bottom" (click)="onSettingsClick()">
        <div class="nav-bottom__settings">
          <i class="nav__item-icon icon-settings"></i>
          <div class="settings" [class.open]="isSettingsOpened">{{ 't.settings' | translate }}</div>
        </div>
        <span class="sidebar-chevron" [class.rotated]="isSettingsOpened"></span>
      </nav>

      <hr class="divider" *ngIf="isSettingsOpened">

      <nav class="nav settings-block" [class.open]="isSettingsOpened" *ngIf="isSettingsOpened">
        <ng-container *ngIf="auth">
          <a class="nav__item" routerLinkActive="active" [routerLink]="'/' + LANG + '/account/profile'" (click)="closeMenu()">
            {{ 't.profile' | translate }}
          </a>
          <a class="nav__item" routerLinkActive="active" [routerLink]="'/' + LANG + '/account/documents'" (click)="closeMenu()">
            {{ 't.documents' | translate }}
          </a>
        </ng-container>
        <a class="nav__item" routerLinkActive="active" [routerLink]="'/' + LANG + '/blog'" (click)="closeMenu()">
          {{ 't.blog' | translate }}
        </a>
        <a class="nav__item" routerLinkActive="active" [routerLink]="'/' + LANG + '/responsible-gaming'" (click)="closeMenu()">
          {{ 't.responsible-gaming' | translate }}
        </a>
        <a *ngIf="!env.isSe" class="nav__item" routerLinkActive="active" [routerLink]="'/' + LANG + '/loyalty-program'" (click)="closeMenu()">
          {{ 't.bonuses-loyalty' | translate }}
        </a>
        <a class="nav__item" routerLinkActive="active" [routerLink]="'/' + LANG + '/banking'" (click)="closeMenu()">
          {{ 't.banking' | translate }}
        </a>
        <a class="nav__item" routerLinkActive="active" [routerLink]="'/' + LANG + '/about'" (click)="closeMenu()">
          {{ 't.about-us' | translate }}
        </a>
        <a class="nav__item" routerLinkActive="active" [routerLink]="'/' + LANG + '/terms-and-conditions'" (click)="closeMenu()">
          {{ 't.terms-and-cond' | translate }}
        </a>
        <a *ngIf="!env.isSe" class="nav__item" routerLinkActive="active" [routerLink]="'/' + LANG + '/bonus-terms'" (click)="closeMenu()">
          {{ 't.bonus-terms' | translate }}
        </a>
        <a class="nav__item" routerLinkActive="active" [routerLink]="'/' + LANG + '/privacy-policy'" (click)="closeMenu()">
          {{ 't.privacy-policy' | translate }}
        </a>
        <a class="nav__item" routerLinkActive="active" [routerLink]="'/' + LANG + '/faq'" (click)="closeMenu()">
          {{ 't.faq' | translate }}
        </a>
        <a class="nav__item" id="test" target="_blank" href="https://www.samuraipartners.com">
          {{ 't.becom-aff' | translate }}
        </a>
        <a class="nav__item" *ngIf="auth"  (click)="logout()">
          {{ 't.logout' | translate }}
        </a>
      </nav>
    </div>

  </div>
</aside>
