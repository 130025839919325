import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class PlatformService {

  private _browser: boolean;

  constructor(@Inject(PLATFORM_ID) platformId: any) {
    this._browser = isPlatformBrowser(platformId);
  }

  get isBrowser(): boolean {
    return this._browser;
  }
}
