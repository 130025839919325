
export interface Dictionary {
  'en': any;
}

/**
 * Available languages
 */
export const DICTIONARY: Dictionary = {
  en: null
};
