import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AccordionDirective} from './accordion.directive';
import {ClickOutsideDirective} from './click-outside.directive';
import {PasswordShowDirective} from './password-show.directive';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {AppShellNoSSRDirective} from './app-shell-no-ssr.directive';
import {DeleteNotFoundDirective} from './delete-not-found.directive';
import {ClassLoadedDirective} from './class-loaded.directive';
import {DobMaskDirective} from './dob-mask.directive';
import {InputNumberDirective} from './input-number.directive';
import {CropTextDirective} from './crop-text.directive';
import {MemoizeInvalidDirective} from './memoize-invalid.directive';
import {FilterInputDirective} from './filter-input.directive';
import {MoveNextFieldDirective} from './move-next-field.directive';
import {SetValueAutofillDirective} from './set-input-value-ios.directive';
import {RouterLinkDirective} from './router-link.directive';
import {DefaultImage} from './default-image.directive';
import {NumberOnlyDirective} from './only-number.directive';
import {FontByBlockSizeDirective} from './font-by-block-size.directive';
import {StageOnlyDirective} from './stage-only.directive';
import { LazyLoadImagesDirective } from './ngx-lazy-load-images.directive';
import {ImgDimensionDirective} from './img-dimension.directive';

@NgModule({
  declarations: [
    AccordionDirective,
    ClickOutsideDirective,
    PasswordShowDirective,
    AppShellNoSSRDirective,
    DeleteNotFoundDirective,
    ClassLoadedDirective,
    DobMaskDirective,
    InputNumberDirective,
    CropTextDirective,
    MemoizeInvalidDirective,
    FilterInputDirective,
    MoveNextFieldDirective,
    SetValueAutofillDirective,
    RouterLinkDirective,
    DefaultImage,
    NumberOnlyDirective,
    FontByBlockSizeDirective,
    StageOnlyDirective,
    LazyLoadImagesDirective,
    ImgDimensionDirective
  ],
  imports: [
    CommonModule,
    InfiniteScrollModule
  ],
  exports: [
    AccordionDirective,
    ClickOutsideDirective,
    PasswordShowDirective,
    InfiniteScrollModule,
    AppShellNoSSRDirective,
    DeleteNotFoundDirective,
    ClassLoadedDirective,
    DobMaskDirective,
    InputNumberDirective,
    CropTextDirective,
    MemoizeInvalidDirective,
    FilterInputDirective,
    MoveNextFieldDirective,
    SetValueAutofillDirective,
    RouterLinkDirective,
    DefaultImage,
    NumberOnlyDirective,
    FontByBlockSizeDirective,
    StageOnlyDirective,
    LazyLoadImagesDirective,
    ImgDimensionDirective
  ]
})
export class DirectivesModule {
}
