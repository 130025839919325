import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map, share } from 'rxjs/operators';
import { PlatformService } from './platform.service';

@Injectable({
  providedIn: 'root'
})

/**
 * ************************ LIBRARY DOCUMENTATION *****************************
 *
 * https://developer.here.com/documentation/geocoder-autocomplete/topics/quick-start-get-suggestions.html
 *
 * ****************************************************************************
 */
export class PlacesApiService {

  /**
   * APP ID for api credential
   */
  private readonly APP_ID = '';

  /**
   * APP CODE for api credential
   */
  private readonly APP_CODE = '';

  /**
   * Api Key - uses for new version of API
   */
  private readonly API_KEY = '35l6yXlnh6RwmTjjHyCujdAiue1wDPCFAVxl_b7bwbQ';

  constructor(
    private _platform: PlatformService,
    private _http: HttpClient
  ) {}

  /**
   * Get location predictions by provided params
   *
   * @param options List of available options: https://developer.here.com/documentation/geocoder-autocomplete/topics/resource-suggest.html
   */
  public getPredictions(options): Observable<any> {
    return this._http.get(`https://autocomplete.geocoder.ls.hereapi.com/6.2/suggest.json`, {
      params: {
        apiKey: this.API_KEY,
        maxresults: 20,
        ...options
      }
    }).pipe(
        share(),
        map((response: any) => response.suggestions),
    );
  }

  /**
   * Returns list of cities from list of all suggestions
   *
   * @param suggestionList
   * @param maxCount
   */
  public filterCities(suggestionList: Array<any>, maxCount: number = 3): Array<any> {
    let count = 0;

    return !suggestionList ? [] : suggestionList.filter(suggestion => {
      if (count < maxCount && suggestion.matchLevel === 'city') {
        count ++;
        return true;
      }
    });
  }

  /**
   * Returns list of streets from list of all suggestions
   *
   * @param suggestionList
   * @param city
   * @param maxCount
   */
  public filterAddress(suggestionList: Array<any>, city: string = null, maxCount: number = 3): Array<any> {
    let count = 0;

    return !suggestionList ? [] : suggestionList.filter(suggestion => {
      if (count < maxCount && (
        suggestion.matchLevel === 'houseNumber' ||
        suggestion.matchLevel === 'street')
      ) {
        if (city) {
          if (suggestion.address.city === city) {
            count ++;
            return true;
          }
        } else {
          count ++;
          return true;
        }
      }
    });
  }

}
