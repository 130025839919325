import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { LoginFormController } from '../../../helpers/user/login-form-controller';
import { Router } from '@angular/router';
import { UserService } from '../../../services/user/user.service';
import { GoogleTagManagerService } from '../../../services/google-tag-manager.service';
import { EnvironmentService } from '../../../services/environment.service';
import {InstallAppService} from '../../../services/install-app.service';
import {ModalService} from '../../../modal-v2/modal.service';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss']
})
export class LoginFormComponent implements OnInit {

  @Output()
  public close$: EventEmitter<boolean> = new EventEmitter<boolean>();


  /**
   * Controller for current form
   */
  public controller: LoginFormController = new LoginFormController();

  constructor(
    private _modal: ModalService,
    private _router: Router,
    public user: UserService,
    private _gtm: GoogleTagManagerService,
    public env: EnvironmentService,
    public installApp: InstallAppService
  ) { }

  ngOnInit() {
    this.controller.loggedIn$.subscribe(user => {
      this._gtm.signInSuccess(user.id);
    });
  }

  async openRestorePasswordModal() {
    const component = await import('../../../modal-v2/components/lazy/restoration-instruction-form/restoration-instruction-form.component');
    await this._modal.openLazy(component?.RestorationInstructionFormComponent);
  }

  onSignUpClick() {
    this._router.navigateByUrl('/register').then();
  }

  onClose() {
    this.close$.next(true);
  }
}
