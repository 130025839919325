import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { DirectivesModule } from '../../directives/directives.module';
import { PipesModule } from '../../pipes/pipes.module';
import { TranslationModule } from '../../translation/translation.module';
import { SideNavComponent } from './side-nav.component';

@NgModule({
  declarations: [
    SideNavComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    PipesModule,
    TranslationModule,
    DirectivesModule,
  ],
  exports: [
    SideNavComponent
  ]
})
export class SideNavModule { }
