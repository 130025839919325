import { Injectable } from '@angular/core';
import { PlatformService } from './platform.service';

@Injectable({
  providedIn: 'root'
})
export class PostMessageService {

  constructor(
    private _platform: PlatformService
  ) { }

  /**
   * Send post message when page opens in Iframe
   *
   * @param data
   */
  public send(data: object) {
    if (this._platform.isBrowser && window.parent && window.self !== window.top) {
      window.parent.postMessage(data, window.location.origin);
    }
  }
}
