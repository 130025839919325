import { Injectable } from '@angular/core';
import { CmsApiService } from '../../core/services/api/cms-api.service';
import { CmsContentMapperService } from '../../core/services/cms-content-mapper.service';
import {forkJoin, ReplaySubject} from 'rxjs';
import { UpdateOnLangChange } from '../../core/shared/decorators/update-on-lang-change';
import { map } from 'rxjs/operators';
import {SysNotificationsService} from '../../core/services/sys-notifications.service';

export enum TemplateType {
  TEXT = 'text',
  ACCORDION = 'accordion',
  CONTACTS = 'contacts',
  BANKING = 'banking',
  VIP = 'vip',
  LOYALTY = 'loyalty',
  REVIEWS = 'reviews'
}

@Injectable({
  providedIn: 'root'
})
export class StaticPageService {

  constructor(
    private _api: CmsApiService,
    private _mapper: CmsContentMapperService,
    private _sysNotification: SysNotificationsService
  ) { }

  /**
   * Get page item from CMS and prepare for using in frontend
   *
   * @param params
   */
  @UpdateOnLangChange('page/item')
  item(params: object = {}): ReplaySubject<any> {
    return forkJoin([
      this._api.pageItem(params),
      this._api.sysNotificationList({page_slug: params['slug']})
    ]).pipe(
      map(([page, notifications]) => {
        if (page.statusCode === 200) {
          const pageData = this._mapper.mapCmsData(page.data, {
            template_slug: 'template.slug',
            slug: 'slug',
            publishAt: 'publishAt.date',
            unpublishAt: 'unpublishAt.date',
            name: 'name'
          });
          if (pageData.length) {
            this._sysNotification.checkSysNotification(notifications.data ? notifications.data.list : null, page.data[0].slug);
          }
          return pageData;
        } else {
          return [{
            statusCode: page.statusCode
          }];
        }
      })
    ) as ReplaySubject<any>;
  }
}
