import { AfterViewInit, Directive, OnDestroy } from '@angular/core';
import { NgControl, ValidationErrors } from '@angular/forms';
import { distinctUntilChanged, filter, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';

/**
 * Memoize invalid values to prevent submit invalid value (when error comes from backend)
 */
@Directive({
  selector: '[memoizeInvalid]'
})
export class MemoizeInvalidDirective implements AfterViewInit, OnDestroy {

  constructor(
    private _control: NgControl
  ) { }

  ngAfterViewInit() {
    if (!this._control) {
      throw new Error('Works only with form controls');
    }

    const errors: Map<string, ValidationErrors> = new Map();

    this._control.statusChanges.pipe(
      filter(() => this._control.invalid),
      switchMap(() => of(this._control.value)),
      filter(value => !errors.has(value)),
      distinctUntilChanged(),
      tap(value => errors.set(value, this._control.errors))
    ).subscribe();

    this._control.valueChanges.pipe(
      filter(value => errors.has(value)),
      tap(value => this._control.control.setErrors(errors.get(value)))
    ).subscribe();
  }

  ngOnDestroy(): void {
  }
}
