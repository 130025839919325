<div class="popup">
  <div class="popup__title">{{ 't.game-not-available' | translate }}</div>
  <div class="popup__text">
    <ng-container *ngIf="!modal.options?.data?.currencyList">{{ 't.game-not-found' | translate }}</ng-container>

    <ng-container *ngIf="modal.options?.data?.currencyList">
      <div>{{ translateWithDynamicVariable }}</div>
      <br>
      <div>{{ 't.supports-only' | translate }}:
        <br>
        <strong *ngFor="let currency of modal.options?.data?.currencyList; let i = index"><span *ngIf="i !== 0">,</span> {{ currency }}</strong>
      </div>
    </ng-container>
  </div>
  <div class="popup__actions">
    <button class="btn big btn--primary" (click)="modal.close()">Ok</button>
  </div>
</div>
