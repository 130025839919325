import { Component, OnInit, Type, ViewChild, ViewContainerRef } from '@angular/core';
import { fadeInOut, showHide } from './modal-animations';
import { ModalTemplateRef } from './modal-ref';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  animations: [fadeInOut, showHide]
})
export class ModalComponent implements OnInit {

  @ViewChild('modalOverlay', {static: true, read: ViewContainerRef }) public modalOverlay: ViewContainerRef;
  @ViewChild('modalContainer', {static: true, read: ViewContainerRef }) public modalContainer: ViewContainerRef;

  public template;

  constructor() {}

  ngOnInit() {
  }

  createModal(component: Type<any>, template: any) {
    this.modalContainer.clear();
    const modalTemplateComponent = this.modalContainer.createComponent(template as Type<ModalTemplateRef>);
    const modalContentComponent = modalTemplateComponent.instance.contentRef.createComponent(component);
    modalContentComponent.instance.modal = modalTemplateComponent.instance;
    return modalTemplateComponent;
  }

}
