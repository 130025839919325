import { Component, OnInit } from '@angular/core';
import {ModalContent, ModalRef} from '../../../modal-ref';
import {UserService} from '../../../../services/user/user.service';
import {TranslationService} from '../../../../shared/translation/translation.service';

@Component({
  selector: 'app-game-not-available',
  templateUrl: './game-not-available.component.html',
  styleUrls: ['./game-not-available.component.scss']
})
export class GameNotAvailableComponent implements OnInit, ModalContent {

  /**
   * Access to parent modal
   */
  public modal: ModalRef;

  public translateWithDynamicVariable: string;

  constructor(
    public user: UserService,
    private _translate: TranslationService
  ) { }

  ngOnInit() {
    this.translateWithDynamicVariable = this._translate.translate('t.game-not-support', {currency: this.user.info.currency});
  }

}
