import { Injectable } from '@angular/core';
import {filter, first, shareReplay, switchMap} from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { StaticContentService } from './static-content.service';
import {Observable, of} from 'rxjs';
import { PlatformService } from './platform.service';
import { DeviceDetectorService } from 'ngx-device-detector';

@Injectable({
  providedIn: 'root'
})
export class EpmCountryRestrictionService {

  public text$ = null;
  public text = null;

  constructor(
    private _cookies: CookieService,
    private _static: StaticContentService,
    private _platform: PlatformService,
    private _device: DeviceDetectorService
  ) {
    this.text$ = this.createTextSource();
  }

  private createTextSource(): Observable<string> {
    return this._static.item({ slug: 'emp-popup' }).pipe(
      filter(res => !!res),
      switchMap((res) => {
        if (res.length && res[0].Content) {
          this.text = res[0].Content;
        } else {
          this.text = ' ';
        }

        return of(this.text);
      }),
      first(),
      shareReplay(1)
    );
  }
}
