import {Injectable, Injector} from '@angular/core';
import { environment } from '../../../environments/environment';
import { EnvironmentService } from './environment.service';
import { UserService } from './user/user.service';
import {HostRequestsInterceptor} from '../interceptors/host-requests.interceptor';
import {PlatformService} from './platform.service';
import {FaqSchemaService} from "./faq-schema.service";

@Injectable({
  providedIn: 'root'
})
export class CmsContentMapperService {

  private _user: UserService;

  constructor(
    private _env: EnvironmentService,
    private _host: HostRequestsInterceptor,
    private _platform: PlatformService,
    private _injector: Injector,
    private _faqSchema: FaqSchemaService,
    private _hostInterceptor: HostRequestsInterceptor
  ) { }

  /**
   * For avoiding circular dependency
   *
   * @param user
   */
  set user(user: UserService) {
    this._user = user;
  }

  /**
   * Prepare cms data for using in frontend
   *
   * @param data
   * @param extraParams {paramName: 'path.to.value'}
   */
  public mapCmsData(data: Array<any>, extraParams: any = {}): Array<any> {
    const mappedList = [];

    if (data) {
      data.forEach(dataItem => {
        const template = this._getActiveTemplate(dataItem.template);

        const prepared = {
          ...dataItem,
          template
        };

        const mapped = {
          ...this._resolveExtraParams(prepared, extraParams),
          ...this.mapCmsTemplate(template)
        };



        if (data[0] && data[0].slug && data[0].slug.includes('faq') && this._platform.isBrowser) {
          if (mapped && mapped.Content) {
            mapped.Content = this._faqSchema.addFaqSchema(mapped.Content);
          }
        }

        mappedList.push(mapped);
      });
    }

    return mappedList;
  }

  /**
   * Map template object from CMS
   *
   * @param template
   */
  public mapCmsTemplate(template: any): any {
    if (!template || !template.content) {
      return {};
    }

    const content = template.content;

    const mapped = {};

    content.forEach(field => {
      switch (field.field.type) {
        case 'GROUPLIST':
          mapped[field.field.name] = this._mapGroupList(field.value);
          break;
        case 'IMG':
          if (field.value) {
            if (field.value.slice(-1) === '/') {
              mapped[field.field.name] = '';
            } else {
              mapped[field.field.name] = this._resolveImgHost(environment.assets_host + field.value);
            }
          }
          break;
        case 'SMART_INPUT':
          mapped[field.field.name] = this._mapSmartInput(field.value);
          break;
        default:
          mapped[field.field.name] = this._overrideAllUrlsForMirrorsInText(field.value);
      }
    });

    return mapped;
  }

  /**
   * Convert group list string to Javascript Object
   *
   * @param grouplist
   * @private
   */
  private _mapGroupList(grouplist) {
    const dataArray = JSON.parse(grouplist || "[]");

    const parsed = {};

    dataArray.forEach(item => {
      const parsedItem = {};

      item.data.forEach(dataItem => {
        parsedItem[dataItem.key] = dataItem.val;
      });

      parsed[item.name] = parsedItem;
    });

    return parsed;
  }

  /**
   * Convert smart input value to single value
   *
   * @param smartInput
   * @private
   */
  private _mapSmartInput(smartInput) {
    if (!smartInput) {
      return '';
    }

    const smartInputValue = JSON.parse(smartInput);

    const defaultValue = smartInputValue.find(part => part.key === 'default');
    const ipCountryValue = smartInputValue.find(part => part.key === this._env.env.country.short.toUpperCase());
    const accountCountryValue = !!this._user.info &&
      this._user.info.country &&
      smartInputValue.find(part => part.key === this._user.info.country.toUpperCase());

    if (accountCountryValue) {
      return accountCountryValue.value;
    } else if (ipCountryValue) {
      return ipCountryValue.value;
    } else if (defaultValue) {
      return defaultValue.value;
    } else {
      return '';
    }
  }

  /**
   * Returns active template from template list
   *
   * @param templateList
   * @private
   */
  private _getActiveTemplate(templateList: Array<any>): any {
    if (templateList.length > 1) {
      return templateList.find(tpl => tpl.enabled);
    } else {
      return templateList[0];
    }
  }

  /**
   * Returns object with provided extra params of data item
   * Uses when need to add parameters in mapped data item that on top level, not in template
   *
   * @param dataItem
   * @param params
   * @private
   */
  private _resolveExtraParams(dataItem: any, params: any): any {
    const extraParams = {};

    Object.keys(params).forEach(param => {
      extraParams[param] = this._getDeepValue(dataItem, params[param]);
    });

    return extraParams;
  }

  /**
   * Returns value from object by string path
   *
   * @param object
   * @param path
   * @private
   */
  private _getDeepValue(object: any, path: string): any {
    const paths = path.split('.');

    let value = object;

    paths.forEach(pathStep => {
      if (value[pathStep] === undefined) {
        value = undefined;
        return undefined;
      } else {
        value = value[pathStep];
      }
    });

    return value;
  }


  /**
   * Override all url in content template
   *
   * @param content
   * @private
   */

  private _overrideAllUrlsForMirrorsInText(value) {
    const host = this._hostInterceptor.getDomainName();
    const regExp = new RegExp('wildfortune', 'g');
    const hostname = host.split('.')[1];

    return value && !host.includes('localhost') ? value.replace(regExp, hostname) : value;
  }

  /**
   * Override img host for mirrors
   * @param url
   * @private
   */
  private _resolveImgHost(url) {
    return this._hostInterceptor.overrideCmsImgUrl(url);
  }
}
