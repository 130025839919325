import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { catchError, retry, shareReplay, tap } from 'rxjs/operators';
import { EMPTY, of } from 'rxjs';
import { StaticPageService } from 'src/app/page/static-page/static-page.service';
import {ModalContent, ModalRef} from '../../../modal-ref';
import {UserService} from '../../../../services/user/user.service';
import {UserInfoService} from '../../../../services/user/user-info.service';
import {FormsErrorHandlerService} from '../../../../services/forms-error-handler.service';
import {ToastMessageService} from '../../../../modules/toast-message/toast-message.service';
import {EnvironmentService} from '../../../../services/environment.service';
import {SsApiService} from '../../../../services/api/ss-api.service';
import {ModalService} from '../../../modal.service';
import {CommonDataService} from '../../../../services/common-data.service';
import {CustomValidators} from '../../../../helpers/custom-validators';
import {ValidationPatterns} from '../../../../helpers/validation-patterns';

@Component({
  selector: 'app-auth-fields-missed-form',
  templateUrl: './auth-fields-missed-form.component.html',
  styleUrls: ['./auth-fields-missed-form.component.scss']
})
export class AuthFieldsMissedFormComponent implements OnInit, ModalContent {

  /**
   * Missed fields form
   */
  public form: UntypedFormGroup = this._fb.group({});

  /**
   * Is form loading
   */
  public loading: boolean;

  /**
   * Form errors
   */
  public errors: any = {};

  /**
   * Services that comes from modal data (to avoid circular dependency)
   */
  private _user: UserService;
  private _userInfo: UserInfoService;

  public modal: ModalRef;

  /**
   * True if deposit complete. For using iт template
   */
  public isDepositComplete: boolean = false;


  /**
   * Terms and condition slug
   */
  public readonly terms: string = 'terms-and-conditions';

  /**
   * Privacy and policy slug
   */
  public readonly privacy: string = 'privacy-policy';

  constructor(
    private _fb: UntypedFormBuilder,
    private _formErrors: FormsErrorHandlerService,
    private _toastMessage: ToastMessageService,
    private _env: EnvironmentService,
    private _api: SsApiService,
    private _page: StaticPageService,
    private _modal: ModalService,
    public commonData: CommonDataService,
  ) {}

  ngOnInit() {
    this._user = this.modal.options.data.user;
    this._userInfo = this.modal.options.data.userInfo;

    this._initForm();
    this._setCurrency();
    this._setDepositComplete();
  }

  /**
   * Returns FormControl by name
   *
   * @param name
   */
  field(name: string): AbstractControl {
    return this.form.get(name);
  }

  /**
   * Submit form handler
   */
  submit() {
    this.errors = {
      ...this._formErrors.applyFormErrors(this.form),
      ...this._formErrors.applyFormErrors(this.form.get('profile_attributes') as UntypedFormGroup)
    };

    if (this.form.invalid) {
      return;
    }

    this.loading = true;
    this._userInfo.setMissedAuthFields(this.form.value).pipe(
      tap(() => {
        this._user.fetchAllUserData();
        this.modal.close();
      }),
      catchError(error => {
        this.errors = this._formErrors.applyFormErrors(this.form, error.error);
        return of(error);
      })
    ).subscribe(() => {
      this.loading = false;
    });
  }

  /**
   * Init required form fields
   *
   * @private
   */
  private _initForm() {
    /**
     * List of field names that mast be in form
     */
    const requiredFields: Array<string> = this._user.info.auth_fields_missed;
    /**
     * profile_attributes group
     */
    const attributesGroup = new UntypedFormGroup({});
    /**
     * List of field names that mast be in profile_attributes group
     */
    const attributeFields = ['terms_acceptance', 'currency'];

    /**
     * Form initialization
     */
    this.form = new UntypedFormGroup({});

    /**
     * Parse field names list and create form control for each field
     */
    requiredFields.forEach(fieldName => {
      const control = new UntypedFormControl(null, [Validators.required]);

      /**
       * Set specific validators for fields that requires special validation
       */
      switch (fieldName) {
        case 'terms_acceptance':
          control.setValidators(CustomValidators.changeKey('required_true', Validators.requiredTrue));
          control.setValue(true);
          break;
        case 'email':
          control.setValidators([Validators.required, Validators.pattern(ValidationPatterns.email)]);
          break;
      }

      /**
       * If current field must be in profile_attributes -> add field in group
       * Else add it directly in form
       */
      if (attributeFields.includes(fieldName)) {
        attributesGroup.addControl(fieldName, control);
      } else {
        this.form.addControl(fieldName, control);
      }

      /**
       * If attributes group not empty -> add it to form
       */
      if (Object.keys(attributesGroup.controls).length) {
        this.form.addControl('profile_attributes', attributesGroup);
      }
    });
  }

  /**
   * Detect user currency and set value to control
   *
   * @private
   */
  private _setCurrency() {
    if (this.commonData.currencyList.map(currency => currency.code).includes(this._env.env.currency && this._env.env.currency.short)) {
      if (this.field('profile_attributes.currency')) {
        this.field('profile_attributes.currency').setValue( this._env.env.currency.short);
      }
    }
  }

  /**
   * Set deposit complete variable
   *
   */
  private _setDepositComplete() {
    const missedFields: Array<string> = this._user.info.auth_fields_missed;
    if(missedFields.length > 1 && missedFields.includes('terms_acceptance') && missedFields.includes('email')) {
      this.isDepositComplete = true;
    }
  }

  /**
   * Open terms and conditions or privacy policy modal
   */
  openInfoModal(slug: string) {
    this._page.item({ slug }).pipe(
      retry(2),
      tap( async (page) => {
        if(!page) new Error();
        else {
          const component = await import('../../lazy/sys-info/sys-info.component');
          await this._modal.openLazy(component.SysInfoComponent, {
            template: 'CLEAR',
            data: {
              Title: Array.isArray(page) && page.length > 0 ? page[0].Title : page.Title,
              Content: Array.isArray(page) && page.length > 0 ? page[0].Content : page.Content,
            },
          })
        }
      }),
      catchError(() => EMPTY),
      shareReplay()
    ).subscribe();
  }
}
