import { Injectable } from '@angular/core';
import {BasePromo} from '../base-promo';
import {ModalService} from '../../../core/modal-v2/modal.service';

@Injectable({
  providedIn: 'root'
})
export class HalloweenService extends BasePromo {
  eventName = 'halloween';

  /**
   * For prevent click spin button any times
   */
  setSpinWheel;

  constructor(
    private _modal: ModalService
  ) {
    super();
  }

  async openHuntModal() {
    const component = await import('../../../core/modal-v2/components/lazy/halloween-hunt-modal/halloween-hunt-modal.component');
    this.openedModal = await this._modal.openLazy(component?.HalloweenHuntModalComponent, {
      template: 'CLEAR',
      data: {
        size: this.eventList.length,
        data: this.huntModalData
      }
    });

    this.openedModal.onResult().pipe().subscribe(() => this.openedModal = undefined);
  }
}
