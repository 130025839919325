import { ACCEPT_HEADER } from './ss-payments-config';


export class SsPaymentsRequester {

  private readonly HOST;

  constructor(host: string) {
    this.HOST = host;
  }

  /**
   * Make GET request to the server with necessary headers and parse response.
   * @callback getRequest
   * @async
   * @param {string} uri - request url
   * @return {Promise<any>} - JSON parsed response from the server
   */
  apiGet(uri) {
    return fetch(this.HOST + uri, {
      method: 'GET',
      headers: new Headers({
        'Accept': ACCEPT_HEADER,
      }),
      credentials: 'include',
      cache: 'no-cache',
    }).then((res) => res.json());
  }

  /**
   * Make POST request to the server with necessary headers and parse response if it was successful.
   * @callback postRequest
   * @async
   * @param {string} uri - request uri
   * @param {object} data - request body, will be converted to JSON
   * @return {Promise<any>} - JSON parsed response from the server or handled error.
   */
  apiPost(uri, data) {
    return fetch(this.HOST + uri, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
        'Accept': ACCEPT_HEADER,
      }),
      credentials: 'include',
      body: JSON.stringify(data),
      cache: 'no-cache',
    }).then((res) => {
      if (res.ok) {
        return res.json();
      } else {
        return res.json().then((err) => Promise.reject(err));
      }
    }, (error) => {
      return Promise.reject({
        errors: {payment_method: {not_available_now: error}},
      });
    });
  }

  /**
   * Make PUT request to the server with necessary headers and parse response.
   * @callback getRequest
   * @async
   * @param {string} uri - request url
   * @return {Promise<any>} - JSON parsed response from the server
   */
  apiPut(uri, data) {
    return fetch(this.HOST + uri, {
      method: 'PUT',
      headers: new Headers({
        'Content-Type': 'application/json',
        'Accept': ACCEPT_HEADER,
      }),
      body: JSON.stringify(data),
      credentials: 'include',
      cache: 'no-cache',
    }).then(
      (res: any) => {
        if (res.ok) {
          return res;
        } else {
          // @ts-ignore
          return res.json().then((err) => Promise.reject(err));
        }
      },
      (error) => {
        return Promise.reject({errors: error});
      });
  }

  /**
   * Redirect user to specific location.
   * @callback redirectFunction
   * @param {string} uri - request uri
   */
  redirect(uri) {
    if (window) {
      window.location = uri;
    }
  }
}
