import { Injectable } from '@angular/core';
import { SsApiService } from '../api/ss-api.service';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';
import { CommonDataService } from '../common-data.service';
import { GamesService } from '../games/games.service';
import { FiltersService } from '../filters.service';

/**
 * Available transaction statuses (for frontend only)
 */
export enum TransactionStatus {
  PENDING = 'pending',
  ACCEPTED = 'accepted',
  DISCARDED = 'discarded'
}

@Injectable({
  providedIn: 'root'
})
export class UserTransactionsService {

  constructor(
    private _api: SsApiService,
    private _data: CommonDataService,
    private _games: GamesService,
    private _filters: FiltersService
  ) { }

  /**
   * Returns users transactions list
   */
  transactionList(): Observable<any> {
    return this._api.playerPayments().pipe(
      map(list => list.map(transaction => ({
        ...transaction,
        amount: this._data.subunitsToUnits(transaction.amount_cents, transaction.currency),
        currency_symbol: this._data.currencySymbol(transaction.currency),
        created_at: new Date(transaction.created_at),
        status: transaction.finished_at ?
          transaction.success ?
            TransactionStatus.ACCEPTED :
            TransactionStatus.DISCARDED :
          TransactionStatus.PENDING
      }))),
      catchError(error => of([])
    ));
  }

  /**
   * Returns users bets list
   */
  betList(): Observable<any> {
    const games = new Set();
    const bets = [];

    return this._api.playerGames().pipe(
      tap(list => {
        list.forEach(bet => {
          games.add(bet.game);
          bets.push({
            ...bet,
            amount: this._data.subunitsToUnits(bet.total_bets, bet.currency),
            win: this._data.subunitsToUnits(bet.total_wins, bet.currency),
            currency_symbol: this._data.currencySymbol(bet.currency),
            date: new Date(bet['created_at'])
          });
        });
      }),
      switchMap(() => this._games.list({
        'external_id[]': [...games]
      })),
      filter(response => !!response),
      map(gameResponse => {
        const gamesByExternalId = this._filters.valueAsKeyObject('externalId', gameResponse.gameList || []);

        return bets.map(bet => ({
          ...bet,
          game: gamesByExternalId[bet.game]
        }));
      })
    );
  }

  /**
   * Cancel pending cash out
   *
   * @param id
   */
  cashoutRecall(id: string): Observable<any> {
    return this._api.playerPaymentsRecall(id);
  }
}
