<div class="selector" (click)="open()">
  <img class="selector__img" *ngIf="control.value" [src]="'/assets/svg/flags/' + control.value + '.svg'" alt="">
  <span *ngIf="countryName" class="selector__label">{{ control.value }}</span>
  <i *ngIf="showArrow" class="arrow down"></i>
</div>

<form class="countries" [ngClass]="{open: isOpen}" ClickOutside (clickOutside)="close()" [formGroup]="searchForm">
  <input formControlName="query" class="search" type="text" [placeholder]="'t.search' | translate">
  <div class="list">
    <div class="list__item" (click)="select(country)" *ngFor="let country of filteredCountryList">
      <img class="selector__img" [src]="'/assets/svg/flags/' + country.short + '.svg'" alt="">
      <span class="selector__label">{{ country.name }}</span>
    </div>
  </div>
</form>
