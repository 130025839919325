<div class="form">
  <div class="tabs">
    <div class="tabs__header">
      <div class="tabs__header-tab" [ngClass]="{active: currentTab === Tab.TERMS}" (click)="currentTab = Tab.TERMS">{{'t.terms-and-cond' | translate}}</div>
      <div class="tabs__header-tab" [ngClass]="{active: currentTab === Tab.POLICY}" (click)="currentTab = Tab.POLICY">{{'t.privacy-policy' | translate}}</div>
    </div>

    <div [hidden]="currentTab !== Tab.TERMS">
      <div class="tabs__content" *ngIf="terms$ | async as terms; else termsLoading">
        <div class="text" [innerHTML]="terms[0]?.Content | safe: 'html'"></div>
      </div>

      <ng-template #termsLoading>
        <app-preloader></app-preloader>
      </ng-template>
    </div>

    <div [hidden]="currentTab !== Tab.POLICY">
      <div class="tabs__content" *ngIf="policy$ | async as policy; else policyLoading">
        <div class="text" [innerHTML]="policy[0]?.Content | safe: 'html'"></div>
      </div>

      <ng-template #policyLoading>
        <app-preloader></app-preloader>
      </ng-template>
    </div>
  </div>

  <div class="submit-container">
    <ng-container *ngIf="!loading">
      <button class="btn btn--simple-primary" (click)="decline()">{{'t.decline' | translate}}</button>
      <button class="btn btn--filled-primary" (click)="accept()">{{'t.accept' | translate}}</button>
    </ng-container>
    <app-preloader *ngIf="loading"></app-preloader>
  </div>
</div>
