import { Component, ElementRef } from '@angular/core';

@Component({
  selector: 'app-select-header',
  templateUrl: './select-header.component.html',
  styleUrls: ['./select-header.component.scss']
})
export class SelectHeaderComponent {

  public placeholder: boolean;

  public value: string;

  constructor(
    public el: ElementRef
  ) { }

}
