/**
 * Available categories
 */
export enum GameCategory {
  SLOTS = 48,
  OKTOBERFEST = 121,
  BLACKJACK = 50,
  ROULETTE = 49,
  VIDEO_POKER = 63,
  TABLE_GAMES = 64,
  LIVE_CASINO = 61,
  BACCARAT = 76,
  OTHER_GAMES = 77,
  FAVORITE = 'favorite',
  LAST_PLAYED = 'last-played',
  FAVORITE_ID = 67,
  LAST_PLAYED_ID = 65,
  LIVE_CASINO_SLUG = 'live',
  LIVE_CASINO_SLUG_TWO = 'live-casino',
  CHRISTMAS = 64,
  HALLOWEEN_ID = 103,
  HALLOWEEN_SLUG = 'halloween-games',
  TROPICAL = 96,
  SPINFINITY_GAMES = 101,
  WINTER_FAVORITES = 84,
  VALENTINE_DAY = 86,
  EASTER = 78,
  SPINFINITY_TROPICAL = 107,
  GRIZZLY_JACKPOT = 117,
  GRIZZLY_JACKPOT_SLUG = 'grizzly-pine'
}
