import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {RegisterFormComponent} from './register-form.component';
import {ComponentsModule} from '../../shared/components/components.module';
import {RouterModule} from '@angular/router';
import {SelectModule} from '../select/select.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FormControlsModule} from '../form-controls/form-controls.module';
import {DirectivesModule} from '../../shared/directives/directives.module';
import {TranslationModule} from '../../shared/translation/translation.module';

@NgModule({
  declarations: [
    RegisterFormComponent
  ],
  imports: [
    CommonModule,
    TranslationModule,
    RouterModule,
    SelectModule,
    FormsModule,
    ReactiveFormsModule,
    FormControlsModule,
    DirectivesModule,
    ComponentsModule
  ],
  exports: [
    RegisterFormComponent
  ]
})
export class RegisterFormModule { }
