import {Injectable} from '@angular/core';
import {LimitType} from './user/data/user-limits.data';
import {CookieService} from 'ngx-cookie-service';

declare var dataLayer: any[];

enum ACTIONS {
  LOGIN_ACTION = 'login',
  REGISTER_ACTION = 'register',
  CLICK_ACTION = 'button_click',
}

enum CATEGORIES {
  FORM_SUBMIT = 'form_submit',
  DEPOSIT_START = 'deposit_start',
  LIMIT = 'limit',
  ABTEST = 'ab-test'
}

enum TRANSACTIONS {
  DEPOSIT = 'deposit',
}

enum GTAG_COOKIES {
  FIRST_DEPOSIT_SENT = 'gtm-first-deposit-sent',
  USER_IDS = 'gtm-user-ids'
}

const TIMESTAMP = +new Date();

@Injectable({
  providedIn: 'root'
})
export class GoogleTagManagerService {
  /**
   * List of already focused fields
   */
  private _firstFocusEventList = new Map<any, any>();

  constructor(
    private _cookie: CookieService
  ) {
    if (typeof dataLayer !== 'undefined' && dataLayer) {
      dataLayer = (window as any).dataLayer = (window as any).dataLayer || [];
    }
  }

  private _addDataLayer(data: object) {
    dataLayer.push(data);
  }

  /**
   * SignIn header click
   * @eventName event name
   * @label place where user click
   */
  public signInHeaderClick(eventName: string, label: string) {
    this._addDataLayer({
      event: eventName,
      event_action: ACTIONS.LOGIN_ACTION,
      event_category: CATEGORIES.FORM_SUBMIT,
      event_label: label
    });
  }

  /**
   * SignIn sidebar Click
   * @param {string} eventName
   * @param {string} label
   */
  public signInSidebarClick(eventName: string, label: string) {
    this._addDataLayer({
      event: eventName,
      event_action: ACTIONS.LOGIN_ACTION,
      event_category: CATEGORIES.FORM_SUBMIT,
      event_label: label
    });
  }

  /**
   * Sidebar account click
   * @param {string} eventName
   * @param {string} label
   */
  public sidebarAccountClick(eventName: string, label: string) {
    this._addDataLayer({
      event: eventName,
      event_action: ACTIONS.LOGIN_ACTION,
      event_category: CATEGORIES.FORM_SUBMIT,
      event_label: label
    });
  }

  /**
   * Sidebar bonuses click
   * @param {string} eventName
   * @param {string} label
   */
  public sidebarBonusesClick(eventName: string, label: string) {
    this._addDataLayer({
      event: eventName,
      event_action: ACTIONS.LOGIN_ACTION,
      event_category: CATEGORIES.FORM_SUBMIT,
      event_label: label
    });
  }

  /**
   * Slider bonuses click
   * @param {string} eventName
   * @param {string} label
   */
  public sliderBonusesClick(eventName: string, label: string) {
    this._addDataLayer({
      event: eventName,
      event_action: ACTIONS.LOGIN_ACTION,
      event_category: CATEGORIES.FORM_SUBMIT,
      event_label: label
    });
  }

  /**
   * Login button on game page click
   * @param {string} eventName
   * @param {string} label
   */
  public loginButtonOnGamePageClick(eventName: string, label: string) {
    this._addDataLayer({
      event: eventName,
      event_action: ACTIONS.LOGIN_ACTION,
      event_category: CATEGORIES.FORM_SUBMIT,
      event_label: label
    });
  }

  /**
   * Game try real click
   * @param {string} eventName
   * @param {string} label
   */
  public loginGameTryRealClick(eventName: string, label: string) {
    this._addDataLayer({
      event: eventName,
      event_action: ACTIONS.LOGIN_ACTION,
      event_category: CATEGORIES.FORM_SUBMIT,
      event_label: label
    });
  }

  /**
   * Favorite game click
   * @param {string} eventName
   * @param {string} label
   */
  public favoriteGameClick(eventName: string, label: string) {
    this._addDataLayer({
      event: eventName,
      event_action: ACTIONS.LOGIN_ACTION,
      event_category: CATEGORIES.FORM_SUBMIT,
      event_label: label
    });
  }

  /**
   * Login on Registration form click
   * @param {string} eventName
   * @param {string} label
   */
  public loginOnRegistrationClick(eventName: string, label: string) {
    this._addDataLayer({
      event: eventName,
      event_action: ACTIONS.LOGIN_ACTION,
      event_category: CATEGORIES.FORM_SUBMIT,
      event_label: label
    });
  }

  /**
   * Login on Registration form click
   * @param {string} eventName
   * @param {string} label
   */
  public loginOnHoverGameClick(eventName: string, label: string) {
    this._addDataLayer({
      event: eventName,
      event_action: ACTIONS.LOGIN_ACTION,
      event_category: CATEGORIES.FORM_SUBMIT,
      event_label: label
    });
  }

  /**
   * Login click on banner mobile
   * @param {string} eventName
   * @param {string} label
   */
  public loginClickOnBannerMobile(eventName: string, label: string) {
    this._addDataLayer({
      event: eventName,
      event_action: ACTIONS.LOGIN_ACTION,
      event_category: CATEGORIES.FORM_SUBMIT,
      event_label: label
    });
  }

  /**
   * signUp Success
   * @userId
   */
  public signUpSuccess(userId) {
    this._addDataLayer({
      event: 'create_account_button',
      event_action:  ACTIONS.CLICK_ACTION,
      event_category: CATEGORIES.FORM_SUBMIT,
      event_label: 'create_account',
      dimension1: userId
    });
  }

  /**
   * Register header click
   * @param {string} eventName
   * @param {string} label
   */
  public registerHeaderClick(eventName: string, label: string) {
    this._addDataLayer({
      event: eventName,
      event_action: ACTIONS.REGISTER_ACTION,
      event_category: CATEGORIES.FORM_SUBMIT,
      event_label: label,
    });
  }

  /**
   * Register sidebar click
   * @param {string} eventName
   * @param {string} label
   */
  public registerSidebarClick(eventName: string, label: string) {
    this._addDataLayer({
      event: eventName,
      event_action: ACTIONS.REGISTER_ACTION,
      event_category: CATEGORIES.FORM_SUBMIT,
      event_label: label,
    });
  }

  /**
   * Register sidebar click
   * @param {string} eventName
   * @param {string} label
   */
  public registerMainBannerClick(eventName: string, label: string) {
    this._addDataLayer({
      event: eventName,
      event_action: ACTIONS.REGISTER_ACTION,
      event_category: CATEGORIES.FORM_SUBMIT,
      event_label: label,
    });
  }

  /**
   * Register sidebar click
   * @param {string} eventName
   * @param {string} label
   */
  public registerLoginFormrClick(eventName: string, label: string) {
    this._addDataLayer({
      event: eventName,
      event_action: ACTIONS.REGISTER_ACTION,
      event_category: CATEGORIES.FORM_SUBMIT,
      event_label: label,
    });
  }

  /**
   * Register form inputs focus
   * @param {string} eventName
   * @param {string} label
   */
  public registerFormInputFocus(eventName: string, label: string) {
    const eventVal = {
      event: eventName,
      eventAction: 'focus',
      eventCategory: 'register',
      eventLabel: label
    };
    const eventKey = Object.values(eventVal).join();
    if (!this._firstFocusEventList.has(eventKey)) {
      this._firstFocusEventList.set(eventKey, true);
      this._addDataLayer(eventVal);
    }
  }

  /**
   * On loyalty page click
   * @param {string} eventName
   * @param {string} label
   */
  public loyaltyPageClick(eventName: string, label: string) {
    this._addDataLayer({
      event: eventName,
      event_action: ACTIONS.REGISTER_ACTION,
      event_category: CATEGORIES.FORM_SUBMIT,
      event_label: label,
    });
  }

  /**
   * On sticky bottom login click
   * @param {string} eventName
   * @param {string} label
   */
  public stickyBottomButtonClick(eventName: string, label: string) {
    this._addDataLayer({
      event: eventName,
      event_action: ACTIONS.REGISTER_ACTION,
      event_category: CATEGORIES.FORM_SUBMIT,
      event_label: label,
    });
  }

  /**
   * Sign in success
   * @userId
   */
  public signInSuccess(userId) {
    this._addDataLayer({
      event: 'login_account_button',
      event_action: ACTIONS.CLICK_ACTION,
      event_category: CATEGORIES.FORM_SUBMIT,
      event_label: 'login_account',
      dimension1: userId
    });
  }

  /**
   * Click on deposit header button
   * @param {string} eventName
   * @param {string} label
   * @param userId
   */
  public depositHeaderButton(eventName: string, label: string, userId) {
    this._addDataLayer({
      event: eventName,
      event_action: TRANSACTIONS.DEPOSIT,
      event_category: CATEGORIES.FORM_SUBMIT,
      event_label: label,
      dimension1: userId
    });
  }

  /**
   * Click on deposit header button
   * @param {string} eventName
   * @param {string} label
   * @param userId
   */
  public depositSidenavClick(eventName: string, label: string, userId) {
    this._addDataLayer({
      event: eventName,
      event_action: TRANSACTIONS.DEPOSIT,
      event_category: CATEGORIES.FORM_SUBMIT,
      event_label: label,
      dimension1: userId
    });
  }

  /**
   * Click on deposit button on game page
   * @param {string} eventName
   * @param {string} label
   * @param userId
   */
  public depositGamePageClick(eventName: string, label: string, userId) {
    this._addDataLayer({
      event: eventName,
      event_action: TRANSACTIONS.DEPOSIT,
      event_category: CATEGORIES.FORM_SUBMIT,
      event_label: label,
      dimension1: userId
    });
  }

  /**
   * Click on deposit button on banking page
   * @param {string} eventName
   * @param {string} label
   * @param userId
   */
  public depositBankingPageClick(eventName: string, label: string, userId) {
    this._addDataLayer({
      event: eventName,
      event_action: TRANSACTIONS.DEPOSIT,
      event_category: CATEGORIES.FORM_SUBMIT,
      event_label: label,
      dimension1: userId
    });
  }

  /**
   * Click on deposit vendor
   * @param {string} eventName
   * @param {string} label
   * @param {string} method
   * @param userId
   */
  public depositVendorClick(eventName: string, label: string, method: string, userId) {
    this._addDataLayer({
      event: eventName,
      event_action: method,
      event_category: CATEGORIES.DEPOSIT_START,
      event_label: method,
      dimension1: userId
    });
  }

  /**
   * Click on get bonus tournament
   * @param {string} eventName
   * @param {string} label
   */
  public getBonusTournamentClick(eventName: string, label: string) {
    this._addDataLayer({
      event: eventName,
      event_action: ACTIONS.CLICK_ACTION,
      event_category: CATEGORIES.FORM_SUBMIT,
      event_label: label,
    });
  }

  /**
   * Page 403
   * @param {string} eventName
   * @param referrer
   * @param country
   */
  public page403(eventName: string, referrer, country) {
    this._addDataLayer({
      event: eventName,
      event_action: country,
      event_category: 'error_403',
      event_label: referrer,
    });
  }

  /**
   * Page 403
   * @param {string} eventName
   * @param pageLocation
   */
  public page404(eventName: string, pageLocation) {
    this._addDataLayer({
      event: eventName,
      event_action: 'not_found',
      event_category: 'error_404',
      event_label: pageLocation,
    });
  }

  /**
   * Not available pop-up game click
   * @param pageUrl
   */
  public gameNotAvailableClick(pageUrl) {
    this._addDataLayer({
      event: 'game_not_found',
      event_action: 'not_found',
      event_category: 'error',
      event_label: pageUrl,
    });
  }

  /**
   * Register error event
   * @param {string} eventName
   * @param error
   */
  public registerError(eventName: string, error) {
    this._addDataLayer({
      event: eventName,
      event_action: 'register_error',
      event_category: CATEGORIES.FORM_SUBMIT,
      event_label: error,
    });
  }

  /**
   * Provider click
   * @param {string} eventName
   * @param {string} providerName
   */
  public providerClick(eventName: string, providerName: string) {
    this._addDataLayer({
      event: eventName,
      event_action: 'select',
      event_category: 'filter_provider',
      event_label: providerName,
    });
  }

  /**
   * Search game event
   * @param {string} eventName
   * @param {string} value
   */
  public searchGame(eventName: string, value: string) {
    this._addDataLayer({
      event: eventName,
      event_action: 'enter',
      event_category: 'search_game',
      event_label: value,
    });
  }

  /**
   * Responsible game limits event
   * @param type
   * @param period
   * @param userId
   */
  public limitsEvent(type, period, userId) {
    switch (type) {
      case LimitType.DEPOSIT:
        this._addDataLayer({
          event: 'deposit_limits',
          event_action: 'deposit',
          event_category: CATEGORIES.LIMIT,
          event_label: period,
          dimension1: userId
        });
        break;
      case LimitType.WAGER:
        this._addDataLayer({
          event: 'wager_limits',
          event_action: 'wager',
          event_category: CATEGORIES.LIMIT,
          event_label: period,
          dimension1: userId
        });
        break;
      case LimitType.LOSS:
        this._addDataLayer({
          event: 'loss_limits',
          event_action: 'loss',
          event_category: CATEGORIES.LIMIT,
          event_label: period,
          dimension1: userId
        });
        break;
      case LimitType.SESSION:
        this._addDataLayer({
          event: 'session_limit',
          event_action: 'session',
          event_category: CATEGORIES.LIMIT,
          event_label: period,
          dimension1: userId
        });
        break;
      case LimitType.COOLINGOFF:
        this._addDataLayer({
          event: 'cooling_off',
          event_action: 'cooling_off',
          event_category: CATEGORIES.LIMIT,
          event_label: period,
          dimension1: userId
        });
        break;
      case LimitType.SELFEXCLUSION:
        this._addDataLayer({
          event: 'self_exclusion',
          event_action: 'self_exclusion',
          event_category: CATEGORIES.LIMIT,
          event_label: period,
          dimension1: userId
        });
        break;
    }
  }

  /**
   * Update profile event
   * @param eventName
   * @param label
   */
  public updateProfileModalSubmitClick(eventName: string, label: string) {
    this._addDataLayer({
      event: eventName,
      event_action: ACTIONS.REGISTER_ACTION,
      event_category: CATEGORIES.FORM_SUBMIT,
      event_label: label,
    });
  }

  /**
   * Collect all user ids(duplicate accounts) on login/register and returns user ids array
   * @param currentId
   */
  private _collectUserIds(currentId): number[] {
    if (!this._cookie.check(GTAG_COOKIES.USER_IDS)) {
      this._cookie.set(GTAG_COOKIES.USER_IDS, currentId, 999, '/', (window as any).location.hostname);
      return [currentId];
    } else {
      const idsArray = this._cookie.get(GTAG_COOKIES.USER_IDS).split(',').map(val => +val);
      if (!idsArray.includes(currentId)) {
        idsArray.push(currentId);
        this._cookie.set(GTAG_COOKIES.USER_IDS, idsArray.join(','), 999, '/', (window as any).location.hostname);
      }
      return idsArray;
    }
  }

  /**
   * Login button on game page click
   * @param {string} eventName
   * @param {string} label
   */
  public setAbTest(eventName: string, action: string, label: string) {
    if (label) {
      this._addDataLayer({
        event: eventName,
        event_action: action,
        event_category: CATEGORIES.ABTEST,
        event_label: label
      });
    }
  }

  /**
   * signUp Success Timestamp
   * @userId
   */
  public signUpSuccessTimestamp(eventName: string, label: string, userId: number) {
    this._addDataLayer({
      event: eventName,
      event_action: TIMESTAMP,
      event_category: 'timestamp',
      event_label: label,
      dimension1: userId
    });
  }


  /**
   * Game click event
   * @param gameid
   */
  public gameClickEvent(gameid: string) {
    this._addDataLayer({
      event: 'game_click',
      gameid,
    });
  }
}
