import {Directive, ElementRef, Input} from '@angular/core';
import {PlatformService} from '../../services/platform.service';
import {AbstractControl, NgControl} from '@angular/forms';
import {BodyService} from '../../services/body.service';

@Directive({
  selector: '[setValueAutofill]'
})
export class SetValueAutofillDirective {

  constructor(
    private _el: ElementRef,
    private _control: NgControl,
    private _platform: PlatformService,
    private _body: BodyService,
  ) {
    this._setValueAfterAutofill();
  }

  /**
   * Set value after autofill
   *
   * @private
   */
  private _setValueAfterAutofill() {
    if(this._platform.isBrowser && this._el.nativeElement) {
      this._el.nativeElement.addEventListener('change', () => {
        setTimeout(() => {
          if(this._control && !this._control.control.value) {
            this._control.control.setValue(this._el.nativeElement.value);
          }
        }, 250);
      });
    }
  }
}
