import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { EnvironmentService } from '../../services/environment.service';
import { LanguageService } from '../../services/language/language.service';
import { map } from 'rxjs/operators';
import { PlatformService } from '../../services/platform.service';
import { ArrayService } from '../../services/array.service';

@Injectable({
  providedIn: 'root'
})
export class LangGuard implements CanActivate {

  constructor(
    private _env: EnvironmentService,
    private _language: LanguageService,
    private _router: Router,
    private _platform: PlatformService,
    private _array: ArrayService,
  ) {
  }

  /**
   * Localize url
   *
   * @param next
   * @param state
   */
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this._env.env$.pipe(
      map((env) => {


        if (!this._platform.isBrowser && state.url === '/') {
          state.url = '/en';
        }

        const params = this._array.routeToArray(state.url);
        const routeLang = params[0];
        const queryParams = next.queryParams;

        /**
         * If language not defined
         */
        if (!this._language.current || routeLang !== this._language.current) {
          this._language.changeLang(this._language.detectUserLang(routeLang.toLowerCase(), this._env.env.locale.short, this._env.env.languageList));
        }

        /**
         * Perform redirect to locale
         */
        if (routeLang !== this._language.current) {

          let redirectUrl = '/';

          if (routeLang.toLocaleLowerCase() === this._language.current) {
            params[0] = params[0].toLocaleLowerCase();
            redirectUrl += params.join('/');
          } else {
            redirectUrl += [!this._platform.isBrowser ? 'en' : this._language.current, ...params].join('/');
          }

          if (redirectUrl[redirectUrl.length - 1] === '/') {
            redirectUrl = redirectUrl.substring(0, redirectUrl.length - 1);
          }

          const tree = this._router.parseUrl(redirectUrl);
          tree.queryParams = queryParams;

          this._router.navigateByUrl(tree).then();

          return false;
        }

        return true;
      })
    );
  }
}
