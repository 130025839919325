import { Injectable } from '@angular/core';
import { BasePromo } from '../base-promo';
import { combineLatest, Observable, of } from 'rxjs';
import { concatMap, filter, map, tap } from 'rxjs/operators';
import { XmasCalendarItem, XmasPromo, XmasQuestGroupItem } from './xmas-types';
import { BonusStage } from '../../../core/services/user/data/user-bonuses.data';
import { GameCategory } from '../../../core/services/games/game-category';
import { Tournament } from '../../tournaments/tournaments.interface';
import { GameListConfig } from '../../../core/modules/games/game-filtered-list/game-filtered-list.component';

export const BONUS_API_IDS = {
  freespins: 'freespins_xmas',
  lootboxes: 'lootbox_xmas',
};

@Injectable({
  providedIn: 'root',
})
export class XmasService extends BasePromo {

  public eventName = 'xmas';

  public daysImgList: string[] = [
    '/assets/img/promo/xmas/days/0.svg',
    '/assets/img/promo/xmas/days/1.svg',
    '/assets/img/promo/xmas/days/2.svg',
    '/assets/img/promo/xmas/days/3.svg',
    '/assets/img/promo/xmas/days/4.svg',
    '/assets/img/promo/xmas/days/5.svg',
    '/assets/img/promo/xmas/days/6.svg',
  ];

  /**
   * Slider config
   */
  public calendarSliderConfig = {
    spacing: 20,
    breakpoints: {
      '(max-width: 720px)': {
        spacing: 50,
        slidesPerView: 1
      },
    },
    centered: true,
    autoplay: false,
    initial: 0,
    loop: true
  }

  /**
   * Current promo games
   */
  public xmasGamesConfig: GameListConfig = {
    filter: {
      category: GameCategory.SLOTS,
      limit: this.device.isMobile() ? 5 : 11,
    },
    title: 'link.slots',
    link: '/games/slots',
  };

  /**
   * Current tournaments
   * @param _time
   */
  public tournaments$: Observable<Tournament[]> = this.getTournaments$();

  /**
   * Games list by category
   */
  public gameList$: Observable<any> = this.getGameListByCategory();

  /**
   * Current promo from CMS
   */
  public promo$: Observable<XmasPromo> = this.getPromo$();

  /**
   * Is loaded promo
   */
  public isLoadedPromo: boolean;

  public questGroups: XmasQuestGroupItem[];

  /**
   * If user has fs prize
   * @private
   */
  private _isUserHasFsPrize: boolean;

  /**
   * If user has lootbox prize
   * @private
   */
  private _isUserHasLootboxPrize: boolean;

  /**
   * Detect end of promo left
   * @private
   */
  private _endOfPromo;

  public fsAmount: number = 15;

  constructor() {
    super();
  }

  get isUserHasFsPrize(): boolean {
    return this._isUserHasFsPrize;
  }

  get isUserHasLootboxPrize(): boolean {
    return this._isUserHasLootboxPrize;
  }

  set isUserHasFsPrize(value: boolean) {
    this._isUserHasFsPrize = value;
  }

  set isUserHasLootboxPrize(value: boolean) {
    this._isUserHasLootboxPrize = value;
  }

  get isSecondPromoWeek(): boolean {
    return this.time.toLocalDate().setHours(0, 0, 0, 0) >=
      new Date(this.time.toLocalDate('2023-12-11 00:00:00.000000')).setHours(0, 0, 0, 0);
  }

  /**
   * Getter time left end of day
   */
  get endOfPromo(): Date {
    return this._endOfPromo;
  }

  /**
   * Get promo page
   */
  public getPromo$(): Observable<any> {
    this.isLoadedPromo = false;
    return this.user.auth$.pipe(
      tap((auth) => auth ? this.lootbox.loadUserLoobox().subscribe() : of([])),
      concatMap((auth) => {
        return combineLatest([
          this.page.item({slug: this.eventName}),
          this.user.auth ? this.bonuses.freeSpinsList() : of([]),
          this.user.auth ? this.lootbox.lootboxList$ : of([]),
          this.user.auth ? this.lootbox.lootboxPromoList$ : of([]),
        ]);
      }),
      filter(([promo]) => !!promo),
      map(([promo, fs, lootbox, promoLootbox]: [XmasPromo[], any[], any[], any[]]) => {
        this.isLoadedPromo = false;
        this._isUserHasFsPrize = this._checkBonusStateByBonusType(fs, BONUS_API_IDS.freespins, false);
        this._isUserHasLootboxPrize = this._checkBonusStateByBonusType(lootbox, BONUS_API_IDS.lootboxes, true);
        return [this._resolveByRangeWeekDate(promo, [...Object.values(promo[0]?.Calendar)]), fs, lootbox, promoLootbox];
      }),
      map(([promo, fs, lootbox, promoLootbox]: [XmasPromo[], any[], any[], any[]]) => {
        return promo.map(promoItem => {
          return {
            ...promoItem,
            Lootbox: lootbox,
            PromoLootbox: promoLootbox[0],
            Calendar: this._resolveCalendar(promoItem?.Calendar, fs, lootbox),
            unpublishAt: this.time.toLocalDate(promoItem?.unpublishAt),
            QuestSteps: [...Object.values(promoItem?.QuestSteps)],
            LastWinners: [...Object.values(promoItem?.LastWinners)],
            QuestGroups: [...Object.values(promoItem?.QuestGroups)].filter(item => this._getRangeOfWeekDates().includes(item.date)),
          };
        });
      }),
      map(list => list[0]),
      map((list: XmasPromo) => {
        return {
          ...list,
          QuestSocksLength: this._calculateQuestSocks(list?.QuestGroups),
          isQuestAccomplished: this._calculateQuestSocks(list?.QuestGroups) === 7,
        };
      }),
      tap((list) => this.calendarSliderConfig.initial = this._currentIndexByCurrentDay(list?.Calendar)),
      filter(() => this.platform.isBrowser),
      tap(() => setTimeout(() => this.isLoadedPromo = true, 200)),
    );
  }


  /**
   * Get tournaments and filter by slug
   */
  public getTournaments$(): Observable<any> {
    return this.tournaments.list().pipe(
      filter(tournaments => !!tournaments),
      map(tournaments => tournaments.filter(tournament => {
        return tournament.slug.includes('xmas');
      })),
    );
  }

  /**
   * Get games list by category for bottom slider
   */
  public getGameListByCategory(): Observable<any> {
    return this.cmsApi.gameList({category: GameCategory.WINTER_FAVORITES})
      .pipe(
        map(list => [
          this.array.toChunks(this.games.gameListMapper(this.array.checkAndCloneArray(list.data.gameList?.slice(0, 8), 2)), 4),
          this.games.gameListMapper(list.data.gameList)
        ]),
      );
  }

  public openHuntModal() {
  }

  /**
   * Get completed bonus state
   * @param calendarItem
   * @param fs
   * @param lootbox
   * @private
   */
  private _getCompletedState(calendarItem: XmasCalendarItem, fs: any[], lootbox: any[]): boolean {
    const isTakenStages = [BonusStage.ACTIVATED, BonusStage.FINISHED, BonusStage.CANCELED, BonusStage.PLAYED, BonusStage.EXPIRED];
    const isFsActivated = fs.filter(f => f.title.trim() === BONUS_API_IDS.freespins).some(fsBonus => isTakenStages.includes(fsBonus.stage) &&
      this._areSameDates(fsBonus?.created_at));
    const isLootboxActivated = lootbox.filter(l => l.title.trim().includes(BONUS_API_IDS.lootboxes)).some(lootboxBonus => {
      const createdAtDate = typeof lootboxBonus.created_at === 'string' ?
        new Date(lootboxBonus.created_at) :
        lootboxBonus.created_at;
      return isTakenStages.includes(lootboxBonus.stage) && this._areSameDates(createdAtDate);
    });
    return (isFsActivated || isLootboxActivated) && !this._getLockedState(calendarItem?.date) && this.user.auth;
  }

  /**
   * Get taken and locked bonus state
   * @param date
   * @private
   */
  private _getLockedState(date: any): boolean {
    const currentDate = new Date();
    const currentDateUTC = currentDate.toISOString();
    const originalDate = new Date(currentDateUTC);
    const convertedDateStr = originalDate.toISOString().split('T')[0] + ' 00:00:00.000000';
    return date > convertedDateStr || date < convertedDateStr;
  }

  /**
   * Check UTC bonus format dates
   * @param createdAt
   * @private
   */
  private _areSameDates(createdAt) {
    return new Date().getUTCDay() === new Date(createdAt).getDay() &&
      new Date().getUTCMonth() === new Date(createdAt).getMonth() &&
      new Date().getUTCFullYear() === new Date(createdAt).getFullYear();
  }

  /**
   * Check valid bonus stage by bonus title
   * @param arrayToCheck
   * @param title
   * @param isLootbox
   * @private
   */
  private _checkValidBonusStage(arrayToCheck: any[], title: string, isLootbox: boolean) {
    return arrayToCheck.some(item => {
      const isSameTitle = item[isLootbox ? 'lootboxTitle' : 'title']?.trim().toLowerCase() === this.bonuses.mapBonusTitle(title)?.trim().toLowerCase();
      const isValidStage = [BonusStage.ISSUED].includes(item.stage);
      const createdAtDate = typeof item.created_at === 'string' ? new Date(item.created_at) : item.created_at;
      const isCurrentDate = this._areSameDates(createdAtDate);
      if (isSameTitle && isValidStage && isCurrentDate) {
        this.fsAmount = item.freespins_total;
      }
      return isSameTitle && isValidStage && isCurrentDate;
    });
  }


  /**
   * Check bonus state by bonus array
   * @param arrayToCheck
   * @param bonusApiTitle
   * @param isLootbox
   * @private
   */
  private _checkBonusStateByBonusType(arrayToCheck: any[], bonusApiTitle: string, isLootbox: boolean) {
    return arrayToCheck.some(() => {
      return this._checkValidBonusStage(arrayToCheck, bonusApiTitle, isLootbox);
    });
  }

  /**
   * Resolve calendar
   * @param calendar
   * @param fs
   * @param lootbox
   * @private
   */
  private _resolveCalendar(calendar: XmasCalendarItem[], fs: any[], lootbox: any[]): XmasCalendarItem[] {
    return calendar.map((calendarItem: XmasCalendarItem, i: number) => {
      return {
        ...calendarItem,
        locked: this._getLockedState(calendarItem?.date),
        taken: this._getCompletedState(calendarItem, fs, lootbox),
        day_name: this._formatDayName(calendarItem?.date, false),
        day_name_short: this._formatDayName(calendarItem?.date, true),
        img: `/assets/img/promo/xmas/days/${i}.svg`,
      };
    });
  }

  private _currentIndexByCurrentDay(list: any[]): number {
    const currentDate = new Date();
    const currentDay = currentDate.getUTCDay();

    const currentDayData = list.find(item => {
      const itemDate = new Date(item.date);
      const itemDay = itemDate.getUTCDay();
      return itemDay === (currentDay - 1);
    });

    return list.findIndex(val => val === currentDayData);
  }

  /**
   * Resolve week date range
   * @param promo
   * @param calendar
   * @private
   */
  private _resolveByRangeWeekDate(promo: XmasPromo[], calendar: XmasCalendarItem[]): XmasPromo[] {
    return [
      {
        ...promo[0],
        Calendar: calendar.map((item: XmasCalendarItem, i: number) => {
          return {
            ...item,
            date: this._getRangeOfWeekDates(calendar?.length)[i],
            short_date: this._formatShortDate(this._getRangeOfWeekDates(calendar?.length)[i]),
          };
        }),
      },
    ];
  };

  /**
   * Get short format day
   * @param inputDate
   * @private
   */
  private _formatShortDate(inputDate: any) {
    const date = new Date(inputDate);
    const monthNames = ['t.jan', 't.feb', 't.mar', 't.apr', 't.may', 't.jun', 't.jul', 't.aug', 't.sep', 't.oct', 't.nov', 't.dec'];

    const day = date.getDate().toString().padStart(2, '0');
    const month = monthNames[date.getMonth()];
    return `${day} <br> ${this.translate.translate(month)}`
  }

  /**
   * Get day of week
   * @param dateString
   * @param isShort
   * @private
   */
  private _formatDayName(dateString: string, isShort: boolean) {
    let days = [];
    if (isShort) {
      days = ['t.sun', 't.mon', 't.tue', 't.wed', 't.thu', 't.fr', 't.sat'];
    } else {
      days = ['t.sunday', 't.monday', 't.tuesday', 't.wednesday', 't.thursday', 't.friday', 't.saturday'];
    }
    const d = new Date(dateString);
    return days[d.getDay()];
  }


  /**
   * Get range of dates by diapason
   * @param diapasonRange
   * @private
   */
  private _getRangeOfWeekDates(diapasonRange: number = 7) {
    const currentDate = new Date();
    const dates = [];
    const currentDay = currentDate.toLocaleString('en-us', {weekday: 'long'}).toLowerCase();
    const startWeekIndex = currentDay === 'sunday' ? -6 : 0;
    for (let i = 0; i < diapasonRange; i++) {
      const date = new Date(
        Date.UTC(
          currentDate.getUTCFullYear(),
          currentDate.getUTCMonth(),
          currentDate.getUTCDate() - currentDate.getUTCDay() + i + (currentDate.getUTCDay() === 0 ? startWeekIndex : 1),
          0, 0, 0,
        ),
      );
      const formattedDate = date.toISOString().split('T')[0] + ' 00:00:00.000000';
      dates.push(formattedDate);
    }
    return dates;
  }

  private _calculateQuestSocks(questGroups: XmasQuestGroupItem[], userStatuses = this.user.info.statuses): number {
    if (this.user.auth) {
      const idsArray1 = questGroups?.map(item => item.group);
      const idsArray2 = userStatuses?.map(item => item.id);
      return idsArray1.filter(id => idsArray2.includes(id)).length;
    }
  }
}
