import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header.component';
import { HeaderAuthorizedComponent } from './header-authorized/header-authorized.component';
import { HeaderNotAuthorizedComponent } from './header-not-authorized/header-not-authorized.component';
import { NotificationCenterModule } from '../../../modules/notification-center/notification-center.module';
import { RouterModule } from '@angular/router';
import { ComponentsModule } from '../../components/components.module';
import { DirectivesModule } from '../../directives/directives.module';
import { SelectModule } from '../../../modules/select/select.module';
import { PipesModule } from '../../pipes/pipes.module';
import { TranslationModule } from '../../translation/translation.module';

@NgModule({
  declarations: [
    HeaderComponent,
    HeaderAuthorizedComponent,
    HeaderNotAuthorizedComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    PipesModule,
    TranslationModule,
    ComponentsModule,
    DirectivesModule,
    SelectModule,
    NotificationCenterModule
  ],
  exports: [
    HeaderComponent,
    HeaderAuthorizedComponent,
    HeaderNotAuthorizedComponent,
    NotificationCenterModule
  ]
})
export class HeaderModule { }
