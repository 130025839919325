<ng-container *ngIf="epm.text$ | async as text">
  <div class="cookies" (click)="cookiesAccepted()">
    <ng-container>
      <div>
        <span>{{ 't.we-use-cookies' | translate }}. </span>
        <div *ngIf="text === ' '">
          <span>{{ 't.see-our' | translate }} </span>
          <a (click)="goToPolicy()">{{ 't.privacy-policy' | translate }} </a>
          <span>{{ 't.to-learn-more' | translate }}. </span>
        </div>
        <span [innerHTML]="text"></span>
      </div>
      <button class="btn btn--filled-secondary accept" title="{{ 't.accept-cookies' | translate }}">{{ 't.accept' | translate }}</button>
    </ng-container>
  </div>
</ng-container>
