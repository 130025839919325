import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { GamesService } from '../../services/games/games.service';
import { filter, first, map, switchMap } from 'rxjs/operators';
import { DeviceDetectorService } from 'ngx-device-detector';
import { GamesLauncherService } from '../../services/games/games-launcher.service';
import { UserService } from '../../services/user/user.service';

@Injectable({
  providedIn: 'root'
})
export class GamePageGuard implements CanActivate {

  constructor(
    private _router: Router,
    private _games: GamesService,
    private _device: DeviceDetectorService,
    private _launcher: GamesLauncherService,
    private _user: UserService
  ) {
  }

  /**
   * Check if game exists in current navigation state
   * and get game from backend if not exists
   *
   * @param next
   * @param state
   */
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    /**
     * Get current game data from navigationState
     */
    const game =
      this._router.getCurrentNavigation().extras &&
      this._router.getCurrentNavigation().extras.state &&
      this._router.getCurrentNavigation().extras.state.game;

    /**
     * If game exists -> continue navigation
     */
    if (game) {
      this._launcher.currentGame = game;
      return this._user.auth$.pipe(
        switchMap(auth => of(this._launcher.checkGameAvailability(game)))
      );
    } else {
      const slug = this._resolveGameSLug(next);
      /**
       * Else get game from backend and save to navigationState
       */
      return this._user.auth$.pipe(
        switchMap(auth => this._games.list({slug})),
        filter(response => !!response),
        first(),
        map(response => {
          if (response.gameList[0]) {
            this._launcher.currentGame = response.gameList[0];
          }

          return this._launcher.checkGameAvailability(response.gameList[0] || null);
        }),
      );
    }
  }

  /**
   * Returns correct game slug
   *
   * @private
   */
  private _resolveGameSLug(route: ActivatedRouteSnapshot) {
    if (route.queryParams['mobile-alias'] && !this._device.isDesktop()) {
      return route.queryParams['mobile-alias'];
    } else {
      return route.params.slug;
    }
  }

}
