import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { SideNavService } from '../side-nav/side-nav.service';
import { UserService } from '../../../services/user/user.service';
import { LanguageService } from '../../../services/language/language.service';
import { SeoService } from '../../../services/seo.service';
import { EnvironmentService } from '../../../services/environment.service';
import { GlobalEventsService } from '../../../services/global-events.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter, tap } from 'rxjs/operators';
import { HalloweenService } from '../../../../page/promo/halloween-treasure-valley/halloween.service';
import { XmasService } from '../../../../page/promo/xmas/xmas.service';

@UntilDestroy()
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {

  /**
   * For disable transparent if scroll down
   */
  @Input() colorDuringSroll: string;

  /**
   * For toggle colors
   */
  public changedColor: string;

  constructor(
    public user: UserService,
    public sidenav: SideNavService,
    public seo: SeoService,
    public lang: LanguageService,
    public env: EnvironmentService,
    public halloween: HalloweenService,
    public xmas: XmasService,
    private _events: GlobalEventsService
  ) {
  }

  ngOnInit() {
    this.changedColor = this.colorDuringSroll;
    this._events.scroll$.pipe(
      untilDestroyed(this),
      filter(() => window && !!this.colorDuringSroll),
      tap(() => {
        if (window.pageYOffset > 75) {
          this.changedColor = '';
        } else {
          this.changedColor = this.colorDuringSroll;
        }
      })
    ).subscribe();
  }

  ngOnDestroy() {
  }
}
