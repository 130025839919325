import { Component, OnInit } from '@angular/core';
import {ModalContent, ModalRef} from '../../../modal-ref';

@Component({
  selector: 'app-bonus-cancel-confirmation',
  templateUrl: './bonus-cancel-confirmation.component.html',
  styleUrls: ['./bonus-cancel-confirmation.component.scss']
})
export class BonusCancelConfirmationComponent implements OnInit, ModalContent {

  /**
   * Link to modal window
   */
  public modal: ModalRef;

  constructor() { }

  ngOnInit() {
  }

}
