export const environment = {
  production: true,
  cms_host: 'https://api.wildfortune.io',
  api_host: 'https://api.wildfortune.io/api',
  ss_api_host: 'https://wildfortune.io/api',
  ss_host: 'https://wildfortune.io',
  assets_host: 'https://static.wildfortune.io',
  app_host: 'https://www.wildfortune.io',
  error_host: 'https://error.wildfortune.io/error/handle',
  mlh_host: 'https://mlh.wildfortune.io',
  mirror_host_au: 'https://www.wildfortune24.io',
  redirector_host: 'https://api.dstat.site/api',
  wr_websocket: 'https://ue.gmngeng.com',
  hotjar_id: 1870206,
  hotjar_version: 6
};

export enum BannerType {
  TOP_SLIDER = 5,
  BOTTOM_SLIDER = 6,
  BOTTOM_STICKY = 7,
  REVIEW = 9,
  SIGN_UP = 11,
  POPUP_BLOCKER = 19,
  TOP_WINNERS = 10,
  LOW_BALANCE = 14,
  LIVE_CASINO = 24,
  MOBILE_APP = 27
}

export enum SocialAuthLink {
  FACEBOOK = 'https://spinsamurai.com/users/auth/facebook',
  GOOGLE = 'https://spinsamurai.com/users/auth/google_oauth2',
  TWITTER = 'https://spinsamurai.com/users/auth/twitter'
}

export enum BlogType {
  TEXT = '1',
  VIDEO = '2'
}

export enum StaticContentType {
  TOP_FEATURES = 13,
}

export const TEST_COOKIE = ['test_env', '1'];
