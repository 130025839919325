import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToastMessageComponent } from './toast-message.component';
import { ToastMessageItemComponent } from './toast-message-item/toast-message-item.component';
import { CookieMessageContentComponent } from './content/cookie-message-content/cookie-message-content.component';
import { TranslationModule } from '../../shared/translation/translation.module';



@NgModule({
    declarations: [
        ToastMessageComponent,
        ToastMessageItemComponent,
        CookieMessageContentComponent
    ],
    imports: [
        CommonModule,
        TranslationModule
    ],
    exports: [
        ToastMessageComponent,
        ToastMessageItemComponent,
        CookieMessageContentComponent
    ]
})
export class ToastMessageModule { }
