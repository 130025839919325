import { Injectable } from '@angular/core';
import {filter, map, share, switchMap, tap} from 'rxjs/operators';
import {combineLatest, Observable, ReplaySubject} from 'rxjs';
import {StaticPageService} from '../static-page/static-page.service';
import {IJackpotCMS, IJackpotSS, IJackpotWinner, JackpotLevels} from './base-jackpot.interface';
import {StaticContentService} from '../../core/services/static-content.service';
import {ArrayService} from '../../core/services/array.service';
import {GamesService} from '../../core/services/games/games.service';
import {SsApiService} from '../../core/services/api/ss-api.service';
import {CommonDataService} from '../../core/services/common-data.service';
import {WebSocketService} from '../../core/services/web-socket.service';
import {BreakpointsService} from '../../core/services/breakpoints.service';
import { UserService } from '../../core/services/user/user.service';
import { CmsApiService } from '../../core/services/api/cms-api.service';
import { GameCategory } from '../../core/services/games/game-category';

@Injectable({
  providedIn: 'root'
})
export class JackpotService {

  /**
   * Show/hide sidebar menu link
   */
  public jackpotSlug: string;

  /**
   * Is jackpot enable
   */
  public isJackpotEnable: boolean;

  /**
   * Jackpot page from CMS
   */
  public cmsJackpot$: ReplaySubject<IJackpotCMS> = new ReplaySubject<IJackpotCMS>(1);

  /**
   * Jackpot from SS
   */
  public ssJackpot$: ReplaySubject<IJackpotSS> = new ReplaySubject<IJackpotSS>(1);

  /**
   * Winner jackpot from ws
   */
  public wsWinnerJackpot$: ReplaySubject<IJackpotSS> = new ReplaySubject<IJackpotSS>(1);


  /**
   * Jackpot Hunter CMS list
   */
  public jackpotHunterCMSList$: Observable<IJackpotWinner[]>;

  /**
   * Ws Jackpots
   * @private
   */
  private _wsJackpots$: Observable<IJackpotSS> = this._ws.jackpots$;

  /**
   * Winner SS list
   */
  public winnersSSList$: Observable<IJackpotWinner[]>;

  public jackpotBadgeImg = '<img src="/assets/img/jackpot/jackpot-badge.png" alt="Jackpot badge">';


  constructor(
    private _static: StaticContentService,
    private _pages: StaticPageService,
    private _array: ArrayService,
    private _games: GamesService,
    private _ssApi: SsApiService,
    private _common: CommonDataService,
    private _ws: WebSocketService,
    private _breakpoints: BreakpointsService,
    private _cmsApi: CmsApiService,
    private _user: UserService,
  ) {}

  /**
   * Get and serialize promo
   */
  public initJackpot(slug: string) {
    let jackpotSlug: string;

    return this.getAvailableJackpotSlug(slug).pipe(
      filter(response => !!(response && response.length)),
      switchMap(response => {
        jackpotSlug = response[0] && response[0].Content;
        return this.getJackpotData(jackpotSlug);
      }),
      share()
    );
  }

  public getJackpotData(jackpotSlug: string) {
    return combineLatest([
      this.getCmsJackpot(jackpotSlug),
      this.getSSJackpot()
    ]).pipe(
      tap(([cmsJackpot, ssJackpot]) => {
        if (cmsJackpot && ssJackpot) {
          this.jackpotSlug = jackpotSlug;
          this.isJackpotEnable = true;
          this.cmsJackpot$.next(cmsJackpot);
          this.ssJackpot$.next(ssJackpot);
          this._listenWsJackpots();
          this._listenWsWinnerJackpots();
        } else {
          this.isJackpotEnable = false;
          this.cmsJackpot$.next(null);
          this.ssJackpot$.next(null);
        }
      }),
      share()
    );
  }

  private _listenWsJackpots() {
    this._wsJackpots$.pipe(
      map(jackpot => this._updateLevelsAndTotal(jackpot)),
      tap(this.ssJackpot$)
    ).subscribe();
  }

  private _listenWsWinnerJackpots() {
    this._ws.jackpotsWin$.pipe(
      filter(bonus => bonus.strategy === 'jackpot_award' && bonus.stage === 'issued'),
      map(jackpot => ({
        ...jackpot,
        currencySymbol: this._common.currencySymbol(jackpot.currency),
        amount: this._common.subunitsToUnits(jackpot.amount_cents, jackpot.currency)
      })),
      tap(this.wsWinnerJackpot$)
    ).subscribe();
  }

  /**
   * Mapped grouplist
   * @param data
   */
  public mappedGroupList(data: any = {}): any {
    return Object.values(data);
  }

  public getAvailableJackpotSlug(slug: string) {
    return this._static.item({slug});
  }

  public getCmsJackpot(slug: string) {
    return this._pages.item({slug}).pipe(
      filter(jackpot => !!jackpot && Boolean(jackpot[0])),
      map((jackpot) => jackpot.map(item => {
        return {
          ...item,
          Levels: this.mappedGroupList(item?.Levels)?.sort(
            (prev, next) => Object.values(JackpotLevels).indexOf(prev.type) - Object.values(JackpotLevels).indexOf(next.type)
          ),
          Benefits: this._breakpoints.isMobile ? item.Benefits?.split(',').map(s => s.trim()) : this._array.toChunks(item.Benefits?.length ? item.Benefits?.split(',').map(s => s.trim()) : [], 3),
          Works: this.mappedGroupList(item.Works).map((w, i) => ({
            ...w,
            text: w.text?.split(' ').map(s => `<span>${i === 0 && s === w.text?.split(' ')[w.text?.split(' ').length - 1] ? s + this.jackpotBadgeImg : s}</span>`).join(' ')
          })),
          WinnersList: (this.mappedGroupList(item.WinnersList) || []).map((item, index) => ({
            place: index + 1,
            name: item.name,
            prize: item.prize
          }))
        };
      })[0]),
      map(jackpot => !jackpot?.statusCode ? jackpot : null),
      share(),
    );
  }

  public getSSJackpot() {
    return this._ssApi.jackpots().pipe(
      map(jackpots => jackpots.filter(j => j.state === 'active')[0]),
      filter(jackpot => Boolean(jackpot)),
      map(jackpot => this._updateLevelsAndTotal(jackpot)),
      tap(data => this.winnersSSList$ = this._getWinnersSSList(data?.name)),
      tap(data => this.jackpotHunterCMSList$ = this._getJackpotHunterCMSList()),
      share()
    );
  }

  private _getWinnersSSList(jackpotName: string = '') {
    return this._ssApi.jackpotWins(jackpotName ? {jackpot_name: jackpotName, sort_by: 'amount_cents'} : {sort_by: 'amount_cents'}).pipe(
      filter(data => !!data),
      map(data => {
        return (data || []).slice(0, 10).map((item, index) => {
          return {
            place: index + 1,
            name: item.user_name.slice(-0, item.user_name.length > 3 ? -3 : -2) + (item.user_name.length > 3 ? '***' : '**'),
            prize: this._common.subunitsToUnits(item.amount_cents, item.jackpot_original_currency)
          };
        });
      })
    );
  }

  private _updateLevelsAndTotal(wsJackpotMessage: IJackpotSS) {
    wsJackpotMessage = JSON.parse(JSON.stringify(wsJackpotMessage));
    wsJackpotMessage['total'] = 0;
    wsJackpotMessage['currencySymbol'] = this._common.currencySymbol(wsJackpotMessage.currency);
    wsJackpotMessage.levels = wsJackpotMessage.levels?.reverse().sort((prev: any, next: any) => Object.values(JackpotLevels).indexOf(prev.name) - Object.values(JackpotLevels).indexOf(next.name));
    wsJackpotMessage.levels.forEach(level => {
      level['total'] = 0;
      const sumFilds = ['amount_cents'];
      Object.entries(level).forEach(f => sumFilds.includes(f[0]) ? level[f[0]] = this._common.subunitsToUnits(f[1], wsJackpotMessage.currency) : null);
      level['total'] = Number((Object.entries(level).reduce((acc, curr) =>   acc + (sumFilds.includes(curr[0]) ? curr[1] : 0) , 0)).toFixed(2));
      wsJackpotMessage['total'] += Number((Object.entries(level).reduce((acc, curr) =>   acc + (sumFilds.includes(curr[0]) ? curr[1] : 0) , 0)).toFixed(2));
    });

    return wsJackpotMessage;
  }


  private _getJackpotHunterCMSList() {
    return this._cmsApi.getLeaderBoard({category_id: GameCategory.GRIZZLY_JACKPOT}).pipe(
      filter(data => !!data),
      map(data  => data?.data?.data?.list),
      map(data => {
        const currentPlayer = data?.find((e) => e?.nickname === this._user?.info?.nickname);
        const array = (data || [])?.slice(0, 10);
        currentPlayer ? array.push(currentPlayer) : null;
        const result = array.map((item) => {
          return {
            isCurrentPlayer: this._user?.info?.nickname === item?.nickname,
            place: item?.place,
            name: item?.nickname?.slice(-0, item?.nickname?.length > 3 ? -3 : -2) + (item?.nickname?.length > 3 ? '***' : '**'),
            prize: item?.bets_volume,
          };
        });
        return result;
      }),
    );
  }

}
