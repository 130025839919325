import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from '../../services/user/user.service';
import { tap } from 'rxjs/operators';
import { LocalHistoryService } from '../../services/local-history.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private _user: UserService,
    private _router: Router,
    private _localHistory: LocalHistoryService
  ) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this._user.auth$.pipe(
      tap(auth => {
        if (!auth) {
          this._localHistory.addUrl('before-login', state.url);
          this._router.navigateByUrl('/register').then();
        }
      })
    );
  }

}
