import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NotificationCenterDropDownComponent} from './notification-center-drop-down/notification-center-drop-down.component';
import {TranslationModule} from '../../shared/translation/translation.module';
import {RouterModule} from '@angular/router';
import {DirectivesModule} from '../../shared/directives/directives.module';
import { NotificationCenterComponent } from './notification-center/notification-center.component';
import { NotificationCenterItemComponent } from './notification-center-item/notification-center-item.component';
import {PipesModule} from '../../shared/pipes/pipes.module';

@NgModule({
  declarations: [
    NotificationCenterDropDownComponent,
    NotificationCenterComponent,
    NotificationCenterItemComponent
  ],
  exports: [
    NotificationCenterDropDownComponent,
    NotificationCenterComponent,
    NotificationCenterItemComponent
  ],
  imports: [
    CommonModule,
    TranslationModule,
    DirectivesModule,
    RouterModule,
    PipesModule
  ]
})
export class NotificationCenterModule {
}
