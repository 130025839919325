<ng-container *ngIf="banner$ | async as banner">
  <ng-container *ngIf="banner.length">
    <div class="bonus-container" [ngClass]="{visible: visible}">
      <div class="panel">
        <i class="panel__close icon-close" (click)="close()"></i>
        <div class="bonus">
          <img class="bonus__img" [src]="banner[0].Image" alt="" DeleteNotFound>
          <div class="bonus__text">
            <div class="bonus__title">{{ banner[0].Title }}</div>
            <div class="bonus__description">{{ banner[0].Description }}</div>
          </div>
          <a class="bonus__action btn big btn--filled-primary" [href]="banner[0].ButtonUrl">{{ banner[0].ButtonName }}</a>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>
