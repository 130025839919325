import { BadgeService, WATCHED_FOR_OPEN } from '../../core/services/badge.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { OffersService } from './offers.service';
import { combineLatest, Observable, of, ReplaySubject } from 'rxjs';
import { SeoService } from '../../core/services/seo.service';
import { catchError, filter, first, map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { StaticPageService } from '../static-page/static-page.service';
import { UserService } from '../../core/services/user/user.service';
import { GroupsService } from '../../core/services/groups.service';
import { EnvironmentService } from '../../core/services/environment.service';
import { UserBonusesService } from '../../core/services/user/user-bonuses.service';
import { LootboxService } from '../../core/services/lootbox/lootbox.service';
import { BonusStage } from '../../core/services/user/data/user-bonuses.data';
import { ToastMessageService } from '../../core/modules/toast-message/toast-message.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { LanguageService } from '../../core/services/language/language.service';
import { Router } from '@angular/router';
import { EventType } from '../promo/base-promo';
import { ModalService } from '../../core/modal-v2/modal.service';
import { HalloweenService } from '../promo/halloween-treasure-valley/halloween.service';

export enum BonusTypes {
  WELCOME = 'welcome',
  RELOAD = 'reload',
  LOYALTY = 'loyalty',
  DAILY = 'daily'
}

export const CLAIMED_WELCOME_BONUSES_GROUP = 'ID62';
export const CLAIMED_WELCOME_4_SETUP = 'ID610';

@UntilDestroy()
@Component({
  selector: 'app-offers',
  templateUrl: './offers.component.html',
  styleUrls: ['./offers.component.scss']
})
export class OffersComponent implements OnInit, OnDestroy {

  /**
   * Promo list observable
   */
  public readonly list$: ReplaySubject<any> = this._offers.list();

  /**
   * SEO Text from CMS
   */
  public offersText$: ReplaySubject<any> = this._page.item({
    slug: 'bonuses'
  }).pipe(
    tap((data) => {
      if (data) {
        this._seo.setMetaTags(data[0] || {});
      }
    }),
    shareReplay(1)
  ) as ReplaySubject<any>;

  /**
   * List of initialized sliders
   */
  public readonly sliders: any = {};

  /**
   * Welcome bonus type
   */
  public welcomeBonus: BonusTypes = BonusTypes.WELCOME;

  /**
   * Reload bonus type
   */
  public reloadBonus: BonusTypes = BonusTypes.RELOAD;

  /**
   * Loyalty bonus type
   */
  public loyaltyBonus: BonusTypes = BonusTypes.LOYALTY;

  /**
   * Deposit bonus list from SS
   * @private
   */
  private _depositBonusList$ = this.user.auth ? this._bonuses.depositBonusList() : of([]);

  /**
   * Cms bonus list
   * @private
   */
  public dailyRewardBonus$ = this._offers.list({
    category_slug: 'description-bonus'
  }).pipe(
    filter(list => !!list),
    map(list => list.map(bonus => ({
      ...bonus,
      type: BonusTypes.DAILY
    }))),
  );

  /**
   * Welcome bonuses list observable
   */
  public readonly welcomeBonuses$: Observable<any> = combineLatest([this._depositBonusList$, this._bonuses.cmsBonusList$])
    .pipe(
      tap(([bonusListSS, bonusListCMS]) => {
        if (bonusListSS.length) {
          this.currentWelcomeIndex = this._bonuses.findActiveBonusCMSIndex(bonusListCMS, bonusListSS);
          this.currentWelcomeIndex = this.currentWelcomeIndex < 0 ? 0 : this.currentWelcomeIndex;
        }
      }),
      map(([bonusListSS, bonusListCMS]) => bonusListCMS)
    );

  /**
   * Reload bonuses list observable
   */
  public readonly reloadBonuses$: Observable<any> = this._offers.list({
    category_slug: 'reload-bonuses'
  }).pipe(
    filter(list => !!list),
    map(list => {
      return list.map(bonus => {
        return {
          title: bonus.title,
          description: bonus.description,
          image: bonus.image,
          color: bonus.color,
          type: this.reloadBonus,
          countFS: bonus.countFS
        };
      });
    })
  );

  /**
   * Loyalty bonuses list observable
   */
  public readonly loyaltyBonuses$: Observable<any> = this._offers.list({
    category_slug: 'loyalty-bonuses'
  }).pipe(
    filter(list => !!list),
    tap(list => {
      if (this.user.auth) {
        const activeLoyaltyIndex = list.findIndex(l => l.slug.replace('-', '') === this._groups.loyaltyGroup);
        this.loyaltyActiveIndex = activeLoyaltyIndex === 0 || activeLoyaltyIndex ? activeLoyaltyIndex : null;
      } else {
        this.loyaltyActiveIndex = 0;
      }
    }),
    map(list => {
      return list.map(bonus => {
        return {
          title: bonus.title,
          description: bonus.description,
          image: bonus.image,
          color: bonus.color,
          topLabel: bonus.topLabel,
          type: this.loyaltyBonus,
          countFS: bonus.countFS
        };
      });
    })
  );

  /**
   * Special bonuses from SS (bonuses, deposit bonuses, free spins, lootboxes)
   */
  public specialBonuses$: Observable<any>;

  /**
   * Social promo
   */
  public promo$ = this._offers.item({ slug: 'telegram-viber-promo' });

  /**
   * Loyalty active index
   */
  public loyaltyActiveIndex: number;

  /**
   * Current welcome index bonus
   */
  public currentWelcomeIndex = 0;

  /**
   * Is welcome bonuses claimed
   */
  public isWelcomeBonusesClaimed: boolean;

  constructor(
    private _offers: OffersService,
    private _seo: SeoService,
    private _badge: BadgeService,
    private _page: StaticPageService,
    private _groups: GroupsService,
    private _bonuses: UserBonusesService,
    private _lootbox: LootboxService,
    private _modal: ModalService,
    private _toastMessage: ToastMessageService,
    private _lang: LanguageService,
    private _router: Router,
    public user: UserService,
    public env: EnvironmentService,
    public halloween: HalloweenService
  ) { }

  ngOnInit() {
    this._seo.setMetaTags({ MetaTitle: 'Wild Fortune | Offers' });
    this._badge.openListEntity(WATCHED_FOR_OPEN.OFFERS);

    this.user.auth$.pipe(
      untilDestroyed(this),
      filter(auth => !!auth),
      tap(() => {
        this.isWelcomeBonusesClaimed = this._groups.isExistGroup(CLAIMED_WELCOME_BONUSES_GROUP);
        this._lootbox.loadUserLoobox().subscribe();
        this._updateSSBonusList();
      })
    ).subscribe();

    this._lang.langChange$.pipe(
      untilDestroyed(this),
      tap(() => {
        this._updateSSBonusList();
      })
    ).subscribe();
  }

  ngOnDestroy() {
  }

  get Stage() {
    return BonusStage;
  }

  get EventType() {
    return EventType;
  }

  private _updateSSBonusList() {
    this.specialBonuses$ = combineLatest([
      this._bonuses.freeSpinsList(),
      this._bonuses.bonusList(),
      this._lootbox.lootIssuedboxList$
    ]).pipe(
      map(([fs, bonuses, lootbox]) => fs.concat(bonuses).concat(lootbox)),
      map(list => list.filter(bonus => bonus.active !== false && bonus.stage !== this.Stage.EXPIRED && bonus.stage !== this.Stage.FINISHED))
    );
  }

  /**
   * Check is bonus FS
   *
   * @private
   */
  private _isFSBonus(bonus) {
    return bonus.activation_path && bonus.activation_path.includes('freespins');
  }

  /**
   * Cancel provided bonus
   *
   * @param bonus
   */
  cancel(bonus: any) {
    const modal = this._modal.open('BONUS_CANCEL_CONFIRM');
    let path;
    if (this._isFSBonus(bonus)) {
      path = this._bonuses.cancelFreeSpins(bonus.id);
    } else if (this._bonuses.isLootbox(bonus)) {
      path = this._lootbox.cancelLootbox(bonus.id);
    } else {
      path = this._bonuses.cancelBonus(bonus.id);
    }

    modal.onResult().pipe(
      first(),
      filter(confirm => !!confirm),
      switchMap(() => {
        bonus.loading = true;
        return path;
      }),
      tap(() => {
        this._toastMessage.success('t.bonus-canceled');
        this._updateSSBonusList();
      }),
      catchError(error => {
        this._toastMessage.error('t.error');
        return of(error);
      })
    ).subscribe(() => {
      bonus.loading = false;
    });
  }

  /**
   * Activate provided bonus
   *
   * @param bonus
   */
  activate(bonus: any) {
    bonus.loading = true;
    let path;
    if (this._isFSBonus(bonus)) {
      path = this._bonuses.activateFreeSpins(bonus.id);
    } else {
      path = this._bonuses.activateBonus(bonus.id);
    }

    path.pipe(
      tap(() => {
        bonus.pending_activation = true;
        this._toastMessage.success('t.few-moments');
      }),
      catchError(error => {
        this._toastMessage.error('t.error');
        return of(error);
      })
    ).subscribe(() => {
      bonus.loading = false;
    });
  }

  public onBonusClick() {
    if (this.user.auth) {
      this._router.navigateByUrl('/deposit').then();
    } else {
      this.user.authUser().then();
    }
  }
}
