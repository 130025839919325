<div class="install-app-banner">
  <div class="install-app-banner__wrapper">
    <img class="install-app-banner__logo" src="/assets/img/mobile-app/icon.png" alt="Install app logo">
    <div>
      <div class="install-app-banner__text">{{ 't.all-games-bonuses-app' | translate }}</div>
      <button class="btn btn--filled-primary" (click)="installApp.closeDepositBlock()" [routerLink]="['/', 'mobile-app']">{{'t.how-install' | translate}}</button>
    </div>
  </div>
  <i class="close icon-close" (click)="installApp.closeDepositBlock()"></i>
</div>
