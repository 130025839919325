import { Component, OnDestroy, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { BannersService } from '../../../services/banners.service';
import { fromEvent, NEVER, Observable } from 'rxjs';
import { BannerType } from '../../../../../environments/environment';
import { PlatformService } from '../../../services/platform.service';
import { debounceTime, tap } from 'rxjs/operators';
import { UserService } from '../../../services/user/user.service';

@Component({
  selector: 'app-sticky-bonus',
  templateUrl: './sticky-bonus.component.html',
  styleUrls: ['./sticky-bonus.component.scss']
})
export class StickyBonusComponent implements OnInit, OnDestroy {

  /**
   * Banner from CMS
   */
  public banner$: Observable<any> = NEVER;

  /**
   *  Is banner visible
   */
  public visible: boolean;

  constructor(
    private _cookie: CookieService,
    private _banners: BannersService,
    private _platform: PlatformService,
    private _user: UserService
  ) { }

  ngOnInit() {
    // if (!this._cookie.check('sticky-banner')) {
    //   this.banner$ = this._banners.list({
    //     type: BannerType.BOTTOM_STICKY
    //   });
    //
    //   this._handleScroll();
    // }
  }

  /**
   * Handle banner close
   */
  close() {
    this._cookie.set('sticky-banner', '1', 33, '/');
    this.banner$ = NEVER;
  }

  ngOnDestroy(): void {
  }

  /**
   * Handle window scroll
   *
   * @private
   */
  private _handleScroll() {
    if (!this._platform.isBrowser) { return; }

    fromEvent(window, 'scroll', {
      capture: true,
      passive: true
    }).pipe(
      debounceTime(300),
      tap(event => {
        this.visible = (window.scrollY > 300) && !this._user.auth;
      })
    ).subscribe();
  }

}
