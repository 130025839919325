import { Component } from '@angular/core';
import { ToastMessage, ToastMessageService } from './toast-message.service';
import { showHide } from './animations';

@Component({
  selector: 'app-toast-message',
  templateUrl: './toast-message.component.html',
  styleUrls: ['./toast-message.component.scss'],
  animations: [showHide]
})
export class ToastMessageComponent {

  constructor(
    private _toastMessage: ToastMessageService
  ) { }

  get messagesList(): Array<ToastMessage> {
    return this._toastMessage.messagesList;
  }

  onMessageClick(message) {
    message.isClicked = true;
    this._toastMessage.removeMessage(message);
  }

}
