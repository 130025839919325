import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ValidationPatterns } from '../validation-patterns';
import { SsApiService } from '../../services/api/ss-api.service';
import { catchError, finalize, tap } from 'rxjs/operators';
import { forkJoin, Observable, of, ReplaySubject } from 'rxjs';
import { UserService } from '../../services/user/user.service';
import { ToastMessageService } from '../../modules/toast-message/toast-message.service';
import { CacheService } from '../../services/cache/cache.service';
import { ContentUpdaterService } from '../../services/content-updater/content-updater.service';
import { FormsErrorHandlerService } from '../../services/forms-error-handler.service';
import { EventEmitter, inject } from '@angular/core';
import { WrSocketService } from '../../services/wr-socket.service';

/**
 * For handling login forms
 */
export class LoginFormController {

  /**
   * Access to global services
   */
  private _fb: UntypedFormBuilder = inject(UntypedFormBuilder);
  private _ssApi: SsApiService = inject(SsApiService);
  private _user: UserService = inject(UserService);
  private _toastMessage: ToastMessageService = inject(ToastMessageService);
  private _cache: CacheService = inject(CacheService);
  private _contentUpdater: ContentUpdaterService = inject(ContentUpdaterService);
  private _formErrors: FormsErrorHandlerService = inject(FormsErrorHandlerService);
  private _wrSocket: WrSocketService = inject(WrSocketService);
  /**
   * Is loading state
   */
  private _loading: boolean;

  /**
   * Is 2FA enabled variable for get desired form control
   */
  private _is2FAEnabled: boolean;

  /**
   * Is 2FA enabled
   */
  private _is2FAEnabled$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  private _is2FAEnabled$$: Observable<any> = this._is2FAEnabled$.asObservable();

  /**
   * Login for FormGroup
   */
  private _form: UntypedFormGroup = this._fb.group({
    email: [null, [Validators.required, Validators.pattern(ValidationPatterns.email)]],
    password: [null, [Validators.required]],
    otp_attempt: [null]
  });

  /**
   * Emits updating result
   */
  public loggedIn$: EventEmitter<any> = new EventEmitter<any>();

  constructor() {
  }

  /**
   * Access to private properties from outside
   */
  get form(): UntypedFormGroup { return this._form; }
  get loading(): boolean { return this._loading; }
  get is2FAEnabled$(): Observable<boolean> { return this._is2FAEnabled$$; }

  /**
   * Returns FormControl by key
   *
   * @param key
   */
  input(key: string): AbstractControl {
    return this._form.get(key);
  }

  /**
   * Submit form handler
   */
  submitForm() {
    this._formErrors.applyFormErrors(this._form, null, true);

    if (this._form.invalid) {
      return;
    }
    this._loading = true;

    this._ssApi.usersSignIn({
      user: this._is2FAEnabled ?
        { otp_attempt: this.input('otp_attempt').value } :
        { email: this.input('email').value, password: this.input('password').value }
    }).pipe(
      tap(response => {
        this._cache.clear();
        this._user.applyUserInfo(response);

        forkJoin([
          this._user.getUserAccounts(),
          this._user.getUserAccountsCompatibility()
        ]).subscribe();

        this._contentUpdater.updateAll();
        this.loggedIn$.next(response);
        this._wrSocket.sendEventLogin();
      }),
      catchError(error => {
        if (error.status === 422 && error.error.required && error.error.required.includes('one_time_password')) {
          this._loading = false;
          this._is2FAEnabled = true;
          this._is2FAEnabled$.next(true);
          return of(error);
        } else {
          this._toastMessage.error(this._formErrors.ssBackendErrorsToArray(error.error)[0] || 't.invalid-email-pass');
          this._changesFieldErrorsIfSSError(error.error);
          this._formErrors.applyFormErrors(this._form, error.error, true);
          return of(error);
        }
      }),
      finalize(() => this._loading = false)
    ).subscribe();
  }

  /**
   * If error from SS reset errors for some fields if valueChanges
   * @private
   */
  private _changesFieldErrorsIfSSError(errors) {
    const controls = Object.keys(errors.errors);

    if (controls.length) {
      this.form.valueChanges.pipe(
        tap(() => {
          controls.forEach(control => this.input(control).setErrors(null));
          this._formErrors.applyFormErrors(this._form, null, true);
        }),
      ).subscribe();
    }
  }
}
