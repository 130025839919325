import { Component, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { REGENERATED_CRYPTO_COOKIE, REGENERATED_CRYPTO_GROUP, UserService } from '../core/services/user/user.service';
import {
  concatMap,
  debounceTime,
  delay,
  distinctUntilChanged,
  filter,
  first, map,
  switchMap,
  takeWhile,
  tap
} from 'rxjs/operators';
import { WindowService } from '../core/services/window.service';
import { PlatformService } from '../core/services/platform.service';
import { combineLatest, of } from 'rxjs';
import { ToastMessageService } from '../core/modules/toast-message/toast-message.service';
import {
  CookieMessageContentComponent
} from '../core/modules/toast-message/content/cookie-message-content/cookie-message-content.component';
import { CookieService } from 'ngx-cookie-service';
import { BadgeService } from '../core/services/badge.service';
import { TranslationService } from '../core/shared/translation/translation.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NavigationEnd, Router } from '@angular/router';
import { LanguageService } from '../core/services/language/language.service';
import { LocalHistoryService } from '../core/services/local-history.service';
import { EnvironmentService } from '../core/services/environment.service';
import { SeoService } from '../core/services/seo.service';
import { GroupsService } from '../core/services/groups.service';
import { NotificationCenterService } from '../core/modules/notification-center/notification-center.service';
import { ZendeskChatService } from '../core/services/zendesk/zendesk-chat.service';
import { LocalstorageService } from '../core/services/localstorage.service';
import { WrSocketService } from '../core/services/wr-socket.service';
import { GlobalEventsService } from '../core/services/global-events.service';
import { InstallAppService } from '../core/services/install-app.service';
import { JackpotService } from './jackpot/jackpot.service';
import { StaticContentService } from '../core/services/static-content.service';
import { AbTestService } from '../core/services/ab-test.service';
import { ModalService } from '../core/modal-v2/modal.service';
import { ScrollService } from '../core/services/scroll.service';
import { WebSocketService } from '../core/services/web-socket.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { HalloweenService } from './promo/halloween-treasure-valley/halloween.service';
import { EXCLUDE_PUSH_PROMPT_PAGES } from '../core/services/one-signal.service';
import { XmasService } from './promo/xmas/xmas.service';
import { UserBonusesService } from '../core/services/user/user-bonuses.service';
import { LootboxService } from '../core/services/lootbox/lootbox.service';
import { TimeService } from '../core/services/time.service';

export const regAbTestGroup = 'ID417'; // exist if user reg after 21.11.2023

export const BONUS_API_IDS = {
  freespins: 'freespins_xmas',
  lootboxes: 'lootbox_xmas',
};

declare var OneSignal;

@UntilDestroy()
@Component({
  selector: 'app-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.scss']
})
export class PageComponent implements OnInit, OnDestroy {

  /**
   * Is to top button visible
   */
  public toTopVisible: boolean;

  @ViewChild('account', {read: ViewContainerRef, static: true}) account: ViewContainerRef;

  constructor(
    private _user: UserService,
    private _scroll: ScrollService,
    private _window: WindowService,
    private _platform: PlatformService,
    private _toastMessage: ToastMessageService,
    private _cookies: CookieService,
    private _badge: BadgeService,
    private _abTest: AbTestService,
    public translations: TranslationService,
    private _router: Router,
    private _lang: LanguageService,
    private _history: LocalHistoryService,
    private _env: EnvironmentService,
    private _seo: SeoService,
    private _zendesk: ZendeskChatService,
    private _storage: LocalstorageService,
    private _wrSocket: WrSocketService,
    private _events: GlobalEventsService,
    public installApp: InstallAppService,
    private _halloween: HalloweenService,
    private _christmas: XmasService,
    private _groups: GroupsService,
    private _notification: NotificationCenterService,
    private _static: StaticContentService,
    private _jackpot: JackpotService,
    private _modal: ModalService,
    private _ws: WebSocketService,
    private _device: DeviceDetectorService,
    private _bonuses: UserBonusesService,
    private _lootbox: LootboxService,
    private _time: TimeService,
  ) {
    this._ws.createConnection();
    this._restrictSomeRouteByGeo();
  }

  ngOnInit() {
    this._seo.updateGlobalHeadLink();
    this._actionsForAuthUser();
    this._actionsForNotAuthUser();
    this._handleScroll();
    if (!this._cookies.check('isCookiesAccepted')) {
      this.showCookiesMessage();
    }
    this._halloween.initSidebarMenuLink();
    this._halloween.initEvent();
    this._christmas.initSidebarMenuLink();
    if (this._platform.isBrowser) {
      this._christmas.initWelcomePromoModal();
    }
    this._checkAbTestAndInitJackpot();
    this._listenOnesignal();
    this._handleKeyboardIos();
    console.log(`Page comp inited`);
  }

  ngOnDestroy() {
  }

  /**
   * Load badges info
   */
  private _actionsForAuthUser() {
    this._user.auth$.pipe(
      filter(auth => !!auth),
      first()
    ).subscribe(() => {
      this._wrSocket.initWrSocket();
      this._badge.loadBadgeInfo();
      this._addUserToTestGroupAfterLogin();
      this._lootbox.loadUserLoobox().pipe(
        concatMap(() => {
          return combineLatest([
            this._bonuses.freeSpinsList(),
            this._lootbox.lootboxList$]);
        }),
        map(([fs, lootbox]) => {
          const stage = this._bonuses.Stage;
          const type = this._bonuses.Type;
          return {
            [stage.XMAS_FS]: fs.filter(
              (e) => (e.stage === stage.ACTIVATED || e.stage === stage.ISSUED) && e.type === type.FREE_SPINS && e.title.trim() === BONUS_API_IDS.freespins &&
                this._time.toLocalDate().setHours(0, 0, 0, 0) === e.created_at.setHours(0, 0, 0, 0)
            ),
            [stage.XMAS_LOOTBOX]: lootbox.filter(
              (e) => (e.stage === stage.ACTIVATED || e.stage === stage.ISSUED) && e.type === type.LOOTBOX && e.lootboxTitle.trim() === BONUS_API_IDS.lootboxes &&
                this._time.toLocalDate().setHours(0, 0, 0, 0) === e.created_at.setHours(0, 0, 0, 0)
            )
          }
        }),
        tap(list => {
          this._bonuses.xmasActivatedBonusesFs$.next(list[this._bonuses.Stage.XMAS_FS])
          this._bonuses.xmasActivatedBonusesLootbox$.next(list[this._bonuses.Stage.XMAS_LOOTBOX])
        }),
      ).subscribe();
      this._events.isLeaveBrowserTab$.subscribe((isLeave: boolean) => {
        isLeave ? this._wrSocket.sendEventMouseout() : this._wrSocket.sendEventMousein();
      });
      this._zendesk.initChat();
      this._storage.set('user_id', this._user.info.id);
      this._checkIfCryptoAddressRegenerated();
      this._addGroupAfterRegByAbTest();
    });
  }

  /**
   * Actions for not auth user
   * @private
   */
  private _actionsForNotAuthUser() {
    this._user.auth$.pipe(
      filter(auth => !auth),
      first()
    ).subscribe(() => {
      this._zendesk.initChat();
    });
  }

  /**
   * To top button click handler
   */
  onToTopClick() {
    requestAnimationFrame(() => this._scroll.scrollToElement(this._scroll.documentElement));
  }

  /**
   * Window scroll handler
   *
   * @private
   */
  private _handleScroll() {
    if (this._platform.isBrowser) {
      this._events.scroll$.pipe(
        untilDestroyed(this),
        debounceTime(150),
      ).subscribe(() => {
        this.toTopVisible = this._window.nativeWindow.pageYOffset > 300;
      });
    }
  }

  /*
   * Show message until user accepts cookies
   */
  showCookiesMessage() {
    if (this._platform.isBrowser) {
      const message = this._toastMessage.warning(CookieMessageContentComponent, null, true, false);
      message.callback = () => {
        requestAnimationFrame(() => {
          if (!this._cookies.check('isCookiesAccepted')) {
            this.showCookiesMessage();
          }
        });
      };
    }
  }

  private _addUserToTestGroupAfterLogin() {
    this._abTest.abTestDEVWFC1406$.pipe(filter(test => !!test), first()).subscribe(
      () => this._abTest.addUserToABTestGroup(this._abTest.getAbTestDEVWFC1406(), '_abTestDEVWFC1406')
    );

    this._abTest.abTestDEVWFC1713$.pipe(filter(test => !!test), first()).subscribe(
      () => this._abTest.addUserToABTestGroup(this._abTest.getAbTestDEVWFC1713(), '_abTestDEVWFC1713')
    );

    this._abTest.abTestDEVWFC1859$.pipe(filter(test => !!test), first()).subscribe(
      () => this._abTest.addUserToABTestGroup(this._abTest.getAbTestDEVWFC1859(), '_abTestDEVWFC1859')
    );
  }

  /**
   * Restrict some route by geo
   *
   * @private
   */
  private _restrictSomeRouteByGeo() {
    this._router.events.pipe(
      untilDestroyed(this),
      filter(event => event instanceof NavigationEnd),
      tap(event => {
        if (this._router.url === `/${this._lang.current}/bonuses` && this._env.isSe) {
          this._history.skipHistoryNavigate('/404');
        }
      })
    ).subscribe();
  }


  /**
   * If crypto address regenerated show notification
   * @private
   */
  private _checkIfCryptoAddressRegenerated() {
    if (this._groups.isExistGroup(REGENERATED_CRYPTO_GROUP) && !this._cookies.check(REGENERATED_CRYPTO_COOKIE)) {
      this._notification.regeneratedCryptoAddressChannel$.next();
    }
  }

  /**
   * Init jackpot
   * @private
   */
  private _initJackpot() {
    this._jackpot.initJackpot('available-jackpot').subscribe();
  }


  /**
   * Listen jackpot winner and open modal
   * @private
   */
  private _listenJackpotWinnerAndOpenModal() {
    this._jackpot.wsWinnerJackpot$.pipe(
      tap(async (jackpot) => {
        const component = await import('../core/modal-v2/components/lazy/jackpot-winning/jackpot-winning.component');
        this._modal.openLazy(component?.JackpotWinningComponent, {
          template: 'CLEAR',
          data: {jackpot}
        });
      })
    ).subscribe();
  }

  /**
   * Check ab test and init jackpot
   * @private
   */
  private _checkAbTestAndInitJackpot() {
    this._abTest.abTestDEVWFC1406$.pipe(
      filter(abTest => abTest === this._abTest.abTestId.DEVWFC1406_A),
      tap(() => {
        this._initJackpot();
        this._listenJackpotWinnerAndOpenModal();
      })
    ).subscribe();
  }

  private _handleKeyboardIos() {
    if (this._platform.isBrowser && this._device.getDeviceInfo().os.toLowerCase() === 'ios') {
      this._events.routerNavigationStart$.pipe(
        untilDestroyed(this),
        tap((data) => {
          const input: any = document.querySelector('.ios-show-keyboard');
          if (!this._user.auth && data.url.includes('deposit') || data.url.includes('register')) {
            input.focus();
          }
        })
      ).subscribe()
    }
  }

  /**
   * To show OneSignal prompt popup on specific pages
   */
  private _listenOnesignal() {
    this._events.routerNavigationEnd$.pipe(
      distinctUntilChanged(),
      takeWhile(() => !OneSignal?.User?._currentUser?.hasOneSignalId, true),
      filter((data) => EXCLUDE_PUSH_PROMPT_PAGES.every(page => !data?.url?.includes(page))),
      delay(1000),
      tap(() => OneSignal.Slidedown.promptPush()),
      tap(() => this._cookies.set('user_push_subscribe', `${!!(OneSignal?.User?._currentUser?.hasOneSignalId && OneSignal?.User?.PushSubscription?.optedIn)}`, 999, '/'))
    ).subscribe();
  }

  /**
   * Add user to ab test group if reg after 21.11.2023
   * @private
   */
  private _addGroupAfterRegByAbTest() {
    of(true).pipe(
      delay(3000),
      filter(() => this._groups.isExistGroup(regAbTestGroup)),
      switchMap(() => this._abTest.checkExistABTest$(this._abTest.getAbTestDEVWFC1859, false, 'ID415', '_abTestDEVWFC1859'))
    ).subscribe();
  }
}
