<header class="header" [ngStyle]="{backgroundColor: changedColor}">
  <div class="container">
    <div class="content-container">
      <div class="left">
        <div class="menu">
          <img src="../../../../../assets/svg/burger.svg" alt="" (click)="sidenav.toggle()">
          <div class="notify">
            <app-notification-center-drop-down *appShellNoSSR></app-notification-center-drop-down>
          </div>
        </div>
      </div>
      <div class="center">
        <a [href]="seo.originUrl + '/' + lang.current" class="logo" title="Wildfotune">
          <img *ngIf="env.isSe || !xmas.isPromoLogo" src="/assets/svg/logo.svg" alt="Wildfortune logo">
          <img *ngIf="!env.isSe && xmas.isPromoLogo" src="/assets/img/promo/xmas/logo.svg" alt="">
          <span>Wildfotune</span>
        </a>
      </div>
      <div class="right">
        <ng-container>
          <app-header-authorized *ngIf="user.auth"></app-header-authorized>
          <app-header-not-authorized *ngIf="!user.auth"></app-header-not-authorized>
        </ng-container>
      </div>
    </div>
  </div>
</header>
