import {SsWebSocketsPrivateChanel} from '../../vendor/ss-web-sockets/ss-web-sockets.types';

export enum CustomNotificationChanel {
  INSTALL_APP = 'install_app',
  REGENERATED_CRYPTO_ADDRESS = 'regenerated_crypto_address',
  XMAS_NOTIFY = 'xmas_notify',
}

const additionalSocketsFields = {
  [SsWebSocketsPrivateChanel.FREESPINS_CHANGED]: {
    templateTitle: 't.free-spins',
    icon: '/assets/img/notification/freespins.svg',
    link: '/account/user'
  },
  [SsWebSocketsPrivateChanel.GAME_LIMITS]: {
    templateTitle: 't.game-limits',
    icon: '/assets/img/notification/game-limits.svg',
    link: '/'
  },
  [SsWebSocketsPrivateChanel.BONUSES_CHANGED]: {
    templateTitle: 't.bonuses',
    icon: '/assets/img/notification/bonuses.svg',
    link: '/account/user'
  },
  [SsWebSocketsPrivateChanel.PAYMENTS_CHANGED]: {
    templateTitle: 't.payments',
    icon: '/assets/img/notification/payments.svg',
    link: '/account/history/transactions'
  },
  [SsWebSocketsPrivateChanel.LOOTBOXES_CHANGED]: {
    templateTitle: 't.lootbox',
    icon: '/assets/img/notification/lootboxes.svg',
    link: '/account/user'
  },
  [SsWebSocketsPrivateChanel.TOURNAMENTS_STATUSES]: {
    templateTitle: 't.tournaments',
    icon: '/assets/img/notification/tournaments.svg'
  },
  [SsWebSocketsPrivateChanel.TOURNAMENTS_STARTED]: {
    templateTitle: 't.tournaments',
    icon: '/assets/img/notification/tournaments.svg'
  },
  [SsWebSocketsPrivateChanel.GROUPS_UPDATES]: {
    templateTitle: 't.next-level',
    icon: '/assets/img/notification/groups.svg',
    link: '/loyalty-program'
  },
  [CustomNotificationChanel.INSTALL_APP]: {
    templateTitle: 't.install-app',
    icon: '/assets/img/notification/app.svg',
    link: '/mobile-app'
  },
  [CustomNotificationChanel.REGENERATED_CRYPTO_ADDRESS]: {
    templateTitle: 't.important-crypto-updated',
    icon: '/assets/img/notification/payments.svg',
    link: '/deposit',
  }
};

export const additionalLootboxFields = {
  spinder_bonus_landing: {
    link: '/promo/valentine',
    description: 't.notify-spinder-lootbox-desc'
  }
};

export default additionalSocketsFields;
