import { Inject, Injectable } from '@angular/core';
import { LIB_SRC, SsPaymentConfig } from './ss-payments-config';
import { Observable, ReplaySubject } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { SsPaymentsRequester } from './ss-payments-requester';
import { fromPromise } from 'rxjs/internal-compatibility';
import { first, map, switchMap } from 'rxjs/operators';
import { CustomRoutes, PaymentAction, PaymentMethod, SsPaymentsModuleConfig } from './ss-payments-types';
import {PlatformService} from '../../services/platform.service';
import {CookieService} from 'ngx-cookie-service';
import {devDomain} from '../../../../config/dev.domain';

declare var PaymentsAPI;
const biggerLogos = [
  'mifinity_sepa', 'volt_bankuberweisung', 'uberweisungen', 'mifinity', 'm_giropay', 'jsofort_deu',
  'jgiropay_deu', 'jtrustly_deu', 'interac_finteqhub', 'jinterac_can'
];

@Injectable()
export class SsPaymentsService {

  /**
   * List of app hosts from which request urls should not be modified
   *
   * @private
   */
  private _ignoredHosts = [
    'https://localhost:4200',
    'https://stage.wildfortune.io',
    'https://dev.wildfortune.io',
    'https://wildfortune.io',
    ...devDomain
  ];
  
  /**
   * Methods which have own image on our side
   */
  private readonly _methodsWithOwnImage = ['interac_finteqhub'];
  
  
  /**
   * Is ready to use source
   */
  private _ready$: ReplaySubject<boolean> = new ReplaySubject(1);

  /**
   * Custom requester for Ss Payments API
   */
  private _requester = new SsPaymentsRequester(this._resolveHost());

  /**
   * Is library ready to use
   */
  public ready$: Observable<boolean> = this._ready$.asObservable();

  constructor(
    @Inject(SsPaymentConfig)
    private _config: SsPaymentsModuleConfig,
    @Inject(DOCUMENT)
    private _document: Document,
    private _platform: PlatformService,
    private _cookie: CookieService
  ) {
    this.loadApiLibrary();
  }

  /**
   * Load api library script
   */
  loadApiLibrary() {
    const script: HTMLScriptElement = this._document.createElement('script');
    script.onload = (e: Event) => requestAnimationFrame(() => {
      this._ready$.next(true);
    });
    script.src = this._resolveHost() + LIB_SRC;

    this._document.head.appendChild(script);
  }

  /**
   * Fetch payment methods list
   *
   * @param currency
   */
  fetchMethods(currency: string): Observable<any[]> {
    return this.ready$.pipe(
      first(),
      switchMap(() => fromPromise(PaymentsAPI.fetchPaymentMethods(currency, this._requester))),
      map((list: any[]) => list.filter(method => (PaymentsAPI.SUPPORTED_PROVIDERS || []).includes(method.provider))),
      map((list: any[]) => list.map(method => ({
        ...method,
        img: this._resolveImage(method),
        isBiggerLogo: biggerLogos.some(brand => method.brand.includes(brand))
      })))
    );
  }



  /**
   * Build object that contain information to render payment form and additional information.
   *
   * @param currency
   * @param action
   * @param method
   * @param channelIdentifier
   */
  buildPaymentPlan(currency: string, action: PaymentAction, method: any, channelIdentifier: string = null): Observable<any> {
    return this.ready$.pipe(
      first(),
      switchMap(() => fromPromise(PaymentsAPI.buildPaymentPlan(currency, action, method, channelIdentifier, this._requester)))
    );
  }

  /**
   * Tells whether given paymentMethod is enabled for the action or not.
   *
   * @param action
   * @param method
   */
  isPaymentMethodEnabled(action: PaymentAction, method): boolean {
    return PaymentsAPI.isPaymentMethodEnabled(action, method);
  }

  /**
   * Tells whether given paymentMethod has saved methods for the action or not.
   *
   * @param action
   * @param method
   */
  savedChannels(action: PaymentAction, method): Array<any> {
    return PaymentsAPI.savedChannels(action, method);
  }

  /**
   * Tells whether user can process new payments through this payment method.
   *
   * @param action
   * @param method
   */
  isNewPaymentEnabled(action: PaymentAction, method): boolean {
    return PaymentsAPI.isNewPaymentEnabled(action, method);
  }

  /**
   * Return list of saved accounts for provided payment method
   *
   * @param action
   * @param method
   */
  savedAccounts(action: PaymentAction, method): Array<any> {
    return (method[action + '_saved_methods'] || []).map(savedMethod => ({
      ...savedMethod,
      id: method.id,
    }));
  }

  /**
   * Submit payment form.
   *
   * @param plan
   * @param formData
   * @param customRoutes
   */
  submitForm(plan, formData, customRoutes: CustomRoutes = {}) {
    return this.ready$.pipe(
      first(),
      switchMap(() => fromPromise(PaymentsAPI.submitForm(plan, formData, this._requester, customRoutes)))
    );
  }

  /**
   * Returns payment method logo URL
   *
   * @param method
   * @private
   */
  private _resolveImage(method: PaymentMethod): string {
    return this._methodsWithOwnImage.includes(method.brand) ?
      `/assets/svg/payment-methods/color/${method.brand}.svg`
      :  method.type === 'crypto'
        ? `https://cdn2.softswiss.net/logos/payments/color/${method.currency}_${method.brand}.svg`
        : `https://cdn2.softswiss.net/logos/payments/color/${method.provider}-${method.brand}.svg`;

  }


  private _resolveHost() {
    return this._platform.isBrowser &&
    !this._ignoredHosts.some(item => item.includes(window.location.host)) ?
      window.location.origin.replace('https://www.', 'https://').replace('https://stage.', 'https://') :
      this._config.host;
  }
}
