<ng-container *ngFor="let message of messagesList">
  <div class="toast-message-container" [ngClass]="{'clear-toast-styles': message.hasComponent}">
    <app-toast-message-item [@showHide]  class="{{ message.type }}" [ngClass]="{'clear-toast-styles': message.hasComponent}" (click)="onMessageClick(message)">
      <ng-container *ngIf="message.hasComponent">
        <ng-container *ngComponentOutlet="message.content"></ng-container>
      </ng-container>
  
      <ng-container *ngIf="!message.hasComponent">{{ message.content }}</ng-container>
    </app-toast-message-item>
  </div>

