import { NgModule } from '@angular/core';
import { CommonModule, DecimalPipe } from '@angular/common';
import { SafePipe } from './safe.pipe';
import { CurrencyFormatPipe } from './currency-format.pipe';
import {ReplacePipe} from './replace.pipe';
import { ProcessingTimePipe } from './processing-time.pipe';
import { PlacePipe } from './place.pipe';
import {DisplayCurrencyPipe} from './display-currency.pipe';
import {IgnoreHtmlTagsPipe} from './ignore-html-tags.pipe';

@NgModule({
  declarations: [
    SafePipe,
    CurrencyFormatPipe,
    ReplacePipe,
    ProcessingTimePipe,
    PlacePipe,
    DisplayCurrencyPipe,
    IgnoreHtmlTagsPipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    SafePipe,
    CurrencyFormatPipe,
    ReplacePipe,
    ProcessingTimePipe,
    PlacePipe,
    DisplayCurrencyPipe,
    IgnoreHtmlTagsPipe
  ],
  providers: [
    DecimalPipe,
  ]
})
export class PipesModule {
}
