import { Injectable } from '@angular/core';
import { ReplaySubject, Observable } from 'rxjs';
import { CommonDataService } from '../common-data.service';
import { tap } from 'rxjs/operators';
import { UserService } from './user.service';
import { SsPaymentsService } from '../../vendor/ss-payments/ss-payments.service';
import { PaymentMethod } from '../../vendor/ss-payments/ss-payments-types';
import {EnvironmentService} from '../environment.service';
import {filter, map} from 'rxjs/operators';
import {StaticContentService} from '../static-content.service';
import {UserInfoService} from './user-info.service';
import {CookieService} from 'ngx-cookie-service';
import {PlatformService} from '../platform.service';
import {ModalService} from '../../modal-v2/modal.service';

const IS_USER_HAS_DEPOSIT_COOKIE = '--is-user-has-deposit-cookie';

@Injectable({
  providedIn: 'root'
})
export class UserPaymentService {

  /**
   * Payment methods list source
   */
  private _paymentMethodsList: ReplaySubject<PaymentMethod[]> = new ReplaySubject(1);

  /**
   * Protected access to payment method list source
   */
  public paymentMethodsList$: Observable<PaymentMethod[]> = this._paymentMethodsList.asObservable();

  /**
   * Limit payment accounts list
   * @private
   */
  private _paymentLimitAccountsList: {[key: string]: string[]};

  /**
   * Checked limit account field
   * @private
   */
  private _checkedLimitField: string;

  constructor(
    private _user: UserService,
    private _ssPayments: SsPaymentsService,
    private _data: CommonDataService,
    private _env: EnvironmentService,
    private _static: StaticContentService,
    private _userInfo: UserInfoService,
    private _modal: ModalService,
    private _cookie: CookieService,
    private _platform: PlatformService
  ) {
    this._handleUserCurrencyChange();
  }

  get isUserHasSuccessDep() {
    if (this._platform.isBrowser) {
      return Boolean(Number(this._cookie.get(IS_USER_HAS_DEPOSIT_COOKIE)));
    }
  }

  set isUserHasSuccessDep(value) {
    if (this._platform.isBrowser) {
      this._cookie.set(IS_USER_HAS_DEPOSIT_COOKIE, '1', 999, '/', (window.location.hostname as any));
    }
  }

  /**
   * Update current payment methods list
   *
   * @param currency
   * @private
   */
  private _updatePaymentMethodsList(currency: string): void {
    if (!currency) {
      this._paymentMethodsList.next(null);
      return;
    }

    this._ssPayments.fetchMethods(currency).pipe(
      map(list => list.map(brand => ({
        ...brand,
        img: brand.brand === 'trustly' ? '/assets/svg/payment-methods/color/trustly-new.svg' : brand.img
      }))),
      tap(list => this._paymentMethodsList.next(list)),
    ).subscribe();
  }

  /**
   * Update payment methods list on user currency change
   *
   * @private
   */
  private _handleUserCurrencyChange(): void {
    this._user.currency$.pipe(
      tap(currency => {
        this._updatePaymentMethodsList(currency);
        this._getLimitAccountsList();
      })
    ).subscribe();
  }

  /**
   * Get limit accounts for payment
   *
   * @param payment
   */
  public getLimitAccountsForPayment(payment: PaymentMethod): number {
    if (this._paymentLimitAccountsList && this._checkedLimitField) {
      const limit = Object.entries(this._paymentLimitAccountsList).find(l => l[1].some(brands => brands.includes(payment[this._checkedLimitField])));
      return limit ? +limit[0] : null;
    } else {
      return null;
    }
  }

  /**
   * Get limit payment accounts list
   * @private
   */
  private _getLimitAccountsList() {
    this._static.item({slug: 'payment-account-list'}).pipe(
      filter(response => response && response[0]),
      map(response => response[0]),
      tap(response => {
        this._checkedLimitField = response.checkedField;
        let limitList: {[key: string]: string[]};

        if (response.limitList && response.limitList.limitList) {
          limitList = {};
          Object.entries(response.limitList.limitList).forEach((limitArray: [string, string]) => {
            if (!limitList[limitArray[1]]) {limitList[limitArray[1]] = [];}
            limitList[limitArray[1]].push(limitArray[0]);
          });

          this._paymentLimitAccountsList = limitList;
        }
      })
    ).subscribe();
  }

  /**
   * Check fiat payment and call modal with required fields
   */
  public checkFiatPayment(context: string): Observable<any> {
    return this._userInfo.missingAttributesFor(context).pipe(
      tap((fields: any) => {
        if (fields.length) {
          this._modal.open('AUTH_FIELD_MISSED_UPDATE', {
            data: {
              context
            },
            template: 'NO_CLOSE',
            disableOverlayClosing: true,
            closeAfterRouteChange: true,
          });
        }
      })
    );
  }
}
