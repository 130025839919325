/**
 * Available limit types
 */
export enum LimitType {
  LOSS = 'loss',
  WAGER = 'wager',
  DEPOSIT = 'deposit',
  COOLINGOFF = 'coolingoff',
  SELFEXCLUSION = 'selfexclusion',
  SESSION = 'session',
  REALITYCHECK = 'reality_check'
}

/**
 * Available limit statuses
 */
export enum LimitStatus {
  UNCONFIRMED_DISABLE = 'unconfirmed_disable',
  CONFIRMED_DISABLE = 'confirmed_disable',
  ACTIVE = 'active',
  UNCONFIRMED = 'unconfirmed',
  CONFIRMED = 'confirmed',
  CONFIRMED_DISABLED_NO_DATE = 'confirmed_disable_no_date', // this uses only for frontend
}

/**
 * Limit period parameter values
 */
export enum LimitPeriod {
  DAY = 'day',
  DAY_3 = 'day_3',
  WEEK = 'week',
  MONTH = 'month',
  MONTH_3 = 'month_3',
  MONTH_6 = 'month_6',
  MONTH_9 = 'month_9',
  MONTH_12 = 'month_12',
  FOREVER = 'forever'
}

/**
 * Status messages according by limit status
 */
export const StatusMessageList: any = {
  [LimitStatus.UNCONFIRMED_DISABLE]: 't.disable-unconf',
  [LimitStatus.CONFIRMED_DISABLE]: 't.confirmed-disable',
  [LimitStatus.CONFIRMED_DISABLED_NO_DATE]: 't.confirmed-disable-no-date',
  [LimitStatus.ACTIVE]: 't.active',
  [LimitStatus.UNCONFIRMED]: 't.unconfirmed',
  [LimitStatus.CONFIRMED]: 't.confirmed'
};


/**
 * Available period params
 */
export const PeriodMessageList: any = {
  [LimitPeriod.DAY]: 't.day_1',
  [LimitPeriod.DAY_3]: 't.day_3',
  [LimitPeriod.WEEK]: 't.week_1',
  [LimitPeriod.MONTH]: 't.month_1',
  [LimitPeriod.MONTH_3]: 't.month_3',
  [LimitPeriod.MONTH_6]: 't.month_6',
  [LimitPeriod.MONTH_9]: 't.month_9',
  [LimitPeriod.MONTH_12]: 't.year_1',
  [LimitPeriod.FOREVER]: 't.forever'
};

/**
 * Data arrays to pass in modal windows
 */
export const LimitModalData: any = {
  [LimitType.DEPOSIT]: {
    title: 't.create-dep-limit',
    title_edit: 't.edit-dep-limit',
    type: LimitType.DEPOSIT,
    description: 't.popup-dep-limit-description'
  },
  [LimitType.WAGER]: {
    title: 't.create-wager-limit',
    title_edit: 't.edit-wager-limit',
    type: LimitType.WAGER,
    description: 't.popup-wager-limit-desc'
  },
  [LimitType.LOSS]: {
    title: 't.create-loss-limit',
    title_edit: 't.edit-loss-limit',
    type: LimitType.LOSS,
    description: 't.popup-loss-limit-desc'
  },
};

/**
 * Data for limit accepting modal
 */
export const EditSessionLimitsModalData: any = {
  [LimitType.SESSION]: {
    title: 't.edit-session-limit',
    description: 't.popup-session-limit-descr'
  },
  [LimitType.COOLINGOFF]: {
    title: 't.edit-coolingoff-limit',
    description: 't.cooling-off-description'
  }
};

/**
 * Data for limit accepting modal
 */
export const AcceptLimitModalData: any = {
  [LimitType.COOLINGOFF]: {
    title: 't.cooling-off-confirmation',
    description: 't.cooling-off-confirmation-desk'
  },
  [LimitType.SELFEXCLUSION]: {
    title: 't.selfexcl-confirmation',
    description: 't.selfexcl-confirmation-desk'
  }
};

/**
 * Values list for COOLINGOFF limit period selectbox
 */
export const CoolingOffPeriodValues: Array<any> = [
  {value: LimitPeriod.DAY, message: resolvePeriodMessage(LimitPeriod.DAY)},
  {value: LimitPeriod.DAY_3, message: resolvePeriodMessage(LimitPeriod.DAY_3)},
  {value: LimitPeriod.WEEK, message: resolvePeriodMessage(LimitPeriod.WEEK)},
  {value: LimitPeriod.MONTH, message: resolvePeriodMessage(LimitPeriod.MONTH)},
  {value: LimitPeriod.MONTH_6, message: resolvePeriodMessage(LimitPeriod.MONTH_6)},
];

/**
 * Values list for SELFEXCLUSION limit period selectbox
 */
export const SelfExclusionPeriodValues: Array<any> = [
  {value: LimitPeriod.MONTH_6, message: resolvePeriodMessage(LimitPeriod.MONTH_6)},
  {value: LimitPeriod.MONTH_9, message: resolvePeriodMessage(LimitPeriod.MONTH_9)},
  {value: LimitPeriod.MONTH_12, message: resolvePeriodMessage(LimitPeriod.MONTH_12)},
  // {value: LimitPeriod.FOREVER, message: resolvePeriodMessage(LimitPeriod.FOREVER)},
];

/**
 * Values list for REALITYCHECK limit period selectbox
 */
export const RealityCheckPeriodValues: Array<any> = [
  {value: 15, message: resolvePeriodMessage(15)},
  {value: 30, message: resolvePeriodMessage(30)},
  {value: 45, message: resolvePeriodMessage(45)},
  {value: 60, message: resolvePeriodMessage(60)},
];

/**
 * Returns correct period message for current period
 *
 * @param period
 */
export function resolvePeriodMessage(period): string {
  return PeriodMessageList[period] ?
    PeriodMessageList[period] :
    '#period# minutes';
}

/**
 * Default values for money limits
 */
export const MoneyLimitDefaultValues = {
  [LimitType.DEPOSIT]: { // Deposit limit default values
    [LimitPeriod.DAY]: {
      EUR: 10000,
      NOK: 100000,
      USD: 10000,
      AUD: 10000,
      CAD: 15000,
      NZD: 15000,
      PLN: 40000,
      ZAR: 150000,
      JPY: 1165000,
      HUF: 3500000,
      BTC: 1.3,
      BCH: 47,
      ETH: 69,
      LTC: 250,
      DOG: 4700000,
      USDT: 10000,
    },
    [LimitPeriod.WEEK]: {
      EUR: 70000,
      NOK: 700000,
      USD: 70000,
      AUD: 70000,
      CAD: 105000,
      NZD: 105000,
      PLN: 280000,
      ZAR: 1050000,
      JPY: 8160000,
      HUF: 24550000,
      BTC: 9,
      BCH: 325,
      ETH: 400,
      LTC: 1800,
      DOG: 33000000,
      USDT: 70000,
    },
    [LimitPeriod.MONTH]: {
      EUR: 300000,
      NOK: 3000000,
      USD: 300000,
      AUD: 300000,
      CAD: 450000,
      NZD: 450000,
      PLN: 1200000,
      ZAR: 4500000,
      JPY: 34990000,
      HUF: 105180000,
      BTC: 38,
      BCH: 1400,
      ETH: 1700,
      LTC: 7700,
      DOG: 140400000,
      USDT: 300000,
    }
  },
  [LimitType.WAGER]: { // Wager limit default values
    [LimitPeriod.DAY]: {
      EUR: 100000,
      NOK: 1000000,
      USD: 100000,
      AUD: 100000,
      CAD: 150000,
      NZD: 150000,
      PLN: 400000,
      ZAR: 1500000,
      JPY: 11660000,
      HUF: 35060000,
      BTC: 12.5,
      BCH: 461,
      ETH: 570,
      LTC: 2600,
      DOG: 45000000,
      USDT: 100000,
    },
    [LimitPeriod.WEEK]: {
      EUR: 700000,
      NOK: 7000000,
      USD: 700000,
      AUD: 700000,
      CAD: 1050000,
      NZD: 1050000,
      PLN: 2800000,
      ZAR: 10500000,
      JPY: 81640000,
      HUF: 245300000,
      BTC: 87,
      BCH: 3300,
      ETH: 4000,
      LTC: 18000,
      DOG: 310000000,
      USDT: 700000,
    },
    [LimitPeriod.MONTH]: {
      EUR: 3000000,
      NOK: 30000000,
      USD: 3000000,
      AUD: 3000000,
      CAD: 4500000,
      NZD: 4500000,
      PLN: 12000000,
      ZAR: 45000000,
      JPY: 350020000,
      HUF: 1051810000,
      BTC: 370,
      BCH: 13900,
      ETH: 17000,
      LTC: 76600,
      DOG: 1400000,
      USDT: 3000000,
    }
  },
  [LimitType.LOSS]: { // Loss limit default values
    [LimitPeriod.DAY]: {
      EUR: 10000,
      NOK: 100000,
      USD: 10000,
      AUD: 10000,
      CAD: 15000,
      NZD: 15000,
      PLN: 40000,
      ZAR: 150000,
      JPY: 1160000,
      HUF: 3500000,
      BTC: 1.3,
      BCH: 47,
      ETH: 69,
      LTC: 250,
      DOG: 4700000,
      USDT: 10000,
    },
    [LimitPeriod.WEEK]: {
      EUR: 70000,
      NOK: 700000,
      USD: 70000,
      AUD: 70000,
      CAD: 105000,
      NZD: 105000,
      PLN: 280000,
      ZAR: 1050000,
      JPY: 8160000,
      HUF: 24550000,
      BTC: 9,
      BCH: 325,
      ETH: 400,
      LTC: 1800,
      DOG: 33000000,
      USDT: 70000,
    },
    [LimitPeriod.MONTH]: {
      EUR: 300000,
      NOK: 3000000,
      USD: 300000,
      AUD: 300000,
      CAD: 450000,
      NZD: 450000,
      PLN: 1200000,
      ZAR: 4500000,
      JPY: 34990000,
      HUF: 105180000,
      BTC: 38,
      BCH: 1400,
      ETH: 1700,
      LTC: 7700,
      DOG: 140400000,
      USDT: 300000,
    }
  }
};

/**
 * Uses for check current amount balance after confirmation and redirect to some url
 */
export const CurrencyLimitForRedirectConfirmation = {
  EUR: 10,
  NOK: 100,
  USD: 10,
  AUD: 15,
  CAD: 15,
  NZD: 15,
  PLN: 45,
  ZAR: 155,
  JPY: 1300,
  HUF: 3650,
  BTC: 0.0003,
  BCH: 0.0531,
  ETH: 0.0053,
  LTC: 0.1605,
  DOG: 120,
  USDT: 10
};
