<div class="dropdown" ClickOutside (clickOutside)="notify.close()">
  <ng-container *ngIf="notify.notifications$ | async as notifications">
    <div class="dropdown__header" (click)="notify?.toggle()">
      <img class="bell" [ngClass]="{'bell--animate': notify.animateBell}" src="/assets/img/notification/bell.svg" alt="">

      <span class="dropdown__count" [ngClass]="{'dropdown__count--active': notifications.length}">
        {{notifications.length || 0}}
      </span>
    </div>
    <div class="dropdown-menu">
      <app-notification-center [lists]="notifications"></app-notification-center>
    </div>
  </ng-container>
</div>
