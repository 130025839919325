<div class="form-control" [ngClass]="{invalid: control?.invalid && (!messages.length) , touched: control?.touched && (!messages.length), filled: control?.value}">
  <div class="form-control__input">
    <ng-content></ng-content>
    <span class="form-control__label" *ngIf="label">{{ label }}</span>
  </div>
  <div class="form-control__error-line"></div>

  <ng-container *ngIf="bottomPlaceholder">
    <div class="form-control__placeholder">{{ bottomPlaceholder }}</div>
  </ng-container>
  <ng-container *ngIf="!control || (control && control.touched) || (control && !control.pristine && validationMessages.length)">
    <ng-container *ngIf="errors && errors.length && (!messages.length)">
      <div class="form-control__error" >
        <span
          *ngFor="let error of errors; let last = last">{{ error.error | translate : error.attributes }}{{
          error.length > 1 && !last ? ',' : '' }} </span>
      </div>
    </ng-container>

    <ng-container *ngIf="messages.length || (validationMessages.length && control.valid)">
      <div class="form-control__validation-message" *ngFor="let message of (messages.length ? messages : validationMessages)">
        <i *ngIf="control.invalid" class="icon-ellipse"></i>
        <i *ngIf="control.valid" class="icon-check-validation"></i>
        <span>{{ message | translate }}</span>
      </div>
    </ng-container>
  </ng-container>
</div>
