import { ServiceWorkerService } from './core/services/service-worker.service';
import { Component } from '@angular/core';
import { DataPreloaderService } from './core/services/data-preloader.service';
import {OneSignalService} from './core/services/one-signal.service';
import {GlobalEventsService} from './core/services/global-events.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Wild Fortune';

  constructor(
    public loader: DataPreloaderService,
    private _oneSignal: OneSignalService,
    private _swRegister: ServiceWorkerService,
    private _events: GlobalEventsService
  ) {
    this._events.initGlobalEvent();
  }

}
