import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SsPaymentConfig } from './ss-payments-config';
import { SsPaymentsService } from './ss-payments.service';
import { SsPaymentsModuleConfig } from './ss-payments-types';

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [
    SsPaymentsService
  ]
})
export class SsPaymentsModule {
  static forRoot(config: SsPaymentsModuleConfig): ModuleWithProviders<SsPaymentsModule> {
    return {
      ngModule: SsPaymentsModule,
      providers: [
        {
          provide: SsPaymentConfig,
          useValue: config
        }
      ]
    };
  }
}
