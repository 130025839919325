import { Component, OnInit } from '@angular/core';
import {BaseTimerComponent} from '../../../helpers/base-timer.component';
import {TimeService} from '../../../services/time.service';

@Component({
  selector: 'app-countdown-timer',
  templateUrl: './countdown-timer.component.html',
  styleUrls: ['./countdown-timer.component.scss']
})
export class CountdownTimerComponent extends BaseTimerComponent implements OnInit {

  constructor(
    private _time: TimeService
  ) {
    super();
  }

  ngOnInit(): void {
    this.resolveTimer();
  }

}
