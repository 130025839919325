import { Injectable, Inject } from '@angular/core';
import { Meta, Title} from '@angular/platform-browser';
import { Location, DOCUMENT } from '@angular/common';
import { PlatformService } from './platform.service';
import { environment } from '../../../environments/environment';
import { EnvironmentService } from './environment.service';
import { switchMap, tap } from 'rxjs/operators';
import {GlobalEventsService} from './global-events.service';
import {WrSocketService} from './wr-socket.service';

@Injectable({
  providedIn: 'root'
})
export class SeoService {

  private _supportedLocales = ['fi', 'en', 'de', 'fi', 'fr', 'pl', 'no', 'en-ca', 'en-nz', 'fr-ca', 'en-au'];
  private _defaultLocale = 'en';

  constructor(
    private _meta: Meta,
    private _title: Title,
    private _platform: PlatformService,
    private _location: Location,
    private _env: EnvironmentService,
    private _globalEvents: GlobalEventsService,
    private _wrSocket: WrSocketService,
    @Inject(DOCUMENT) private _document: Document
  ) {
  }


  /**
   * Set in head Title & description
   * @param tags
   */
  public setMetaTags(tags: any) {
    this._title.setTitle(tags.MetaTitle || 'Wild Fortune');
    this._meta.updateTag({name: 'description', content: tags.MetaDesc || ''});

    if ('NoIndex' in tags && tags.NoIndex) {
      this.addMetaRobotNoIndex();
    }
  }

  /**
   * Add new alternate/canonical links and remove old links
   */
  public updateGlobalHeadLink() {
    this._globalEvents.routerNavigationEnd$.pipe(
      tap(() => this._wrSocket.sendEventPageOpen()),
      switchMap(() => this._env.env$),
      tap(({data}) => {
        this._supportedLocales = data.languageList ? data.languageList.map(lang => lang.short)
          : this._supportedLocales;
        this._removeOldLinks(['alternate', 'canonical', 'x-default']);
        this._addHeadLinkAlternate();
        this._addHeadLinkCanonicalAndXDefault('canonical');
        this._addHeadLinkCanonicalAndXDefault('x-default');
        this._addLangTagForHTML();
      }),
      switchMap(() => this._globalEvents.routerNavigationEnd$),
      tap(e => this._check403Page(e))
    ).subscribe();
  }

  /**
   * Add link alternate <link rel="alternate" href="https://www.turbico.com/en" hreflang="en">
   */
  private _addHeadLinkAlternate() {
    this._supportedLocales.forEach(e => {
      const link: HTMLLinkElement = this._document.createElement('link');
      let url: any = this._location.path().split('/');
      url[1] = e;
      url = url.join('/');
      link.setAttribute('rel', 'alternate');
      if (e !== 'en') {
        link.setAttribute('href', environment.app_host + url);
        link.setAttribute('hreflang', e);

        if (e === 'de') {
          link.setAttribute('href', environment.app_host.replace('io', 'com') + url);
          link.setAttribute('hreflang', 'de');
        }

        if (e === 'fi') {
          link.setAttribute('href', environment.app_host.replace('io', 'com') + url);
          link.setAttribute('hreflang', 'fi');
        }

        if (e === 'en-au') {
          link.setAttribute('href', environment.mirror_host_au + url);
          link.setAttribute('hreflang', e);
        }

      } else {
        link.setAttribute('href', environment.app_host + `/${url.split('/').slice(2).join('/')}`);
        link.setAttribute('hreflang', e);
      }
      this._document.head.appendChild(link);
    });
  }

  /**
   * Add link <link [rel]="attr" href="/en">
   * @attr
   */
  private _addHeadLinkCanonicalAndXDefault(attr: string) {
    const link: HTMLLinkElement = this._document.createElement('link');
    link.setAttribute('rel', attr);

    let url: any = this._location.path().split('/');
    const lang = url[1];
    url = url.join('/');

    if (this._location.path() === '/en') {
      link.setAttribute('href', environment.app_host + this._location.path().replace('/en', ''));
    } else if (this._location.path().includes('/en/')) {
      link.setAttribute('href', environment.app_host + '/' + this._location.path().replace('/en/', ''));
    } else if (!this._location.path()) {
      link.setAttribute('href', environment.app_host);
    } else {
      link.setAttribute('href', environment.app_host + this._location.path());
    }
    if (lang === 'en-au') {
      link.setAttribute('href', environment.mirror_host_au + url);
    }

    this._document.head.appendChild(link);
  }

  /**
   * Remove old alternate\canonical links from DOM
   * @param typeOfLinks
   */
  private _removeOldLinks(typeOfLinks: any[]) {
    if (this._platform.isBrowser) {
     typeOfLinks.forEach(e => {
       const oldLinks: NodeListOf<HTMLLinkElement> = this._document.querySelectorAll(`link[rel="${e}"]`);
       if (oldLinks.length) {
         oldLinks.forEach(j => {
           j.remove();
         });
       }
     });
    }
  }

 /**
  * Add in head <meta name="robots" content="noindex" />
  */
  public addMetaRobotNoIndex() {
    this._meta.addTags([
      { name: 'robot', content: 'noindex' }
    ]);
  }

  /**
   * Part for <html lang="en">
   * if lang == de -> lang="de"
   * if lang == en-ca -> lang="en"
   */
  private _addLangTagForHTML() {
    const matches = this._location.path().match(/^\/([a-z]{2}(?:-[A-Z]{2})?)\//) ? this._location.path().match(/^\/([a-z]{2}(?:-[A-Z]{2})?)\//) : this._location.path().match(/^\/([a-z]{2}(?:-[A-Z]{2})?)/);
    const locale = (matches && this._env.env.languageList.map(e => e.short).indexOf(matches[1]) !== -1) ? matches[1] : this._defaultLocale;
    this._document.documentElement.lang = locale;
  }

  /**
   * Get location.origin
   */
  public get originUrl() {
    if (this._platform.isBrowser) {
      return location.origin;
    }
  }

  /**
   * Check 403 and remove canonical and x-default
   * @param e
   * @private
   */
  private _check403Page(e) {
    if (e.url && e.url.includes('403')) {
      this._removeOldLinks(['canonical', 'x-default']);
    }
  }
}
