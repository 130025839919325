import { PlatformService } from './platform.service';
import { Injectable } from '@angular/core';

interface Storage {
  getItem(key: string): string | null;
  setItem(key: string, value: string): void;
  removeItem(key: string): void;
}

@Injectable({
  providedIn: 'root'
})
export class LocalstorageService {

  private readonly _storage: Storage;

  public constructor(
    private _platform: PlatformService,
  ) {
    this._storage = this._platform.isBrowser ? window.localStorage : null;
  }

  get(key: any): string | null {
    return this._storage?.getItem(key);
  }

  set(key: any, value: string): void {
    this._storage?.setItem(key, value);
  }

  clearItem(key: any): void {
    this._storage?.removeItem(key);
  }

  clearItems(keys: any[]): void {
    keys.forEach((key) => this.clearItem(key));
  }
}
