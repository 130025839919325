import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import {EpmCountryRestrictionService} from '../../../../services/epm-country-restriction.service';

@Component({
  selector: 'wr-cookie-message-content',
  templateUrl: './cookie-message-content.component.html',
  styleUrls: ['./cookie-message-content.component.scss']
})
export class CookieMessageContentComponent {

  constructor(
    private _router: Router,
    private _cookies: CookieService,
    public epm: EpmCountryRestrictionService
  ) {
  }

  goToPolicy() {
    this._router.navigateByUrl(`/privacy-policy`).then();
  }

  cookiesAccepted() {
    this._cookies.set('isCookiesAccepted', 'true', 999, '/');
  }
}
