import { Injectable } from '@angular/core';
import {BasePromo} from '../base-promo';

@Injectable({
  providedIn: 'root'
})
export class SpinfinityTropicalService extends BasePromo {
  eventName = 'spinfinity-tropical';


  /**
   * For switch logic if bonus api not enable
   */
  public isEnableBonusApi: boolean;

  constructor() {
    super();
  }

  openHuntModal() {}
}
