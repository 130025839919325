import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormInputComponent } from './form-input/form-input.component';
import {DirectivesModule} from '../../shared/directives/directives.module';
import {TranslationModule} from '../../shared/translation/translation.module';

@NgModule({
  declarations: [
    FormInputComponent
  ],
  imports: [
    CommonModule,
    DirectivesModule,
    TranslationModule,
    TranslationModule
  ],
  exports: [
    FormInputComponent
  ]
})
export class FormControlsModule { }
