import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Observable, of } from 'rxjs';
import { filter, first, switchMap } from 'rxjs/operators';
import { map, tap } from 'rxjs/operators';
import { BannersService } from '../../../services/banners.service';
import { CookieService } from 'ngx-cookie-service';
import { showHide } from '../../../modules/toast-message/animations';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { PlatformService } from '../../../services/platform.service';
import { UserPaymentService } from '../../../services/user/user-payment.service';
import { LanguageService } from '../../../services/language/language.service';

export enum CountdownTimerBannerPlace {
  SIGN_UP = 'sign-up',
  DEPOSIT = 'deposit'
}

enum CountdownCookie {
  TIME_LEFT_SIGN_UP = '--countdown-time-left-sign-up',
  TIME_LEFT_DEPOSIT = '--countdown-time-left-deposit',
  PREVIOUS_TIME_CMS = '--countdown-previous-time-cms'
}

export interface ICountdownTimerData {
  PrizeTitle: string;
  PrizeSubtitle: string;
  Time: Date;
  Image: string;
}

@UntilDestroy()
@Component({
  selector: 'app-countdown-timer-banner',
  templateUrl: './countdown-timer-banner.component.html',
  styleUrls: ['./countdown-timer-banner.component.scss'],
  animations: [showHide]
})
export class CountdownTimerBannerComponent implements OnInit, OnDestroy {

  @Input() place: CountdownTimerBannerPlace = CountdownTimerBannerPlace.SIGN_UP;
  @Input() showLoaderBtn: boolean = false;

  @Output() onButtonClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() onBannerLoaded: EventEmitter<boolean> = new EventEmitter<boolean>();

  public countdownTimer$: Observable<ICountdownTimerData>;

  private _timeLeft: any;

  constructor(
    private _banners: BannersService,
    private _cookie: CookieService,
    private _platform: PlatformService,
    private _userPayment: UserPaymentService,
    private _lang: LanguageService
  ) { }

  ngOnInit() {
    this._resolveBanner();
  }

  ngOnDestroy() {
  }

  private _resolveBanner() {
    this._lang.langChange$.pipe(
      untilDestroyed(this),
      tap(() => {
        this.countdownTimer$ = of(this._userPayment.isUserHasSuccessDep).pipe(
          filter(res => !res),
          first(),
          switchMap(() => this._banners.item({ alias: 'wf-countdown-timer-banner' })),
          filter(banner => !!banner),
          filter(banner => !!(banner && banner.length)),
          first(),
          map(banner => ({
            ...banner[0],
            Time: this._resolveTimeForTimer(banner)
          })),
          filter(banner => !!banner.Time),
          tap(banner => this.onBannerLoaded.emit(!!banner)),
        ) as Observable<ICountdownTimerData>;
      })
    ).subscribe();
  }

  private _resolveTimeForTimer(banner: ICountdownTimerData) {
    if (this._platform.isBrowser) {
      const cookieDate = this._cookie.get(this.place === CountdownTimerBannerPlace.SIGN_UP ? CountdownCookie.TIME_LEFT_SIGN_UP : CountdownCookie.TIME_LEFT_DEPOSIT);
      const previousCmsTime = this._cookie.get(CountdownCookie.PREVIOUS_TIME_CMS);
      const cmsTime = banner && banner[0] && banner[0].Time;
      this._timeLeft = cookieDate === 'null' ? null : previousCmsTime && cmsTime && previousCmsTime !== cmsTime || !previousCmsTime && cmsTime ?
        new Date(Date.now() + ((parseInt(cmsTime) || 2) * 60 * 1000)) :
        cookieDate && (new Date(cookieDate) > new Date()) ? new Date(cookieDate) :
          cookieDate && (new Date(cookieDate) < new Date()) ? null :
            new Date(Date.now() + ((parseInt(cmsTime) || 2) * 60 * 1000));

      this._cookie.set(
        this.place === CountdownTimerBannerPlace.SIGN_UP ? CountdownCookie.TIME_LEFT_SIGN_UP : CountdownCookie.TIME_LEFT_DEPOSIT,
        this._timeLeft, 999, '/', (window.location.hostname as any)
      );

      if (cmsTime) this._cookie.set(CountdownCookie.PREVIOUS_TIME_CMS, cmsTime, 999, '/', (window.location.hostname as any));

      return this._timeLeft;
    }
  }

  public buttonClick() {
    this.onButtonClick.emit();
  }

  get countdownTimerBannerPlace() {
    return CountdownTimerBannerPlace;
  }
}
