import { Component, OnDestroy, OnInit } from '@angular/core';
import { filter, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {ModalContent, ModalRef} from '../../../modal-ref';
import {UpdateProfileFormController} from '../../../../helpers/user/update-profile-form-controller';
import {UserFieldType} from '../../../../helpers/user/user-fields.data';
import {IMoveFieldData} from '../../../../shared/directives/move-next-field.directive';
import {GoogleTagManagerService} from '../../../../services/google-tag-manager.service';
import {UserService} from '../../../../services/user/user.service';
import {UserInfoService} from '../../../../services/user/user-info.service';
import {EnvironmentService} from '../../../../services/environment.service';
import { ScrollService } from '../../../../services/scroll.service';
import { DeviceDetectorService } from 'ngx-device-detector';

@UntilDestroy()
@Component({
  selector: 'app-auth-fields-missed-update',
  templateUrl: './auth-fields-missed-update.component.html',
  styleUrls: ['./auth-fields-missed-update.component.scss']
})
export class AuthFieldsMissedUpdateComponent implements OnInit, OnDestroy, ModalContent {

  /**
   * Current fields by step
   */
  fields: string[] = [];

  /**
   * Edit profile form controller
   */
  public controller: UpdateProfileFormController = new UpdateProfileFormController('edition');

  /**
   * Link to modal window
   */
  public modal: ModalRef;

  public readonly FieldType = UserFieldType;

  /**
   * Use for MoveNextFieldDirective
   */
  public controlList: IMoveFieldData[] = [
    { name: 'gender', maxLength: 1 },
    { name: 'city', maxLength: 1, isSelect: true },
    { name: 'address', maxLength: 1, isSelect: true },
  ];

  constructor(
    private _router: Router,
    private _gtm: GoogleTagManagerService,
    private _user: UserService,
    private _scroll: ScrollService,
    private _device: DeviceDetectorService,
    public userInfo: UserInfoService,
    public env: EnvironmentService
  ) {

  }

  ngOnInit() {
    this._handleEvents();

    if (this._device.isMobile()) {
      this._scroll.blockScroll();
    }
  }

  private _handleEvents() {
    /**
     * Form result handler
     */
    this.controller.updated$.pipe(
      untilDestroyed(this),
      filter(updated => !!updated),
      tap(() => {
        if (this.modal.options.data && this.modal.options.data.url) {
          this._router.navigateByUrl(this.modal.options.data.url).then();
        }

        setTimeout(() => this.modal.close(true), 100);
      })
    ).subscribe();
  }
  
  ngOnDestroy() {
    if (this._device.isMobile()) {
      this._scroll.UnblockScroll();
    }
  }
  
  /**
   * Send gtm data on field focus
   */
  onFocus(e, customName: string = null) {
    customName ? this._gtm.registerFormInputFocus('register', customName) : this._gtm.registerFormInputFocus('register', e.target.name);
  }
}
