<div [@showHide] *ngIf="countdownTimer$ | async as countdownTimer" class="banner"
  [ngClass]="{'sign-up': place === countdownTimerBannerPlace.SIGN_UP, 'deposit': place === countdownTimerBannerPlace.DEPOSIT}"
>
  <ng-container *ngIf="place === countdownTimerBannerPlace.DEPOSIT">
    <ng-container *ngIf="!showLoaderBtn; else preloading">
      <button  class="btn btn--primary big" (click)="buttonClick()">{{ 't.deposit' | translate }}</button>
    </ng-container>
    <ng-template #preloading>
      <app-preloader></app-preloader>
    </ng-template>
  </ng-container>
  <p class="banner__text">{{ 't.only-now' | translate }}:</p>
  <div class="banner__prize" [innerHTML]="countdownTimer?.PrizeTitle | safe: 'html'"></div>
  <div class="banner__prize-subtitle" [innerHTML]="countdownTimer?.PrizeSubtitle | safe: 'html'"></div>
  <app-countdown-timer [timeLeft]="countdownTimer?.Time" (timeFinished)="onBannerLoaded.emit(false)"></app-countdown-timer>
  <img ClassLoaded class="banner__img" [src]="countdownTimer?.Image" alt="Countdown timer image">
  <img *ngIf="place === countdownTimerBannerPlace.DEPOSIT" ClassLoaded class="banner__circle-img" src="/assets/img/countdown-dep.png" alt="Countdown deposit image">
</div>
