import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './footer/footer.component';
import { BottomNavigationComponent } from './bottom-navigation/bottom-navigation.component';
import { BottomTextComponent } from './bottom-text/bottom-text.component';
import { PipesModule } from '../pipes/pipes.module';
import { TranslationModule } from '../translation/translation.module';
import { ComponentsModule } from '../components/components.module';
import { GamePreviewComponent } from './game-preview/game-preview.component';
import { DirectivesModule } from '../directives/directives.module';
import { RouterModule } from '@angular/router';
import { SelectModule } from '../../modules/select/select.module';
import { HeaderBalanceComponent } from './header/header-balance/header-balance.component';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';
import { NgxTippyModule } from 'ngx-tippy-wrapper';
import { LobbyCategoryComponent } from './lobby-category/lobby-category.component';
import { TournamentPreviewComponent } from './tournament-preview/tournament-preview.component';
import { TournamentItemHeaderComponent } from './tournament-item-header/tournament-item-header.component';
import { HeaderModule } from './header/header.module';
import { SideNavModule } from './side-nav/side-nav.module';
import {FormControlsModule} from '../../modules/form-controls/form-controls.module';

@NgModule({
  declarations: [
    FooterComponent,
    BottomNavigationComponent,
    BottomTextComponent,
    GamePreviewComponent,
    HeaderBalanceComponent,
    LobbyCategoryComponent,
    TournamentPreviewComponent,
    TournamentItemHeaderComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    PipesModule,
    TranslationModule,
    ComponentsModule,
    DirectivesModule,
    SelectModule,
    ReactiveFormsModule,
    NgxMaskDirective,
    NgxTippyModule,
    HeaderModule,
    SideNavModule,
    FormControlsModule
  ],
  exports: [
    FooterComponent,
    BottomNavigationComponent,
    BottomTextComponent,
    GamePreviewComponent,
    LobbyCategoryComponent,
    TournamentPreviewComponent,
    TournamentItemHeaderComponent,
    HeaderModule,
    SideNavModule
  ],
  providers: [
    provideNgxMask()
  ]
})
export class LayoutModule {
}
