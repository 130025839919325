import { AfterViewInit, Directive, ElementRef, OnDestroy, Renderer2 } from '@angular/core';
import { PlatformService } from '../../services/platform.service';
import { fromEvent } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { tap } from 'rxjs/operators';

@UntilDestroy()
@Directive({
  selector: '[PasswordShow]'
})
export class PasswordShowDirective implements AfterViewInit, OnDestroy {

  /**
   * Is text input visible now
   */
  private _passwordVisible: boolean;

  /**
   * Toggle button element
   */
  private _toggleEl: HTMLElement;

  constructor(
    private _el: ElementRef,
    private _platform: PlatformService,
    private _renderer: Renderer2
  ) { }

  ngAfterViewInit(): void {
    if (!this._platform.isBrowser) {
      return;
    }

    this._toggleEl = this._el.nativeElement.previousElementSibling;

    this._renderer.setStyle(this._toggleEl, 'pointer-events', 'all');
    this._renderer.setStyle(this._toggleEl, 'cursor', 'pointer');

    /**
     * Handle click on toggle el
     */
    fromEvent(this._toggleEl, 'click').pipe(
      untilDestroyed(this),
      tap(() => {
        if (this._passwordVisible) {
          this._hide();
        } else {
          this._show();
        }
      })
    ).subscribe();
  }

  ngOnDestroy(): void {
  }

  /**
   * Hide input text
   *
   * @private
   */
  private _hide() {
    this._renderer.setAttribute(this._el.nativeElement, 'type', 'password');
    this._renderer.removeClass(this._toggleEl, 'active');
    this._passwordVisible = false;
  }

  /**
   * Show input text
   *
   * @private
   */
  private _show() {
    this._renderer.setAttribute(this._el.nativeElement, 'type', 'text');
    this._renderer.addClass(this._toggleEl, 'active');
    this._passwordVisible = true;
  }

}
