import { ErrorHandler, Injectable } from '@angular/core';
import { DICTIONARY } from './translation.data';
import { catchError, finalize, tap } from 'rxjs/operators';
import { CmsApiService } from '../../services/api/cms-api.service';
import { LanguageService } from '../../services/language/language.service';
import { of, Subject } from 'rxjs';
import { MissingTranslateError } from '../errors/missing-translate';
import { isNullOrUndefined } from '../../helpers/utils';

@Injectable({
  providedIn: 'root'
})
export class TranslationService {

  /**
   * Storage for missing translations
   */
  private _missingKeys: Set<string> = new Set();

  /**
   * Available languages
   */
  private _dictionary: any = DICTIONARY;

  /**
   * Is translations loading
   */
  private _translationsLoading = true;

  /**
   * Is translations loading Subject
   * @private
   */
  private _translationLoading$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private _cmsApi: CmsApiService,
    private _language: LanguageService,
    private _errorHandler: ErrorHandler
  ) {
  }

  get loading(): boolean {
    return this._translationsLoading;
  }

  get loading$(): Subject<boolean> {
    return this._translationLoading$;
  }

  /**
   * Translate text
   *
   * Using: translate('Hello, #name#', {name: 'Alex'});
   * Output: 'Hei Alex'; // (fi lang)
   *
   * @param key
   * @param params
   */
  public translate(key, params = null): string {
    let translated = '';
    let targetLanguage = this._language.current;

    if (isNullOrUndefined(this._dictionary[targetLanguage])) {
      targetLanguage = this._language.previous;
    }

    if (isNullOrUndefined(this._dictionary[targetLanguage]) || isNullOrUndefined(this._dictionary[targetLanguage][key])) {
      translated = key;

      if (!this._missingKeys.has(key) && DICTIONARY[targetLanguage]) {
        this._missingKeys.add(key);
        this._errorHandler.handleError(new MissingTranslateError(key));
      }
    } else {
      translated = this._dictionary[targetLanguage][key];
    }

    if (params) {
      const keyParams = [];
      const regex = /\#([^^]*?)\#/ig;
      let match;
      while (match = regex.exec(translated)) {
        keyParams.push(match[1]);
      }
      keyParams.forEach((item) => {
        translated = translated.replace(`#${item}#`, params[item]);
      });
    }

    return translated;
  }

  /**
   * Load user translates
   */
  public getUserTranslates$() {
    this._translationsLoading = true;
    this._translationLoading$.next(true);
    return this._cmsApi.localizationList().pipe(
      catchError(error => of({data: []})),
      tap((res: {data: any[]}) => {
        this._resolveLangFormat(res);
      }),
      finalize(() => {
        this._translationsLoading = false;
        this._translationLoading$.next(false);
      })
    );
  }

  /**
   * Resolve lang format
   * @param res
   */
  private _resolveLangFormat(res: any) {
    if (!res.data) {
      return;
    }

    const keyValuePairs = {};
    res.data.forEach((item) => {
      if (!isNullOrUndefined(item.key) && !isNullOrUndefined(item.value)) {
        keyValuePairs[item.key] = item.value;
      }
    });
    DICTIONARY[this._language.current] = keyValuePairs;
  }
}
