import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalComponent } from './modal.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ComponentsModule } from '../shared/components/components.module';
import { RouterModule } from '@angular/router';
import { PipesModule } from '../shared/pipes/pipes.module';
import { SelectModule } from '../modules/select/select.module';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';
import { ModalTemplateListImports } from './templates';
import { DirectivesModule } from '../shared/directives/directives.module';
import { ModalRegularListImports } from './components/regular';
import { RegisterFormModule } from '../modules/register-form/register-form.module';
import { FormControlsModule } from '../modules/form-controls/form-controls.module';
import { TranslationModule } from '../shared/translation/translation.module';
import { LayoutModule } from '../shared/layout/layout.module';

@NgModule({
  declarations: [
    ModalComponent,
    ...ModalTemplateListImports,
    ...ModalRegularListImports,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    ComponentsModule,
    LayoutModule,
    TranslationModule,
    PipesModule,
    DirectivesModule,
    SelectModule,
    NgxMaskDirective,
    RegisterFormModule,
    FormControlsModule,
  ],
  exports: [
    ModalComponent,
  ],
  providers: [
    provideNgxMask()
  ]
})
export class ModalModule {
}
