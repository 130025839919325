
export const devDomain: string[] = [
  'https://jdillinger.dev.local:4200',
  'https://man.dev.local:4200',
  'https://alinater.dev.local:4200',
  'https://front1.dev.local:4200',
  'https://star.dev.local:4200',
  'https://frontnumberfour.dev.local:4200',
  'https://azino777.dev.local:4200',
];
