import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router, RouterModule, Routes, UrlSerializer } from '@angular/router';
import { PageComponent } from './page.component';
import { DataPreloaderService } from '../core/services/data-preloader.service';
import { GamePageGuard } from '../core/shared/guards/game-page.guard';
import { LangGuard } from '../core/shared/guards/lang.guard';
import { CookieService } from 'ngx-cookie-service';
import { SsApiService } from '../core/services/api/ss-api.service';
import { PlatformService } from '../core/services/platform.service';
import { AuthGuard } from '../core/shared/guards/auth.guard';
import { LoginPageGuard } from '../core/shared/guards/login-page.guard';
import { GameCategory } from '../core/services/games/game-category';
import { UserService } from '../core/services/user/user.service';
import { LocalstorageService } from '../core/services/localstorage.service';
import { GroupsService } from '../core/services/groups.service';
import { ZendeskChatService } from '../core/services/zendesk/zendesk-chat.service';
import { CmsApiService } from '../core/services/api/cms-api.service';

export const PTAG_STRING = 'ptag';
export const PARTNER_ID_STRING = 'id_id';
export const STAG_STRING = 'stag';

const confirmationRedirects: Routes = [
  { path: 'users/confirmation', redirectTo: 'confirmation/user' },
  { path: ':lang/users/confirmation', redirectTo: 'confirmation/user' },
  { path: 'users/password/edit', redirectTo: 'confirmation/password_edit' },
  { path: ':lang/users/password/edit', redirectTo: 'confirmation/password_edit' },
  { path: 'profile/limits/confirm', redirectTo: 'confirmation/limit' },
  { path: ':lang/profile/limits/confirm', redirectTo: 'confirmation/limit' },
  { path: 'profile/auth_providers/confirm', redirectTo: 'confirmation/auth_provider' },
  { path: ':lang/profile/auth_providers/confirm', redirectTo: 'confirmation/auth_provider' },
  { path: 'users/unlock', redirectTo: 'confirmation/unlock' },
  { path: ':lang/users/unlock', redirectTo: 'confirmation/unlock' },
  { path: ':lang/users/sign_in', redirectTo: 'login' },
  { path: 'profile/limits', redirectTo: 'account/responsible-gaming/deposit-limits' },
  { path: ':lang/profile/limits', redirectTo: 'account/responsible-gaming/deposit-limits' },
  { path: 'deposit', redirectTo: 'account/deposit' },
  { path: ':lang/deposit', redirectTo: 'account/deposit' },
  { path: 'cashout', redirectTo: 'account/cashout' },
  { path: ':lang/cashout', redirectTo: 'account/cashout' },
  { path: 'account', redirectTo: 'account/user' },
  { path: ':lang/account', redirectTo: 'account/user' },
  { path: 'account/history', redirectTo: 'account/history/transactions' },
  { path: ':lang/account/history', redirectTo: 'account/history/transactions' },
  { path: 'account/responsible-gaming', redirectTo: 'account/responsible-gaming/deposit-limits' },
  { path: ':lang/account/responsible-gaming', redirectTo: 'account/responsible-gaming/deposit-limits' },
  { path: ':lang/profile/responsible-gaming', redirectTo: 'account/responsible-gaming' },
];

const changeRedirectForSomePageWithSlug: Routes = [
  { path: ':lang/home', redirectTo: '' },
];

const routes: Routes = [
  {
    path: '',
    component: PageComponent,
    resolve: {
      data: DataPreloaderService
    },
    children: [
      { path: '', loadChildren: () => import('./home/home.module').then(m => m.HomeModule), canActivate: [LangGuard], pathMatch: 'full' },
      { path: '404', loadChildren: () => import('./not-found/not-found.module').then(m => m.NotFoundModule) },
      { path: '403', loadChildren: () => import('./forbidden-page/forbidden-page.module').then(m => m.ForbiddenPageModule) },
      { path: 'transfer', loadChildren: () => import('./transfer/transfer.module').then(m => m.TransferModule) },
      { path: ':lang/payment-status', loadChildren: () => import('./payments-status/payments-status.module').then(m => m.PaymentsStatusModule), canActivate: [LangGuard] },
      { path: 'payment-status', loadChildren: () => import('./payments-status/payments-status.module').then(m => m.PaymentsStatusModule), canActivate: [LangGuard] },
      ...confirmationRedirects,
      ...changeRedirectForSomePageWithSlug,
      { path: ':lang', loadChildren: () => import('./home/home.module').then(m => m.HomeModule), canActivate: [LangGuard] },
      { path: ':lang/login', loadChildren: () => import('./sign-in-page/sign-in-page.module').then(m => m.SignInPageModule), canActivate: [LangGuard, LoginPageGuard] },
      { path: ':lang/register', loadChildren: () => import('./sign-up-page/sign-up-page.module').then(m => m.SignUpPageModule), canActivate: [LangGuard, LoginPageGuard] },
      { path: 'account', loadChildren: () => import('./account/account.module').then(m => m.AccountModule), canActivate: [LangGuard, AuthGuard] },
      { path: ':lang/account', loadChildren: () => import('./account/account.module').then(m => m.AccountModule), canActivate: [LangGuard, AuthGuard] },
      { path: ':lang/confirmation', loadChildren: () => import('./confirmation/confirmation.module').then(m => m.ConfirmationModule), canActivate: [LangGuard] },
      { path: ':lang/bonuses', loadChildren: () => import('./offers/offers.module').then(m => m.OffersModule), canActivate: [LangGuard] },
      { path: ':lang/jackpot', loadChildren: () => import('./jackpot/jackpot.module').then(m => m.JackpotModule),  canActivate: [LangGuard] },
      { path: ':lang/promo', loadChildren: () => import('./promo/promo.module').then(m => m.PromoModule), canActivate: [LangGuard] },
      { path: ':lang/tournaments', loadChildren: () => import('./tournaments/tournaments.module').then(m => m.TournamentsModule), canActivate: [LangGuard] },
      { path: ':lang/blog', loadChildren: () => import('./blog/blog.module').then(m => m.BlogModule), canActivate: [LangGuard] },
      { path: ':lang/mobile-app', loadChildren: () => import('./mobile-app/mobile-app.module').then(m => m.MobileAppModule), canActivate: [LangGuard] },
      { path: ':lang/games/favorite', loadChildren: () => import('./extra-category-page/extra-category-page.module').then(m => m.ExtraCategoryPageModule), data: { category: GameCategory.FAVORITE }, canActivate: [LangGuard, AuthGuard] },
      { path: ':lang/games/last-played', loadChildren: () => import('./extra-category-page/extra-category-page.module').then(m => m.ExtraCategoryPageModule), data: { category: GameCategory.LAST_PLAYED }, canActivate: [LangGuard, AuthGuard] },
      { path: ':lang/games', loadChildren: () => import('./games-page/games-page.module').then(m => m.GamesPageModule), canActivate: [LangGuard] },
      { path: ':lang/play/:slug', loadChildren: () => import('./game-page/game-page.module').then(m => m.GamePageModule), canActivate: [LangGuard, GamePageGuard] },
      { path: ':lang/:slug', loadChildren: () => import('./static-page/static-page.module').then(m => m.StaticPageModule), canActivate: [LangGuard] },
      { path: '**', redirectTo: '404' },
    ]
  }
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class PageRoutingModule {
  constructor(
    private _cookie: CookieService,
    private _router: Router,
    private _api: SsApiService,
    private _serializer: UrlSerializer,
    private _platform: PlatformService,
    private _user: UserService,
    private _storage: LocalstorageService,
    private _groups: GroupsService,
    private _zendesk: ZendeskChatService,
    private _cmsApi: CmsApiService
  ) {
    this._detectQueryParameters();
  }

  /**
   * Detect query parameters
   * @private
   */
  private _detectQueryParameters() {
    if (!this._platform.isBrowser) {
      return;
    }
    const params = this._serializer.parse(window.location.search).queryParams;
    const domain = location.hostname.split('.').slice(-2).join('.');

    setTimeout(() => {
      if (params.stag || params.test || params.nocache) {
        this._detectTestParameter(params);
        if (params.stag) {
          this._detectStag(params, domain);
        }
        if (params.nocache) {
          delete params.nocache;
        }
        if (params.affb_id) {
          this._cookie.set('affb_id', `${params.affb_id}`, 999, '/', domain);
          this._storage.set('affb_id', `${params.affb_id}`);
          delete params.affb_id;
        }
        const tree = this._router.parseUrl(window.location.pathname);
        tree.queryParams = params;
        this._router.navigateByUrl(tree).then();
      } else if (!params.stag && this._cookie.check(PARTNER_ID_STRING)) {
        this._cmsApi.customNoLangHeadersList.push({ key: 'ID-ID', val: this._cookie.get(PARTNER_ID_STRING) });
        this._cmsApi.customHeadersList.push({ key: 'ID-ID', val: this._cookie.get(PARTNER_ID_STRING) });
      }
    }, 100);
  }

  /**
   * Save stag to cookie if exists
   *
   * @private
   */
  private _detectStag(params: any, domain: string) {
    if (params.stag) {

      this._detectAnCutStag(params, domain);

      this._api.infoLocales(
        params.affb_id ? {
          affb_id: params.affb_id,
          stag: params.stag
        } : {
          stag: params.stag
        }
      ).subscribe();

      delete params.stag;

    } else if (!params.stag && this._cookie.check(PTAG_STRING)) {
      this._cookie.set(PARTNER_ID_STRING, this._cookie.get(PTAG_STRING).split('_')[0], 999, '/', domain);
      this._cmsApi.customNoLangHeadersList.push({ key: PARTNER_ID_STRING, val: this._cookie.get(PARTNER_ID_STRING) });
      this._cmsApi.customHeadersList.push({ key: PARTNER_ID_STRING, val: this._cookie.get(PARTNER_ID_STRING) });
    }
  }

  /**
   * Detect test parameter
   * @private
   */
  private _detectTestParameter(params) {
    if (params.test) this._storage.set('test', 'true');
    if (params.stag) this._storage.set('stag', params.stag);
    delete params.test;
  }

  /**
   * Detect ptag and set and cut partner id stag for Back-End
   * @param params
   * @param domain
   * @private
   */
  private _detectAnCutStag(params, domain) {
    switch (true) {
      case this._cookie.check(PTAG_STRING) && this._cookie.check(PARTNER_ID_STRING):
        break;
      case this._cookie.check(PTAG_STRING):
        this._cookie.set(PARTNER_ID_STRING, this._cookie.get(PTAG_STRING).split('_')[0], 999, '/', domain);
        this._cmsApi.customNoLangHeadersList.push({ key: 'ID-ID', val: params.stag.split('_')[0] });
        this._cmsApi.customHeadersList.push({ key: 'ID-ID', val: params.stag.split('_')[0] });
        break;
      default:
        this._cookie.set(PARTNER_ID_STRING, params.stag.split('_')[0], 999, '/', domain);
        this._cookie.set(PTAG_STRING, params.stag, 999, '/', domain);
        this._storage.set(PARTNER_ID_STRING, params.stag.split('_')[0]);
        this._storage.set(STAG_STRING, params.stag);
        this._cmsApi.customNoLangHeadersList.push({ key: 'ID-ID', val: params.stag.split('_')[0] });
        this._cmsApi.customHeadersList.push({ key: 'ID-ID', val: params.stag.split('_')[0] });
    }
  }
}
