import { environment } from '../../../environments/environment';
import { ErrorHandler, Injectable } from '@angular/core';
import { HttpErrorResponse, HttpClient } from '@angular/common/http';
import { PlatformService } from '../services/platform.service';
import {devDomain} from '../../../config/dev.domain';

const HOST = environment.error_host;

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

  /**
   * List of app hosts from which request urls should not be modified
   *
   * @private
   */
  private _ignoredHosts = [
    'https://localhost:4200',
    'https://stage.wildfortune.io',
    'https://dev.wildfortune.io',
    'https://wildfortune.io',
    ...devDomain
  ];

  constructor(
    private _platform: PlatformService,
    private _http: HttpClient,
  ) { }

  /**
   * Handle all errors in application
   *
   * @param error
   */
  handleError(error: any) {
    if (this._platform.isBrowser) {
      const chunkFailedMessage = /Loading chunk [\d]+ failed/;
      if (chunkFailedMessage.test(error.message)) {
        window.location.reload();
      }

      delete error['ngDebugContext'];
      delete error['ngErrorLogger'];

      if (error.rejection) {
        error = error.rejection;
      }

      switch (true) {
        case error instanceof HttpErrorResponse:
          this._sendError({
            ...error,
            message: `Failed HTTP request for url: ${(error as HttpErrorResponse).url}`
          });
          break;
        default:
          if (!error.type) {
            error.type = 'frontend';
          }

          console.log(error);

          this._sendError({
            ...error,
            message: error.message || (error as Error).stack || 'Unknown message'
          });
          break;
      }
    }
  }

  /**
   * Send error to backend
   *
   * @param error
   * @private
   */
  private _sendError(error: Error) {
    if (!navigator.onLine) {
      return;
    }

    try {
      this._http.post(this._resolveHost(), {
        ts: Date.now(),
        userAgent: this._getUserAgent(),
        ...error
      }, {withCredentials: true}).subscribe();
    } catch (e) {}
  }

  /**
   * Returns object containing information about user-agent and internet connection of user
   *
   * @private
   */
  private _getUserAgent() {
    const nav: any = navigator;
    return {
      location: window.location.href,
      language: nav.language || 'unknown',
      platform: nav.platform || 'unknown',
      userAgent: nav.userAgent || 'unknown',
      connectionDownlink: nav.connection ? nav.connection.downlink : 'unknown',
      connectionEffectiveType: nav.connection ? nav.connection.effectiveType : 'unknown',
    };
  }

  /**
   * Resolve host for mirrors
   * @private
   */
  private _resolveHost() {
    if (this._platform.isBrowser) {
      const host = window.location.host;
      const hostName = host.split('.')[1];

      return !this._ignoredHosts.some(item => item.includes(host)) ?
        HOST.replace('wildfortune', hostName) :
        HOST;
    } else {
      return HOST;
    }
  }
}
