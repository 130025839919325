import { Injectable } from '@angular/core';
import { PlatformService } from './platform.service';
import {fromEvent, ReplaySubject} from 'rxjs';
import { debounceTime, tap } from 'rxjs/operators';
import {GlobalEventsService} from './global-events.service';

const MOBILE_SMALL_MAX_WIDTH = 374.98;
const MOBILE_MAX_HEIGHT_LOCKER = 524.98;
const MOBILE_MAX_WIDTH = 767.98;
const MOBILE_MAX_LANDSCAPE_WIDTH = 839.98;
const TABLET_MAX_WIDTH = 991.98;
const SMALL_DESKTOP_MAX_WIDTH = 1199.98;

@Injectable({
  providedIn: 'root'
})

export class BreakpointsService {

  private _windowWidth = this._platform.isBrowser ? window.innerWidth : null;
  private _windowHeight = this._platform.isBrowser ? window.innerHeight : null;

  private _isSmallMobile: boolean;
  private _isMobile: boolean;
  private _isTablet: boolean;
  private _isSmallDesktop: boolean;
  private _isDesktop: boolean;

  private _belowDesktop: boolean;
  private _belowSmallDesktop: boolean;
  private _belowTablet: boolean;

  private _zoomEnabled: boolean = this._platform.isBrowser ? Math.round(window.devicePixelRatio * 100) > 300 : false;

  private _canToggleLandscapeLocker: boolean;

  constructor(
    private _platform: PlatformService,
    private _events: GlobalEventsService
  ) {
    this._handleResize();
    this._updateBreakpoints();
  }

  /**
   * Screen lass then MOBILE_MAX_WIDTH
   */
  public get isSmallMobile(): boolean {
    return this._isSmallMobile;
  }


  /**
   * Screen lass then MOBILE_MAX_WIDTH
   */
  public get isMobile(): boolean {
    return this._isMobile;
  }

  /**
   * Screen lass then TABLET_MAX_WIDTH and more then MOBILE_MAX_WIDTH
   */
  public get isTablet(): boolean {
    return this._isTablet;
  }

  /**
   * Screen lass then SMALL_DESKTOP_MAX_WIDTH and more then TABLET_MAX_WIDTH
   */
  public get isSmallDesktop(): boolean {
    return this._isSmallDesktop;
  }

  /**
   * Screen grater then SMALL_DESKTOP_MAX_WIDTH
   */
  public get isDesktop(): boolean {
    return this._isDesktop;
  }

  /**
   * Screen lass then SMALL_DESKTOP_MAX_WIDTH
   */
  public get belowDesktop(): boolean {
    return this._belowDesktop;
  }

  /**
   * Screen lass then TABLET_MAX_WIDTH
   */
  public get belowSmallDesktop(): boolean {
    return this._belowSmallDesktop;
  }

  /**
   * Screen lass then MOBILE_MAX_WIDTH
   */
  public get belowTablet(): boolean {
    return this._belowTablet;
  }

  /**
   * Zoom enabled
   */
  public get zoomEnabled(): boolean {
    return this._zoomEnabled;
  }

  /**
   * Can toggle landscape locker
   */
  public get canToggleLandscapeLocker(): boolean {
    return this._canToggleLandscapeLocker;
  }

  /**
   * Handle window resize event
   *
   * @private
   */
  private _handleResize() {
    if (this._platform.isBrowser) {
      this._events.resize$?.pipe(
        debounceTime(100),
        tap(() => {
          this._windowWidth = window.innerWidth;
          this._windowHeight = window.innerHeight;
          this._updateBreakpoints();
        })
      ).subscribe();
    }
  }

  /**
   * Update breakpoint values
   *
   * @private
   */
  private _updateBreakpoints() {
    this._isSmallMobile = this._windowWidth <= MOBILE_SMALL_MAX_WIDTH;
    this._isMobile = this._windowWidth <= MOBILE_MAX_WIDTH;
    this._isTablet = this._windowWidth <= TABLET_MAX_WIDTH && this._windowWidth > MOBILE_MAX_WIDTH;
    this._isSmallDesktop = this._windowWidth <= SMALL_DESKTOP_MAX_WIDTH && this._windowWidth > TABLET_MAX_WIDTH;
    this._isDesktop = this._windowWidth > SMALL_DESKTOP_MAX_WIDTH;

    this._belowDesktop = this._windowWidth <= SMALL_DESKTOP_MAX_WIDTH;
    this._belowSmallDesktop = this._windowWidth <= TABLET_MAX_WIDTH;
    this._belowTablet = this._windowWidth <= MOBILE_MAX_WIDTH;
    this._canToggleLandscapeLocker = this._windowWidth <= MOBILE_MAX_LANDSCAPE_WIDTH;
    this._events.updatedBreakpoints$.next(true);
  }
}
