import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutModule } from './layout/layout.module';
import { PipesModule } from './pipes/pipes.module';
import { TranslationModule } from './translation/translation.module';
import { ComponentsModule } from './components/components.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SelectModule } from '../modules/select/select.module';
import { DirectivesModule } from './directives/directives.module';
import { ToastMessageModule } from '../modules/toast-message/toast-message.module';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';
import { HeaderComponent } from './layout/header/header.component';
import { BaseTimerComponent } from '../helpers/base-timer.component';

@NgModule({
  declarations: [
    BaseTimerComponent,
  ],
  imports: [
    CommonModule,
    LayoutModule,
    PipesModule,
    TranslationModule,
    ComponentsModule,
    FormsModule,
    ReactiveFormsModule,
    SelectModule,
    DirectivesModule,
    ToastMessageModule,
    NgxMaskDirective,
  ],
  exports: [
    CommonModule,
    LayoutModule,
    PipesModule,
    TranslationModule,
    ComponentsModule,
    FormsModule,
    ReactiveFormsModule,
    SelectModule,
    DirectivesModule,
    ToastMessageModule,
    HeaderComponent,
    NgxMaskDirective,
  ],
  providers: [
    provideNgxMask()
  ]
})
export class SharedModule { }
