import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { SelectModule } from '../../modules/select/select.module';
import { PreloaderComponent } from './preloader/preloader.component';
import { PipesModule } from '../pipes/pipes.module';
import { TranslationModule } from '../translation/translation.module';
import { StickyBonusComponent } from './sticky-bonus/sticky-bonus.component';
import { RouterModule } from '@angular/router';
import { DirectivesModule } from '../directives/directives.module';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';
import { LoginFormComponent } from '../layout/login-form/login-form.component';
import { InformerComponent } from './informer/informer.component';
import { TournamentTimerComponent } from './tournament-time/tournament-time.component';
import { AccountHeaderComponent } from './account-header/account-header.component';
import { AccountLinksComponent } from './account-links/account-links.component';
import { RadialProgressComponent } from './radial-progress/radial-progress.component';
import { LandscapeLockerComponent } from './landscape-locker/landscape-locker.component';
import { JackpotBadgeComponent } from './jackpot-badge/jackpot-badge.component';
import { CurrencySelectorComponent } from './currency-selector/currency-selector.component';
import { InputCurrenciesSelectorComponent } from './input-currencies-selector/input-currencies-selector.component';
import { NotificationCenterModule } from '../../modules/notification-center/notification-center.module';
import { SkeletonComponent } from './skeleton/skeleton.component';
import { InstallAppBannerComponent } from './install-app-banner/install-app-banner.component';
import { CountdownTimerBannerComponent } from './countdown-timer-banner/countdown-timer-banner.component';
import { CountdownTimerComponent } from './countdown-timer/countdown-timer.component';
import { JackpotLevelsComponent } from './jackpot/jackpot-levels/jackpot-levels.component';
import { JackpotHeaderComponent } from './jackpot/jackpot-header/jackpot-header.component';
import { JackpotWinnersTableComponent } from './jackpot/jackpot-winners-table/jackpot-winners-table.component';
import { FormControlsModule } from '../../modules/form-controls/form-controls.module';
import { SliderModule } from '../../modules/slider/slider.module';
import { CountrySelectorComponent } from './country-selector/country-selector.component';
import { LootboxPreviewComponent } from './lootbox-preview/lootbox-preview.component';


@NgModule({
  declarations: [
    PreloaderComponent,
    StickyBonusComponent,
    LoginFormComponent,
    TournamentTimerComponent,
    InformerComponent,
    RadialProgressComponent,
    AccountHeaderComponent,
    AccountLinksComponent,
    LandscapeLockerComponent,
    JackpotBadgeComponent,
    CurrencySelectorComponent,
    InputCurrenciesSelectorComponent,
    SkeletonComponent,
    InstallAppBannerComponent,
    CountdownTimerBannerComponent,
    CountdownTimerComponent,
    JackpotLevelsComponent,
    JackpotHeaderComponent,
    JackpotWinnersTableComponent,
    CountrySelectorComponent,
    LootboxPreviewComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SelectModule,
    PipesModule,
    TranslationModule,
    RouterModule,
    DirectivesModule,
    NgxMaskDirective,
    NotificationCenterModule,
    FormControlsModule,
    SliderModule
  ],
  exports: [
    PreloaderComponent,
    StickyBonusComponent,
    LoginFormComponent,
    TournamentTimerComponent,
    InformerComponent,
    AccountHeaderComponent,
    AccountLinksComponent,
    RadialProgressComponent,
    LandscapeLockerComponent,
    JackpotBadgeComponent,
    CurrencySelectorComponent,
    InputCurrenciesSelectorComponent,
    SkeletonComponent,
    InstallAppBannerComponent,
    CountdownTimerBannerComponent,
    CountdownTimerComponent,
    JackpotLevelsComponent,
    JackpotHeaderComponent,
    JackpotWinnersTableComponent,
    CountrySelectorComponent,
    LootboxPreviewComponent,
  ],
  providers: [
    provideNgxMask()
  ]
})
export class ComponentsModule {
}
