import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectComponent } from './select.component';
import { SelectOptionComponent } from './select-option/select-option.component';
import { SelectHeaderComponent } from './select-header/select-header.component';
import { SelectDropdownComponent } from './select-dropdown/select-dropdown.component';

export interface SelectConfig {
  /**
   * Class name for container of options list
   */
  dropdownContainerClass?: string;
}

@NgModule({
  declarations: [
    SelectComponent,
    SelectOptionComponent,
    SelectHeaderComponent,
    SelectDropdownComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    SelectComponent,
    SelectOptionComponent,
    SelectHeaderComponent,
    SelectDropdownComponent
  ]
})
export class SelectModule {

}
