export enum ErrorChanel {
  QA = 'qa',
  CONTENT = 'content'
}

export class BaseError extends Error {
  chanel: ErrorChanel;
  type: string;
  name: string;
}
